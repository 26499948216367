import React, { useState, useEffect } from 'react';
import CheckIn from '../../components/CheckIn';
import attention from '../../assets/images/attention.gif'

const AlertNotification = ({ message, hour , minute}) => {
const [isVisible, setIsVisible] = useState(false);
const [hasNotified, setHasNotified] = useState(false);
const large = "font-size:'1rem'"
const [color, setColor] = useState('bg-yellow-600');
const close = () => {
    setIsVisible(false)
    setHasNotified(true)
}

  
useEffect(() => {
    const checkTime = () => {
        const now = new Date();
        const hours = now.getHours();
        const minutes = now.getMinutes();

        if (hours === Number(hour) && minutes === Number(minute) && hasNotified === false) {
            setIsVisible(true);
            //setHasNotified(true);
            const timer = setTimeout(() => {
                setColor('bg-gray-600');
            }, 8000); // 8 seconds delay
    
            return () => clearTimeout(timer);
        }

        
    };

    // Initial check
    checkTime();

    // Set up an interval to check every minute
    const intervalId = setInterval(checkTime, 60000); // 60000ms = 1 minute

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
}, []);

if (!isVisible) return null;

return (
    <div className={`${color} text-black px-4 py-3 rounded relative h-16 flex justify-start transition-colors duration-2000`}role="alert">
    <img src={attention} width="40" />
    <p className="block text-lg py-0 text-white px-4 mt-2 ml-0"  >{message}</p>
    <CheckIn className="mt-2" />
    <span
        className="absolute top-0 bottom-0 right-0 px-4 py-3"
        onClick={() => close(false)}
        style={{ cursor: 'pointer' }}
    >
        <svg
        className="fill-current h-8 w-6 text-white"
        role="button"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        >
        <title >Close</title>
        <path d="M14.348 5.652a.5.5 0 00-.707 0L10 9.293 6.357 5.65a.5.5 0 00-.707.707L9.293 10l-3.65 3.643a.5.5 0 00.707.707L10 10.707l3.643 3.65a.5.5 0 00.707-.707L10.707 10l3.65-3.643a.5.5 0 000-.707z" />
        </svg>
    </span>
    </div>
)
  
  
    
  }

 
export default AlertNotification;
