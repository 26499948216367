import React from "react";
import ReactDOM from "react-dom";

import PemsApp from './PemsApp';
import registerServiceWorker from './registerServiceWorker';
import reportWebVitals from './reportWebVitals';
// Add this import:
//import {AppContainer} from 'react-hot-loader';

// Wrap the rendering in a function:
const render = Component => {
    ReactDOM.render(
        // Wrap App inside AppContainer
        <Component/>
        ,
        document.getElementById('root')
    );
};

// Do this once
registerServiceWorker();

// Render once
render(PemsApp);

reportWebVitals();

// Webpack Hot Module Replacement API
// if (module.hot) {
//     module.hot.accept('./PemsApp', () => {
//         render(PemsApp);
//     });
// }
