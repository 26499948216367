import React from "react";


const CircularProgress = ({className}) => <div className={`loader ${className}  flex items-center justify-center  h-screen`}>
 <div className="">

  <img src="/images/logo.png" className="w-48"  alt="loader"/>
  <img src="/images/spinner.svg" className="w-40" alt="spinner"/>
 </div>

</div>;
export default CircularProgress;
