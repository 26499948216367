// Customizer const
export const SET_CURRENT = 'SET_CURRENT';
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';


//Auth const
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const USER_DATA = 'user_data';
export const USER_TOKEN_SET = 'user_token_set';
export const EXPIRES_SIGNATURE = 'expires_signature';
export const SET_APP_AUTHORIZATION = 'SET_APP_AUTHORIZATION';
export const SET_IDLE_ID = 'SET_IDLE_ID';
export const SET_WARNING_ID = 'SET_WARNING_ID';

//company
export const ACTIVE_COMPANY = 'ACTIVE_COMPANY';

//employee
export const EMPLOYEE_RELATIONSHIPS = 'EMPLOYEE_RELATIONSHIPS';
export const SAVE_COUNTRIES = 'SAVE_COUNTRIES';
