import moment from "moment";
import React from "react";

class Helpers {
    static objectFormBuilder(form, object) {


        for (let name in object) {

            if (object.hasOwnProperty(name)) {
                if (object[name] === undefined || object[name] === null || object[name] === "null") {

                    form.append(name, '');
                } else {

                    form.append(name, object[name]);
                }
            }
        }

        return form;
    }

    static getIdFromSlug(slug) {
        let name = slug.split("-");
        return name[name.length - 1];

    }

    static createSlug(title, id) {
        return title.replace(" ", "-") + "-" + id;
    }

    getDeviceName() {
        let name = navigator.appCodeName;
        return name.replace(" ", "_").toLowerCase();
    }

    static normalizeJson(object) {
        let newObject = {};
        for (let name in object) {

            if (object.hasOwnProperty(name)) {
                newObject[name] = object[name] ? object[name].toString() : '';
            }
        }

        return newObject;

    }

    static getMomentDateFromSQL(date, moment) {
        if (date) {
            return moment(new Date(date));
        }
        return '';
    }

    static getAge(date, moment) {
        if (date) {
            return moment(new Date(date)).fromNow();
        }
        return '';
    }

    static ageFromNow(date, moment) {
        if (date) {

            return moment().diff(moment(new Date(date)), 'day');

        }

        return '';
    }

    static getSQLDateFromMoment(date) {
        let dateFormat = 'YYYY-MM-DD';
        if (date) {

            return date.format(dateFormat);
        }
        
        return '';
    }

    static getSQLTimeFromMoment(date) {
        let dateFormat = 'HH:mm:ss';
        if (date) {

            return date.format(dateFormat);
        }
        return '';
    }

    static getSQLDateTimeFromMoment(date) {
        let dateFormat = 'YYYY-MM-DD HH:mm:ss';
        if (date) {

            return date.format(dateFormat);
        }
        return '';
    }

    static displayDate(date) {
        let dateFormat = 'YYYY-MM-DD';
        let months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];

        if (date) {
            let dateTime = new Date(date);
            let day = dateTime.getDate();
            let month = months[dateTime.getMonth()];
            let year = dateTime.getFullYear();
            return day + " " + month + ", " + year;
        }
        return '';
    }

    static displayMonthYear(date) {
        let dateFormat = 'YYYY-MM-DD';
        let months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];

        if (date) {
            let dateTime = new Date(date);
            let day = dateTime.getDate();
            let month = months[dateTime.getMonth()];
            let year = dateTime.getFullYear();
            return month + " " + year;
        }
        return '';
    }

    static displayDayMonth(date) {

        let months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];

        if (date) {
            let dateTime = new Date(date);
            let day = dateTime.getDate();
            let month = months[dateTime.getMonth()];

            return day + " " + month;
        }
        return '';
    }

    static reDisplayDate(date) {
        let dateFormat = 'YYYY-MM-DD';
        let months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];

        if (date) {
            let dateTime = new Date(date);
            let day = dateTime.getDate();
            if (day < 10) {
                day = '0' + day;
            }
            let month = months[dateTime.getMonth()];
            let year = dateTime.getFullYear();
            return day + "-" + month + "-" + year;
        }
        return '';
    }

    static displayDateTime(date) {

        let months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        if (date) {
            let dateTime = new Date(date);

            return dateTime.getDate() + " " + months[dateTime.getMonth()] + " " + dateTime.getFullYear() + " " + new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate(), dateTime.getHours() - 1, dateTime.getMinutes(), dateTime.getSeconds()).toLocaleTimeString();

        }
        return '';
    }

    static download(filename, path) {
        let element = document.createElement('a');
        element.setAttribute('href', path);
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    static downloadOrOpen(filename, path) {
        let element = document.createElement('a');
        element.setAttribute('href', path);
        element.setAttribute('target', '_blank');
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    static getStates() {
        let theStates = [
            {
                "id": "2647",
                "name": "Abia",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2648",
                "name": "Abuja Federal Capital Territory",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2649",
                "name": "Adamawa",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2650",
                "name": "Akwa Ibom",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2651",
                "name": "Anambra",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2652",
                "name": "Bauchi",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2653",
                "name": "Bayelsa",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2654",
                "name": "Benue",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2655",
                "name": "Borno",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2656",
                "name": "Cross River",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2657",
                "name": "Delta",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2658",
                "name": "Ebonyi",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2659",
                "name": "Edo",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2660",
                "name": "Ekiti",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2661",
                "name": "Enugu",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2662",
                "name": "Gombe",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2663",
                "name": "Imo",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2664",
                "name": "Jigawa",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2665",
                "name": "Kaduna",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2666",
                "name": "Kano",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2667",
                "name": "Katsina",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2668",
                "name": "Kebbi",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2669",
                "name": "Kogi",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2670",
                "name": "Kwara",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2671",
                "name": "Lagos",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2672",
                "name": "Nassarawa",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2673",
                "name": "Niger",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2674",
                "name": "Ogun",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2675",
                "name": "Ondo",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2676",
                "name": "Osun",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2677",
                "name": "Oyo",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2678",
                "name": "Plateau",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2679",
                "name": "Rivers",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2680",
                "name": "Sokoto",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2681",
                "name": "Taraba",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2682",
                "name": "Yobe",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "2683",
                "name": "Zamfara",
                "country_id": 160,
                "created_at": null,
                "updated_at": null
            }
        ];
        return theStates.sort(compareByName);
    }

    static getInstitutions() {
        let theInstitution = [
            {
                "id": 1,
                "country_id": 160,
                "name": " Adamawa State polytechnic, Yola",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 2,
                "country_id": 160,
                "name": " Adeyemi College of Education, Ondo",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 3,
                "country_id": 160,
                "name": " Akanu Ibiam Federal polytechnic, Unwana",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 4,
                "country_id": 160,
                "name": " Allover Central Polytechnic, Sango-Ota Ogun State",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 5,
                "country_id": 160,
                "name": " Auchi polytechnic, Auchi",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 6,
                "country_id": 160,
                "name": " College of Education, Afaha-Nsit",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 7,
                "country_id": 160,
                "name": " College of Education, Agbor",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 8,
                "country_id": 160,
                "name": " College of Education, Akwanga",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 9,
                "country_id": 160,
                "name": " College of Education, Azare",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 10,
                "country_id": 160,
                "name": " College of Education, Ekiadolor",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 11,
                "country_id": 160,
                "name": " College of Education, Gindiri",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 12,
                "country_id": 160,
                "name": " College of Education, Ikere Ekiti",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 13,
                "country_id": 160,
                "name": " College of Education, Katsina Ala",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 14,
                "country_id": 160,
                "name": " College of Education, Katsina-Ala",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 15,
                "country_id": 160,
                "name": " College of Education, Oro, Kwara State",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 16,
                "country_id": 160,
                "name": " College of Education, Warri",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 17,
                "country_id": 160,
                "name": " College of Education, Zuba",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 18,
                "country_id": 160,
                "name": " Delta state polytechnic, Ozoro",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 19,
                "country_id": 160,
                "name": " FCT College of Education, Zuba",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 20,
                "country_id": 160,
                "name": " Federal college of Animal health & production Technology, Ibadan",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 21,
                "country_id": 160,
                "name": " Federal College of Education (Special), Oyo",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 22,
                "country_id": 160,
                "name": " Federal College of Education, (Technical) Bich",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 23,
                "country_id": 160,
                "name": " Federal College of Education, Akoka",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 24,
                "country_id": 160,
                "name": " Federal College of Education, Obudu",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 25,
                "country_id": 160,
                "name": " Federal College of Education, Okene",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 26,
                "country_id": 160,
                "name": " Federal College of Education, Omoku",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 27,
                "country_id": 160,
                "name": " Federal College of Education, pankshin",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 28,
                "country_id": 160,
                "name": " Federal College of Education, Yola",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 29,
                "country_id": 160,
                "name": " Federal College of Education, Zaira",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 30,
                "country_id": 160,
                "name": " Federal College of Education, Zaria",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 31,
                "country_id": 160,
                "name": " Federal polytechnic, Ado – Ekiti",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 32,
                "country_id": 160,
                "name": " Federal polytechnic, Bauchi",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 33,
                "country_id": 160,
                "name": " Federal polytechnic, Birnin-Kebbi",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 34,
                "country_id": 160,
                "name": " Federal polytechnic, Damaturu",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 35,
                "country_id": 160,
                "name": " Federal polytechnic, Ede",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 36,
                "country_id": 160,
                "name": " Federal polytechnic, Idah",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 37,
                "country_id": 160,
                "name": " Federal polytechnic, Ilaro",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 38,
                "country_id": 160,
                "name": " Federal polytechnic, Mubi",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 39,
                "country_id": 160,
                "name": " Federal polytechnic, Nassarawa",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 40,
                "country_id": 160,
                "name": " Federal polytechnic, Nekede",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 41,
                "country_id": 160,
                "name": " Federal polytechnic, Oko",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 2062,
                "country_id": 160,
                "name": " GSS, Gunduma",
                "created_at": "2022-01-28T02:22:31.000000Z",
                "updated_at": "2022-01-28T02:22:31.000000Z"
            },
            {
                "id": 42,
                "country_id": 160,
                "name": " Hussaini Adamu Federal polytechnic, kazaure",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 43,
                "country_id": 160,
                "name": " Institute of Management Technology, Enugu",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 44,
                "country_id": 160,
                "name": " Kano State College of Education, Kano",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 45,
                "country_id": 160,
                "name": " Kano State polytechnic, Kano",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 46,
                "country_id": 160,
                "name": " Maritime Academy of Nigeria, Oron",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 47,
                "country_id": 160,
                "name": " Moshood Abiola polytechnic, Abeokuta",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 48,
                "country_id": 160,
                "name": " National Teachers Institute, kaduna",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 49,
                "country_id": 160,
                "name": " Niger State polytechnic, Zungeru",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 50,
                "country_id": 160,
                "name": " Nigerian Coll. of Aviation Tech., Zaira",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 51,
                "country_id": 160,
                "name": " Novena University Ogume",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 52,
                "country_id": 160,
                "name": " Nuhu Bamalli polytechnic, Zaria, Kaduna State",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 53,
                "country_id": 160,
                "name": " Osun State College of Education, Ila-Orangun",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 54,
                "country_id": 160,
                "name": " Osun State College of Education, Ilesa",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 55,
                "country_id": 160,
                "name": " Osun State College of Technology, Esa-Oke",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 56,
                "country_id": 160,
                "name": " Osun State Polytechnic, Iree",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 57,
                "country_id": 160,
                "name": " Oyo State College Of Education, Oyo",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 58,
                "country_id": 160,
                "name": " Ramat polytechnic, Maiduguri",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 59,
                "country_id": 160,
                "name": " River State polytechnic, Bori",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 60,
                "country_id": 160,
                "name": " Rufus Giwa polytechnic, Owo",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 61,
                "country_id": 160,
                "name": " The polytechnic, Ibadan",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 997,
                "country_id": 160,
                "name": "(Igbo) National High School (Naco), Aba",
                "created_at": "2021-08-21T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1852,
                "country_id": 160,
                "name": "5th Avenue Primary School",
                "created_at": "2021-12-22T20:42:59.000000Z",
                "updated_at": "2021-12-22T20:42:59.000000Z"
            },
            {
                "id": 1670,
                "country_id": 160,
                "name": "A-Z International Secondary School, Isolo, est. 1979",
                "created_at": "2023-06-25T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1305,
                "country_id": 160,
                "name": "Aaye-Oja Community Grammar School, Moba LGA, Aaye Oja Ekiti",
                "created_at": "2022-06-25T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1188,
                "country_id": 160,
                "name": "Abbi Girls Secondary School, Abbi",
                "created_at": "2022-02-28T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1187,
                "country_id": 160,
                "name": "Abbi Grammar School, Abbi",
                "created_at": "2022-02-27T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2096,
                "country_id": 160,
                "name": "Abdu Gusau Polytechnic",
                "created_at": "2022-06-15T13:29:10.000000Z",
                "updated_at": "2022-06-15T13:29:10.000000Z"
            },
            {
                "id": 1578,
                "country_id": 160,
                "name": "Abdullahi fodio Islamic institute Birnin kebbi",
                "created_at": "2023-03-25T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1577,
                "country_id": 160,
                "name": "Abdullahi fodio secondary school Birnin kebbi",
                "created_at": "2023-03-24T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1592,
                "country_id": 160,
                "name": "Abdullazeez Attah memorial college, Okene",
                "created_at": "2023-04-08T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1858,
                "country_id": 160,
                "name": "Abeokuta Girls Grammar School",
                "created_at": "2021-12-22T21:00:47.000000Z",
                "updated_at": "2021-12-22T21:00:47.000000Z"
            },
            {
                "id": 1857,
                "country_id": 160,
                "name": "Abeokuta Grammar School",
                "created_at": "2021-12-22T21:00:47.000000Z",
                "updated_at": "2021-12-22T21:00:47.000000Z"
            },
            {
                "id": 1654,
                "country_id": 160,
                "name": "Abesan High School, Abesan Estate",
                "created_at": "2023-06-09T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 62,
                "country_id": 160,
                "name": "Abia State polytechnic",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 63,
                "country_id": 160,
                "name": "Abia State University",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 1135,
                "country_id": 160,
                "name": "Abonnema Girls Secondary School, Abonnema",
                "created_at": "2022-01-06T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2009,
                "country_id": 160,
                "name": "Abraham Adesanya Polytechnic",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1029,
                "country_id": 160,
                "name": "ABTI Academy, Yola",
                "created_at": "2021-09-22T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1028,
                "country_id": 160,
                "name": "ABTI Priary School, Yola",
                "created_at": "2021-09-21T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 64,
                "country_id": 160,
                "name": "Abubakar Tafawa Balewa University",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 965,
                "country_id": 160,
                "name": "Abuja Federal capital territor lege, Kaura district",
                "created_at": "2021-07-20T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1159,
                "country_id": 160,
                "name": "Access High Schools, Calabar",
                "created_at": "2022-01-30T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1173,
                "country_id": 160,
                "name": "Access High Schools, Calabar",
                "created_at": "2022-02-13T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1021,
                "country_id": 160,
                "name": "Achara Secondary School, IHECHIOWA",
                "created_at": "2021-09-14T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 65,
                "country_id": 160,
                "name": "Achievers University",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 1189,
                "country_id": 160,
                "name": "Adadja Secondary School, Emadadja",
                "created_at": "2022-03-01T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2053,
                "country_id": 160,
                "name": "Adagbrassa Primary School",
                "created_at": "2022-01-26T04:29:25.000000Z",
                "updated_at": "2022-01-26T04:29:25.000000Z"
            },
            {
                "id": 1996,
                "country_id": 160,
                "name": "Adagwe Grammar School",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 66,
                "country_id": 160,
                "name": "Adamawa State University",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 67,
                "country_id": 160,
                "name": "Adekunle Ajasin University",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 68,
                "country_id": 160,
                "name": "Adekunle Ajasin University, Akungba-Akoko",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 69,
                "country_id": 160,
                "name": "Adeleke University, Ede Osun State",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 70,
                "country_id": 160,
                "name": "Adeniran Ogunsanya College of Education",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 1617,
                "country_id": 160,
                "name": "Adesoye College, Offa, est. 1987",
                "created_at": "2023-05-03T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1838,
                "country_id": 160,
                "name": "Adiso Bashuar Primary School",
                "created_at": "2021-12-21T22:46:53.000000Z",
                "updated_at": "2021-12-21T22:46:53.000000Z"
            },
            {
                "id": 1297,
                "country_id": 160,
                "name": "Ado Community High School, Ado Ekiti",
                "created_at": "2022-06-17T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1076,
                "country_id": 160,
                "name": "Ado Girls' Secondary School, Onitsha",
                "created_at": "2021-11-08T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1246,
                "country_id": 160,
                "name": "Adolo College, Uselu, Benin City, est. 1960",
                "created_at": "2022-04-27T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1245,
                "country_id": 160,
                "name": "Aduwawa secondary school,off auchi road,Benin city",
                "created_at": "2022-04-26T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1006,
                "country_id": 160,
                "name": "Afaraukwu Secondary Technical School,[Umuahia]",
                "created_at": "2021-08-30T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 71,
                "country_id": 160,
                "name": "Afe Babalola University",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 1973,
                "country_id": 160,
                "name": "Afenoid Enterprise Limited",
                "created_at": "2022-01-20T02:49:44.000000Z",
                "updated_at": "2022-01-20T02:49:44.000000Z"
            },
            {
                "id": 1315,
                "country_id": 160,
                "name": "African Church Comprehensive High School Ikere Ekiti Est 1969",
                "created_at": "2022-07-05T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 72,
                "country_id": 160,
                "name": "African University of Science and Technology, Abuja",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 73,
                "country_id": 160,
                "name": "African University Of Technology And Management",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 1351,
                "country_id": 160,
                "name": "Aguobu Iwollo Community Secondary School Umegbo",
                "created_at": "2022-08-10T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 74,
                "country_id": 160,
                "name": "Ahmadu Bello University",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 1031,
                "country_id": 160,
                "name": "Ahmadu Ribadu College, Yola",
                "created_at": "2021-09-24T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1655,
                "country_id": 160,
                "name": "Airforce Primary School, Ikeja",
                "created_at": "2023-06-10T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1656,
                "country_id": 160,
                "name": "Airforce Secondary School, Ikeja",
                "created_at": "2023-06-11T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1147,
                "country_id": 160,
                "name": "Airforce Secondary School, Makurdi",
                "created_at": "2022-01-18T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2057,
                "country_id": 160,
                "name": "Aitch American Model College",
                "created_at": "2022-01-26T04:29:25.000000Z",
                "updated_at": "2022-01-26T04:29:25.000000Z"
            },
            {
                "id": 1850,
                "country_id": 160,
                "name": "Aiyetoro Comprehensive School",
                "created_at": "2021-12-22T01:45:13.000000Z",
                "updated_at": "2021-12-22T01:45:13.000000Z"
            },
            {
                "id": 1657,
                "country_id": 160,
                "name": "Ajara Grammar School, Ajara-Badagry",
                "created_at": "2023-06-12T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1890,
                "country_id": 160,
                "name": "Ajayi Crowther Memorial Grammar School",
                "created_at": "2021-12-23T17:38:43.000000Z",
                "updated_at": "2021-12-23T17:38:43.000000Z"
            },
            {
                "id": 75,
                "country_id": 160,
                "name": "Ajayi Crowther University Oyo",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 1658,
                "country_id": 160,
                "name": "Ajeromi Ifelodun High School, Apapa",
                "created_at": "2023-06-13T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1967,
                "country_id": 160,
                "name": "Ajoke Model Nursery & Primary School",
                "created_at": "2022-01-20T02:49:44.000000Z",
                "updated_at": "2022-01-20T02:49:44.000000Z"
            },
            {
                "id": 1867,
                "country_id": 160,
                "name": "Ajumoni Junior and Senior Secondary School",
                "created_at": "2021-12-22T21:20:01.000000Z",
                "updated_at": "2021-12-22T21:20:01.000000Z"
            },
            {
                "id": 1784,
                "country_id": 160,
                "name": "Akande Danhunsi Memorial High School",
                "created_at": "2021-12-03T21:40:52.000000Z",
                "updated_at": "2021-12-03T21:40:52.000000Z"
            },
            {
                "id": 1828,
                "country_id": 160,
                "name": "Akanu Ohafia Secondary School, Ohafia, Abia State",
                "created_at": "2021-12-10T19:23:30.000000Z",
                "updated_at": "2021-12-10T19:23:30.000000Z"
            },
            {
                "id": 1244,
                "country_id": 160,
                "name": "Akenzua Secondary School, I.C.E road, Benin city, Nigeria.",
                "created_at": "2022-04-25T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1154,
                "country_id": 160,
                "name": "Akim Akim Model High School,Odukpani,est .1997",
                "created_at": "2022-01-25T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1660,
                "country_id": 160,
                "name": "Akin Jacob Memorial Baptist Primary School, Surulere",
                "created_at": "2023-06-15T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1659,
                "country_id": 160,
                "name": "Akintan Grammar School, Surulere Lagos",
                "created_at": "2023-06-14T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1282,
                "country_id": 160,
                "name": "Akoko-Edo Grammar School (AKOGRAMS), Uneme-Nekhua, Akoko-Edo LGA",
                "created_at": "2022-06-02T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 76,
                "country_id": 160,
                "name": "AKPERAN ORSHI COLLEGE OF AGRICULTURE",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 1432,
                "country_id": 160,
                "name": "Akram Secondary School Jahun Jigawa State",
                "created_at": "2022-10-30T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 77,
                "country_id": 160,
                "name": "Akwa Ibom State polytechnic",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 78,
                "country_id": 160,
                "name": "Akwa Ibom State University",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 1408,
                "country_id": 160,
                "name": "Akwakuma Girls Secondary School, Akwakuma, Owerri, Imo State",
                "created_at": "2022-10-06T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1535,
                "country_id": 160,
                "name": "Al Ansar Girls College",
                "created_at": "2023-02-10T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 79,
                "country_id": 160,
                "name": "Al-hikmah university, ilorin",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 1206,
                "country_id": 160,
                "name": "Aladja College, Aladja",
                "created_at": "2022-03-18T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1653,
                "country_id": 160,
                "name": "Albesta Academy, Lekki Lagos",
                "created_at": "2023-06-08T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1202,
                "country_id": 160,
                "name": "Alderstown Primary School 'A', Warri",
                "created_at": "2022-03-14T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1203,
                "country_id": 160,
                "name": "Alderstown Primary School 'B', Warri",
                "created_at": "2022-03-15T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1204,
                "country_id": 160,
                "name": "Alderstown School for the Deaf, Warri",
                "created_at": "2022-03-16T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1215,
                "country_id": 160,
                "name": "Alegbo College, Effurun",
                "created_at": "2022-03-27T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1433,
                "country_id": 160,
                "name": "Alhudahuda College, Zaria",
                "created_at": "2022-10-31T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1900,
                "country_id": 160,
                "name": "Alimosho Grammar School",
                "created_at": "2021-12-23T18:15:34.000000Z",
                "updated_at": "2021-12-23T18:15:34.000000Z"
            },
            {
                "id": 1042,
                "country_id": 160,
                "name": "Aliyu mustapha college yola",
                "created_at": "2021-10-05T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1030,
                "country_id": 160,
                "name": "Aliyu Mustapha memorial school, Yola",
                "created_at": "2021-09-23T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1124,
                "country_id": 160,
                "name": "Alkali Musa Nursery and Primary School, Azare",
                "created_at": "2021-12-26T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1371,
                "country_id": 160,
                "name": "All Saint College Gombe.",
                "created_at": "2022-08-30T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1019,
                "country_id": 160,
                "name": "All Saints Secondary School ABA",
                "created_at": "2021-09-12T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 80,
                "country_id": 160,
                "name": "Alvan Ikoku Collage of Education Owerri",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 1428,
                "country_id": 160,
                "name": "Amandugba Technical School",
                "created_at": "2022-10-26T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1348,
                "country_id": 160,
                "name": "Amansiodo Boys Sec School",
                "created_at": "2022-08-07T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 81,
                "country_id": 160,
                "name": "Ambrose Alli University",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 1286,
                "country_id": 160,
                "name": "Ambrose Alli University Secondary School Ekpoma",
                "created_at": "2022-06-06T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 967,
                "country_id": 160,
                "name": "American International School",
                "created_at": "2021-07-22T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1661,
                "country_id": 160,
                "name": "American International School of Lagos",
                "created_at": "2023-06-16T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1953,
                "country_id": 160,
                "name": "Amichi Central School Amichi",
                "created_at": "2022-01-20T02:49:44.000000Z",
                "updated_at": "2022-01-20T02:49:44.000000Z"
            },
            {
                "id": 1507,
                "country_id": 160,
                "name": "Aminu Kano Community Commercial College Kano",
                "created_at": "2023-01-13T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1306,
                "country_id": 160,
                "name": "Amoye Grammar School, Ikere Ekiti. est 1959",
                "created_at": "2022-06-26T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1662,
                "country_id": 160,
                "name": "Amuwo Odofin Secondary school, Mile 2",
                "created_at": "2023-06-17T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 82,
                "country_id": 160,
                "name": "Anambra State University",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 1794,
                "country_id": 160,
                "name": "Anchorleg International Academy",
                "created_at": "2021-12-06T12:05:52.000000Z",
                "updated_at": "2021-12-06T12:05:52.000000Z"
            },
            {
                "id": 1213,
                "country_id": 160,
                "name": "Anglican Girls College, Ughelli",
                "created_at": "2022-03-25T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1663,
                "country_id": 160,
                "name": "Anglican Girls' Grammar School, Lagos",
                "created_at": "2023-06-18T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1078,
                "country_id": 160,
                "name": "Anglican Girls' Secondary School, Onitsha",
                "created_at": "2021-11-10T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1635,
                "country_id": 160,
                "name": "Anglican Grammar School",
                "created_at": "2023-05-21T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1298,
                "country_id": 160,
                "name": "Anglican School, Okemesi-Ekiti",
                "created_at": "2022-06-18T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1997,
                "country_id": 160,
                "name": "Aniemeke Primary School",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1316,
                "country_id": 160,
                "name": "Annuciation Boy School Ikere",
                "created_at": "2022-07-06T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1651,
                "country_id": 160,
                "name": "Ansar Ud Deen College, Isolo",
                "created_at": "2023-06-06T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1985,
                "country_id": 160,
                "name": "Ansar-Ud-Deen High School",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1977,
                "country_id": 160,
                "name": "Ansar-Ud-Deen Primary School",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1268,
                "country_id": 160,
                "name": "Antie Maria Group Of Schools, Benin City",
                "created_at": "2022-05-19T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2063,
                "country_id": 160,
                "name": "Anwar-Ul Islam Model College Agege",
                "created_at": "2022-01-28T02:22:31.000000Z",
                "updated_at": "2022-01-28T02:22:31.000000Z"
            },
            {
                "id": 1255,
                "country_id": 160,
                "name": "Apex College, Ekor - Ibillo",
                "created_at": "2022-05-06T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1257,
                "country_id": 160,
                "name": "Apex Nursery and Primary School, Ibillo",
                "created_at": "2022-05-08T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1321,
                "country_id": 160,
                "name": "Aramoko District Commercial Secondary School, Aramoko Ekiti",
                "created_at": "2022-07-11T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2078,
                "country_id": 160,
                "name": "Araromi Senior Secondary School",
                "created_at": "2022-01-28T02:22:31.000000Z",
                "updated_at": "2022-01-28T02:22:31.000000Z"
            },
            {
                "id": 1650,
                "country_id": 160,
                "name": "Archbishop aggey memorial secondary school, ilasamaja mushin",
                "created_at": "2023-06-05T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1896,
                "country_id": 160,
                "name": "Archdeacon Adelaja Primary School",
                "created_at": "2021-12-23T17:51:53.000000Z",
                "updated_at": "2021-12-23T17:51:53.000000Z"
            },
            {
                "id": 1518,
                "country_id": 160,
                "name": "Arewa Best Academy",
                "created_at": "2023-01-24T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1488,
                "country_id": 160,
                "name": "Aridason Group of Schools",
                "created_at": "2022-12-25T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1586,
                "country_id": 160,
                "name": "Arigbede College, Lokoja",
                "created_at": "2023-04-02T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1118,
                "country_id": 160,
                "name": "Armour Command Children School, Armour Barracks, Bauchi",
                "created_at": "2021-12-20T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1800,
                "country_id": 160,
                "name": "Armti Staff School Primary School, Ilorin",
                "created_at": "2021-12-06T13:00:02.000000Z",
                "updated_at": "2021-12-06T13:00:02.000000Z"
            },
            {
                "id": 1772,
                "country_id": 160,
                "name": "Army Cantonment Secondary School, Ikeja",
                "created_at": "2021-12-03T21:30:31.000000Z",
                "updated_at": "2021-12-03T21:30:31.000000Z"
            },
            {
                "id": 1664,
                "country_id": 160,
                "name": "Army Children High School, Epe Atlantic Hall, Epe",
                "created_at": "2023-06-19T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1522,
                "country_id": 160,
                "name": "Army Day Secondary School",
                "created_at": "2023-01-28T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1575,
                "country_id": 160,
                "name": "Army day secondary school Birnin kebbi",
                "created_at": "2023-03-22T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1238,
                "country_id": 160,
                "name": "Army Day Secondary School, Effurun",
                "created_at": "2022-04-19T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1587,
                "country_id": 160,
                "name": "Army Day Secondary School, Lokoja",
                "created_at": "2023-04-03T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 994,
                "country_id": 160,
                "name": "ARWA Basic Schools,zone 4",
                "created_at": "2021-08-18T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1801,
                "country_id": 160,
                "name": "Asa Local Government Commercial Secondary School",
                "created_at": "2021-12-06T13:00:02.000000Z",
                "updated_at": "2021-12-06T13:00:02.000000Z"
            },
            {
                "id": 1185,
                "country_id": 160,
                "name": "Asagba mixed secondary school",
                "created_at": "2022-02-25T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1527,
                "country_id": 160,
                "name": "Asamu Comprehensive College",
                "created_at": "2023-02-02T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1841,
                "country_id": 160,
                "name": "Ashdale College Ojodu",
                "created_at": "2021-12-21T22:46:53.000000Z",
                "updated_at": "2021-12-21T22:46:53.000000Z"
            },
            {
                "id": 1541,
                "country_id": 160,
                "name": "Assalam Secondary School Kano",
                "created_at": "2023-02-16T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1123,
                "country_id": 160,
                "name": "ATBU Staff Primary School, Bauchi",
                "created_at": "2021-12-25T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1786,
                "country_id": 160,
                "name": "Ate Ikanmu Grammar School",
                "created_at": "2021-12-03T21:45:47.000000Z",
                "updated_at": "2021-12-03T21:45:47.000000Z"
            },
            {
                "id": 1648,
                "country_id": 160,
                "name": "Atlantic-Hall,Poka Epe Lagos",
                "created_at": "2023-06-03T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1278,
                "country_id": 160,
                "name": "Auchi College, Auchi",
                "created_at": "2022-05-29T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1669,
                "country_id": 160,
                "name": "Aunty Ayo international secondary school ikoyi",
                "created_at": "2023-06-24T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1538,
                "country_id": 160,
                "name": "Aunty Laila",
                "created_at": "2023-02-13T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1671,
                "country_id": 160,
                "name": "Aunty May High School, Idimu",
                "created_at": "2023-06-26T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 966,
                "country_id": 160,
                "name": "Ave maria girls' secondary school, jikwoyi Abuja",
                "created_at": "2021-07-21T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1018,
                "country_id": 160,
                "name": "Awesome International Model School, Aba",
                "created_at": "2021-09-11T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1665,
                "country_id": 160,
                "name": "Awodi Ora Secondary School, cardoso, Ajegunle",
                "created_at": "2023-06-20T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1667,
                "country_id": 160,
                "name": "Awori Ajeromi Grammar School, Agboju, Lagos",
                "created_at": "2023-06-22T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1668,
                "country_id": 160,
                "name": "Awori Anglican Comprehensive High School, Ipaja",
                "created_at": "2023-06-23T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1666,
                "country_id": 160,
                "name": "Awori College, Ojo",
                "created_at": "2023-06-21T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1291,
                "country_id": 160,
                "name": "Ayebode High School, Ayebode-Ekiti",
                "created_at": "2022-06-11T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2016,
                "country_id": 160,
                "name": "Ayela Uwangue Academy",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1652,
                "country_id": 160,
                "name": "Ayetoro senior grammar school lagos street ebute metta",
                "created_at": "2023-06-07T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1256,
                "country_id": 160,
                "name": "Azane Model Primary School, Ibillo",
                "created_at": "2022-05-07T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1880,
                "country_id": 160,
                "name": "Babcock University High School",
                "created_at": "2021-12-23T12:05:03.000000Z",
                "updated_at": "2021-12-23T12:05:03.000000Z"
            },
            {
                "id": 83,
                "country_id": 160,
                "name": "Babcock University, ilishan Remo",
                "created_at": "2021-07-19T09:51:01.000000Z",
                "updated_at": "2021-07-19T09:51:01.000000Z"
            },
            {
                "id": 84,
                "country_id": 160,
                "name": "Babcock University, Ogun State",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1672,
                "country_id": 160,
                "name": "Babington Macaulay Junior Seminary, (BMJS)Ikorodu, est. 1996",
                "created_at": "2023-06-27T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1856,
                "country_id": 160,
                "name": "Baboko Community Secondary School",
                "created_at": "2021-12-22T20:42:59.000000Z",
                "updated_at": "2021-12-22T20:42:59.000000Z"
            },
            {
                "id": 1673,
                "country_id": 160,
                "name": "Badagry Grammar School., Badagry, established 1955",
                "created_at": "2023-06-28T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1126,
                "country_id": 160,
                "name": "Bakari Dukku Primary School, Bauchi",
                "created_at": "2021-12-28T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1824,
                "country_id": 160,
                "name": "Banky Academy, Ekiti",
                "created_at": "2021-12-10T19:23:30.000000Z",
                "updated_at": "2021-12-10T19:23:30.000000Z"
            },
            {
                "id": 1674,
                "country_id": 160,
                "name": "Baptist Academy, Obanikoro, established 1855",
                "created_at": "2023-06-29T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1675,
                "country_id": 160,
                "name": "Baptist Boys High School, Yaba, est. 1885",
                "created_at": "2023-06-30T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1982,
                "country_id": 160,
                "name": "Baptist High School",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 968,
                "country_id": 160,
                "name": "Baptist High School, Abuja",
                "created_at": "2021-07-23T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1247,
                "country_id": 160,
                "name": "Baptist High School, Benin City",
                "created_at": "2022-04-28T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1220,
                "country_id": 160,
                "name": "Baptist High School, Eku",
                "created_at": "2022-04-01T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1229,
                "country_id": 160,
                "name": "Baptist High School, Ororekpe",
                "created_at": "2022-04-10T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1631,
                "country_id": 160,
                "name": "Baptist Model High School, Ilorin",
                "created_at": "2023-05-17T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1483,
                "country_id": 160,
                "name": "Baptist Secondary School Kafanchan",
                "created_at": "2022-12-20T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1768,
                "country_id": 160,
                "name": "Barachel Model College",
                "created_at": "2021-12-02T19:21:30.000000Z",
                "updated_at": "2021-12-02T19:21:30.000000Z"
            },
            {
                "id": 1434,
                "country_id": 160,
                "name": "Barewa college, Zaria",
                "created_at": "2022-11-01T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 85,
                "country_id": 160,
                "name": "Bauchi State University, Gadau",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 86,
                "country_id": 160,
                "name": "Bayero University, Kano",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 87,
                "country_id": 160,
                "name": "Baze University,Abuja",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 88,
                "country_id": 160,
                "name": "Bells University of Technology Ota",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 89,
                "country_id": 160,
                "name": "Bells University of Technology, Ota Ogun State",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1963,
                "country_id": 160,
                "name": "Benin Technical College",
                "created_at": "2022-01-20T02:49:44.000000Z",
                "updated_at": "2022-01-20T02:49:44.000000Z"
            },
            {
                "id": 90,
                "country_id": 160,
                "name": "Benson Idahosa university",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 91,
                "country_id": 160,
                "name": "Benson Idahosa University, Edo State",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 92,
                "country_id": 160,
                "name": "Benue state polytechnic, ugbokolo",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 93,
                "country_id": 160,
                "name": "Benue State University",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1979,
                "country_id": 160,
                "name": "Best Legacy Islamic School",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1466,
                "country_id": 160,
                "name": "Bethel baptist high school, kaduna",
                "created_at": "2022-12-03T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1055,
                "country_id": 160,
                "name": "Beulah International School, Etinan",
                "created_at": "2021-10-18T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 94,
                "country_id": 160,
                "name": "Bingham University, Nasarawa ",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1676,
                "country_id": 160,
                "name": "Birch Freeman High School, Surulere, Lagos",
                "created_at": "2023-07-01T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1406,
                "country_id": 160,
                "name": "Bishop Lasbrey Girls Secondary School, Irete, Owerri",
                "created_at": "2022-10-04T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1822,
                "country_id": 160,
                "name": "Bishop Okoye Memorial Nursery and Primary School",
                "created_at": "2021-12-10T19:23:30.000000Z",
                "updated_at": "2021-12-10T19:23:30.000000Z"
            },
            {
                "id": 1414,
                "country_id": 160,
                "name": "Bishop Shannahand College (BSC) orlu Imo State",
                "created_at": "2022-10-12T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1270,
                "country_id": 160,
                "name": "Blessed Saint Paul School",
                "created_at": "2022-05-21T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1868,
                "country_id": 160,
                "name": "BM Computers",
                "created_at": "2021-12-22T21:20:01.000000Z",
                "updated_at": "2021-12-22T21:20:01.000000Z"
            },
            {
                "id": 1774,
                "country_id": 160,
                "name": "Bola Immaculate Nursery and Primary School",
                "created_at": "2021-12-03T21:30:31.000000Z",
                "updated_at": "2021-12-03T21:30:31.000000Z"
            },
            {
                "id": 1678,
                "country_id": 160,
                "name": "Bolade Grammar School Oshodi",
                "created_at": "2023-07-03T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1429,
                "country_id": 160,
                "name": "Bonus Pastor Seminary School Osina",
                "created_at": "2022-10-27T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1632,
                "country_id": 160,
                "name": "Bossey College.",
                "created_at": "2023-05-18T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2007,
                "country_id": 160,
                "name": "Bostom Nursery & Primary School",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 95,
                "country_id": 160,
                "name": "Bowen University, Iwo ",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1086,
                "country_id": 160,
                "name": "Boys High School, Nri",
                "created_at": "2021-11-18T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1093,
                "country_id": 160,
                "name": "Boys High School, Ogbunike",
                "created_at": "2021-11-25T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1067,
                "country_id": 160,
                "name": "Boys Secondary School, Igbo-Ukwu",
                "created_at": "2021-10-30T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1590,
                "country_id": 160,
                "name": "Boys secondary school, Ochaja",
                "created_at": "2023-04-06T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1339,
                "country_id": 160,
                "name": "Boys Secondary School, Umueze-Awkunanaw",
                "created_at": "2022-07-29T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1679,
                "country_id": 160,
                "name": "Boys' Academy, Lagos",
                "created_at": "2023-07-04T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1407,
                "country_id": 160,
                "name": "Boys' secondary school new owerri",
                "created_at": "2022-10-05T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1174,
                "country_id": 160,
                "name": "Bright Future Academy",
                "created_at": "2022-02-14T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1175,
                "country_id": 160,
                "name": "Bright Future Nursery and Primary Schools",
                "created_at": "2022-02-15T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1275,
                "country_id": 160,
                "name": "Brighter Tomorrow Secondary School, Auchi",
                "created_at": "2022-05-26T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1920,
                "country_id": 160,
                "name": "Brightway Secondary School",
                "created_at": "2021-12-27T10:10:15.000000Z",
                "updated_at": "2021-12-27T10:10:15.000000Z"
            },
            {
                "id": 1677,
                "country_id": 160,
                "name": "British International School, Victoria Island, Lagos",
                "created_at": "2023-07-02T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1489,
                "country_id": 160,
                "name": "BUK Staff Secondary school",
                "created_at": "2022-12-26T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1068,
                "country_id": 160,
                "name": "C.K.C Onitsha, est. 1933",
                "created_at": "2021-10-31T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1600,
                "country_id": 160,
                "name": "C.M.M.L Special School, Iyale",
                "created_at": "2023-04-16T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1681,
                "country_id": 160,
                "name": "C.M.S Grammar School, Bariga, est. 1859(befor Olivet hight Oyo)",
                "created_at": "2023-07-06T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1604,
                "country_id": 160,
                "name": "C.O.E Demonstration Sec. School, Ankpa",
                "created_at": "2023-04-20T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1683,
                "country_id": 160,
                "name": "Caleb British Academy, Lagos",
                "created_at": "2023-07-08T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1682,
                "country_id": 160,
                "name": "Caleb International College, Lagos",
                "created_at": "2023-07-07T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 96,
                "country_id": 160,
                "name": "Caleb University, Imota",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1684,
                "country_id": 160,
                "name": "Camp David Academie, Ogba, Ikeja, Lagos",
                "created_at": "2023-07-09T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1685,
                "country_id": 160,
                "name": "Canterbury International School, Ajah, LAgos",
                "created_at": "2023-07-10T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1537,
                "country_id": 160,
                "name": "Captain Memorial College",
                "created_at": "2023-02-12T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1686,
                "country_id": 160,
                "name": "Cardoso High School, Cardoso. Lagos",
                "created_at": "2023-07-11T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 97,
                "country_id": 160,
                "name": "Caritas University, Enugu",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 2018,
                "country_id": 160,
                "name": "Cega College Alagbado",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1053,
                "country_id": 160,
                "name": "Central Comprehensive Secondary School, Afaha-Obong",
                "created_at": "2021-10-16T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2046,
                "country_id": 160,
                "name": "Central Secondary School",
                "created_at": "2022-01-26T04:29:25.000000Z",
                "updated_at": "2022-01-26T04:29:25.000000Z"
            },
            {
                "id": 1618,
                "country_id": 160,
                "name": "Chapel Secondary School, Tanke",
                "created_at": "2023-05-04T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 962,
                "country_id": 160,
                "name": "Chartered Institute of Personnel Management",
                "created_at": "2021-12-02T14:40:13.000000Z",
                "updated_at": "2021-12-02T14:40:13.000000Z"
            },
            {
                "id": 1629,
                "country_id": 160,
                "name": "Cherubim and Seraphim College, Ilorin",
                "created_at": "2023-05-15T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1922,
                "country_id": 160,
                "name": "Chiro Computer Institution\r\n",
                "created_at": "2021-12-27T10:10:15.000000Z",
                "updated_at": "2021-12-27T10:10:15.000000Z"
            },
            {
                "id": 1687,
                "country_id": 160,
                "name": "Chrisland College Idimu (CCI)",
                "created_at": "2023-07-12T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1484,
                "country_id": 160,
                "name": "Christ Ambassadors' College, Kaduna",
                "created_at": "2022-12-21T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1464,
                "country_id": 160,
                "name": "CHRIST ANGLICAN CHURCH NURSERY AND PRIMARY SCHOOL.",
                "created_at": "2022-12-01T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1689,
                "country_id": 160,
                "name": "Christ The Cornerstone International School, est. 1995",
                "created_at": "2023-07-14T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 969,
                "country_id": 160,
                "name": "Christ the King College,Gwagwalada",
                "created_at": "2021-07-24T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1153,
                "country_id": 160,
                "name": "Christian Science College, Federal Housing",
                "created_at": "2022-01-24T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1299,
                "country_id": 160,
                "name": "Christs' School, Ado Ekiti. Est. 1933",
                "created_at": "2022-06-19T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1237,
                "country_id": 160,
                "name": "Chude Girls Grammar School,Sapele",
                "created_at": "2022-04-18T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1065,
                "country_id": 160,
                "name": "City Comprehensive College, Ogidi",
                "created_at": "2021-10-28T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1388,
                "country_id": 160,
                "name": "Claret secondary school new owerri",
                "created_at": "2022-09-16T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1461,
                "country_id": 160,
                "name": "CLASSICAL INTERNATIONAL SCHOOL UNGWAR ROMI, KADUNA",
                "created_at": "2022-11-28T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1462,
                "country_id": 160,
                "name": "CLASSICAL MODEL SCHOOL TUDUN WADA, KADUNA",
                "created_at": "2022-11-29T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1232,
                "country_id": 160,
                "name": "College Education Demonstration Secondary school, Warri",
                "created_at": "2022-04-13T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 98,
                "country_id": 160,
                "name": "College of Agricuture Zuru, Kebbi State",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1585,
                "country_id": 160,
                "name": "College of Arabic & Islamic Studies, Okene",
                "created_at": "2023-04-01T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1303,
                "country_id": 160,
                "name": "College of Education Demonstration Secondary School, Ikere Ekiti.",
                "created_at": "2022-06-23T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 99,
                "country_id": 160,
                "name": "College of Education Ilemona",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1833,
                "country_id": 160,
                "name": "College of Education Oju, Benue State",
                "created_at": "2021-12-21T22:46:53.000000Z",
                "updated_at": "2021-12-21T22:46:53.000000Z"
            },
            {
                "id": 100,
                "country_id": 160,
                "name": "College of Health Techonolgy",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1329,
                "country_id": 160,
                "name": "College Of Immaculate Conception",
                "created_at": "2022-07-19T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1490,
                "country_id": 160,
                "name": "College of Qur'anic Study Ayagi",
                "created_at": "2022-12-27T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1790,
                "country_id": 160,
                "name": "College of Supply Chain Management",
                "created_at": "2021-12-05T14:24:50.000000Z",
                "updated_at": "2021-12-05T14:24:50.000000Z"
            },
            {
                "id": 1690,
                "country_id": 160,
                "name": "College of Technology Secondary School, Yaba",
                "created_at": "2023-07-15T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1346,
                "country_id": 160,
                "name": "Colliery College Ngwo",
                "created_at": "2022-08-05T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1033,
                "country_id": 160,
                "name": "Colonel Isa Memorial School, Yola",
                "created_at": "2021-09-26T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1603,
                "country_id": 160,
                "name": "Comm. Sec. School, Ankpa",
                "created_at": "2023-04-19T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2033,
                "country_id": 160,
                "name": "Command Children School",
                "created_at": "2022-01-26T04:02:10.000000Z",
                "updated_at": "2022-01-26T04:02:10.000000Z"
            },
            {
                "id": 1788,
                "country_id": 160,
                "name": "Command Children School, Dantuku",
                "created_at": "2021-12-03T21:56:31.000000Z",
                "updated_at": "2021-12-03T21:56:31.000000Z"
            },
            {
                "id": 1109,
                "country_id": 160,
                "name": "Command Children School, Shadawanka Barracks, Bauchi",
                "created_at": "2021-12-11T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1823,
                "country_id": 160,
                "name": "Command Day Secondary School, Enugu",
                "created_at": "2021-12-10T19:23:30.000000Z",
                "updated_at": "2021-12-10T19:23:30.000000Z"
            },
            {
                "id": 1694,
                "country_id": 160,
                "name": "Command Day Secondary School, Ojo",
                "created_at": "2023-07-19T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1695,
                "country_id": 160,
                "name": "Command Day Secondary School, Oshodi",
                "created_at": "2023-07-20T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1107,
                "country_id": 160,
                "name": "Command Day Secondary School, Shadawanka Cantonment, Bauchi",
                "created_at": "2021-12-09T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1691,
                "country_id": 160,
                "name": "Command Day Secondary School,Ikeja",
                "created_at": "2023-07-16T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1239,
                "country_id": 160,
                "name": "Command Secondary School, Abakaliki",
                "created_at": "2022-04-20T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1693,
                "country_id": 160,
                "name": "Command Secondary School, Ipaja",
                "created_at": "2023-07-18T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1435,
                "country_id": 160,
                "name": "Command Secondary School, Kaduna",
                "created_at": "2022-11-02T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1436,
                "country_id": 160,
                "name": "Command Secondary School, Zaria",
                "created_at": "2022-11-03T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1344,
                "country_id": 160,
                "name": "Commuinty Secondary School Amagunze",
                "created_at": "2022-08-03T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1137,
                "country_id": 160,
                "name": "Community boy secondary school, Awkuzu",
                "created_at": "2022-01-08T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1101,
                "country_id": 160,
                "name": "Community Boys High School Nnobi",
                "created_at": "2021-12-03T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1099,
                "country_id": 160,
                "name": "Community Boys Secondary School, Obosi",
                "created_at": "2021-12-01T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2022,
                "country_id": 160,
                "name": "Community Grammar School",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1696,
                "country_id": 160,
                "name": "Community Grammar School, Surulere",
                "created_at": "2023-07-21T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1797,
                "country_id": 160,
                "name": "Community High School, Akata, Ogbomoso",
                "created_at": "2021-12-06T13:00:02.000000Z",
                "updated_at": "2021-12-06T13:00:02.000000Z"
            },
            {
                "id": 2077,
                "country_id": 160,
                "name": "Community Primary School",
                "created_at": "2022-01-28T02:22:31.000000Z",
                "updated_at": "2022-01-28T02:22:31.000000Z"
            },
            {
                "id": 1599,
                "country_id": 160,
                "name": "Community Sec. School, Iyale",
                "created_at": "2023-04-15T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1898,
                "country_id": 160,
                "name": "Community Secondary Grammar School Idi-igba/songbe",
                "created_at": "2021-12-23T17:51:53.000000Z",
                "updated_at": "2021-12-23T17:51:53.000000Z"
            },
            {
                "id": 1697,
                "country_id": 160,
                "name": "Community Secondary School",
                "created_at": "2023-07-22T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1954,
                "country_id": 160,
                "name": "Community Secondary School Amichi",
                "created_at": "2022-01-20T02:49:44.000000Z",
                "updated_at": "2022-01-20T02:49:44.000000Z"
            },
            {
                "id": 1342,
                "country_id": 160,
                "name": "Community Secondary School Amokwe.",
                "created_at": "2022-08-01T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 964,
                "country_id": 160,
                "name": "Community Secondary School Araba Salifu",
                "created_at": "2021-07-19T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1612,
                "country_id": 160,
                "name": "Community Secondary School Egbeda-Ega",
                "created_at": "2023-04-28T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1365,
                "country_id": 160,
                "name": "community secondary school Nze",
                "created_at": "2022-08-24T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1853,
                "country_id": 160,
                "name": "Community Secondary School Umumba",
                "created_at": "2021-12-22T20:42:59.000000Z",
                "updated_at": "2021-12-22T20:42:59.000000Z"
            },
            {
                "id": 1103,
                "country_id": 160,
                "name": "Community Secondary School, Ajalli, Orumba North L.G.A.",
                "created_at": "2021-12-05T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 986,
                "country_id": 160,
                "name": "Community Secondary School, Asokoro, Abuja",
                "created_at": "2021-08-10T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1397,
                "country_id": 160,
                "name": "Community Secondary School, Eziorsu, Oguta. 1998",
                "created_at": "2022-09-25T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1332,
                "country_id": 160,
                "name": "Community Secondary School, Isu-Awaa",
                "created_at": "2022-07-22T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1131,
                "country_id": 160,
                "name": "Community Secondary School, Ka",
                "created_at": "2022-01-02T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1058,
                "country_id": 160,
                "name": "Community Secondary School, Oko",
                "created_at": "2021-10-21T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1061,
                "country_id": 160,
                "name": "Community Secondary School, Omor.",
                "created_at": "2021-10-24T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1340,
                "country_id": 160,
                "name": "Community Secondary School, Udi",
                "created_at": "2022-07-30T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1403,
                "country_id": 160,
                "name": "Community Secondary School, Umuna Orlu",
                "created_at": "2022-10-01T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1098,
                "country_id": 160,
                "name": "Community Secondary School,Umuleri",
                "created_at": "2021-11-30T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1888,
                "country_id": 160,
                "name": "Community Senior High School, Lagos",
                "created_at": "2021-12-23T12:05:03.000000Z",
                "updated_at": "2021-12-23T12:05:03.000000Z"
            },
            {
                "id": 1992,
                "country_id": 160,
                "name": "Comprehensive College",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1613,
                "country_id": 160,
                "name": "Comprehensive College,Egbe",
                "created_at": "2023-04-29T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1380,
                "country_id": 160,
                "name": "Comprehensive Day Secondary School Gombe",
                "created_at": "2022-09-08T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1905,
                "country_id": 160,
                "name": "Comprehensive High School",
                "created_at": "2021-12-23T18:15:34.000000Z",
                "updated_at": "2021-12-23T18:15:34.000000Z"
            },
            {
                "id": 1243,
                "country_id": 160,
                "name": "Comprehensive School of Management and Technology (CSMT),Abakaliki",
                "created_at": "2022-04-24T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1976,
                "country_id": 160,
                "name": "Comprehensive Secondary School",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1417,
                "country_id": 160,
                "name": "Comprehensive Secondary School Amaifeke Orlu Imo State",
                "created_at": "2022-10-15T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1424,
                "country_id": 160,
                "name": "Comprehensive Secondary School Isunjaba",
                "created_at": "2022-10-22T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1343,
                "country_id": 160,
                "name": "Comprehensive Secondary School Udi",
                "created_at": "2022-08-02T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1054,
                "country_id": 160,
                "name": "Comprehensive Secondary School, Four-towns, Uyo",
                "created_at": "2021-10-17T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1069,
                "country_id": 160,
                "name": "Comprehensive Secondary School, Nawfia",
                "created_at": "2021-11-01T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1944,
                "country_id": 160,
                "name": "Computer College",
                "created_at": "2021-12-30T13:59:22.000000Z",
                "updated_at": "2021-12-30T13:59:22.000000Z"
            },
            {
                "id": 1032,
                "country_id": 160,
                "name": "Concordia college, Yola, Nigeria",
                "created_at": "2021-09-25T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1248,
                "country_id": 160,
                "name": "Continental Group of Schools, Okunbor Street, Benin City, Nigeria",
                "created_at": "2022-04-29T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1692,
                "country_id": 160,
                "name": "Corona Schools; Ikoyi Day Nursery, Ikoyi, Gbagada, Apapa, Victoria Island and Lekki",
                "created_at": "2023-07-17T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1961,
                "country_id": 160,
                "name": "Coronet Secondary School",
                "created_at": "2022-01-20T02:49:44.000000Z",
                "updated_at": "2022-01-20T02:49:44.000000Z"
            },
            {
                "id": 1353,
                "country_id": 160,
                "name": "Corpous Christie College Achi Umuofia",
                "created_at": "2022-08-12T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1323,
                "country_id": 160,
                "name": "Corpus Christi College, Ilawe Ekiti",
                "created_at": "2022-07-13T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 101,
                "country_id": 160,
                "name": "Covenant University, Canaanland, Ota Ogun State",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 102,
                "country_id": 160,
                "name": "Crawford University, Igbesa Ogun State",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1492,
                "country_id": 160,
                "name": "Crescent International secondary school",
                "created_at": "2022-12-29T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1688,
                "country_id": 160,
                "name": "Crescent Nursery and Primary School, 1004 Federal Housing Estate, Victoria Island",
                "created_at": "2023-07-13T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 103,
                "country_id": 160,
                "name": "Crescent University, Abeokuta",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 104,
                "country_id": 160,
                "name": "Cross River University of technology, calabar",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 2058,
                "country_id": 160,
                "name": "Crown Polytechnic",
                "created_at": "2022-01-26T04:29:25.000000Z",
                "updated_at": "2022-01-26T04:29:25.000000Z"
            },
            {
                "id": 1873,
                "country_id": 160,
                "name": "Crystal Education Center",
                "created_at": "2021-12-22T21:20:01.000000Z",
                "updated_at": "2021-12-22T21:20:01.000000Z"
            },
            {
                "id": 1702,
                "country_id": 160,
                "name": "D-Ivy College, Ikeja Lagos",
                "created_at": "2023-07-27T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 971,
                "country_id": 160,
                "name": "D.M.S.S Secondary School Asokoro, Abuja",
                "created_at": "2021-07-26T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1923,
                "country_id": 160,
                "name": "Damtops International Secondary School",
                "created_at": "2021-12-27T10:10:15.000000Z",
                "updated_at": "2021-12-27T10:10:15.000000Z"
            },
            {
                "id": 1437,
                "country_id": 160,
                "name": "Danbo International College, Barnawa, Kaduna",
                "created_at": "2022-11-04T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1525,
                "country_id": 160,
                "name": "Dantata Memorial Primary School",
                "created_at": "2023-01-31T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1903,
                "country_id": 160,
                "name": "Data Point Computer Institute",
                "created_at": "2021-12-23T18:15:34.000000Z",
                "updated_at": "2021-12-23T18:15:34.000000Z"
            },
            {
                "id": 1649,
                "country_id": 160,
                "name": "Dawah Schools,Jakande Oke Afa Isolo.",
                "created_at": "2023-06-04T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1493,
                "country_id": 160,
                "name": "DAY SCIENCE COLLEGE, KANO(tumbin giwa)",
                "created_at": "2022-12-30T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1066,
                "country_id": 160,
                "name": "Day Star Secondary School,Ogidi",
                "created_at": "2021-10-29T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1094,
                "country_id": 160,
                "name": "De Apex Comprehensive Academy, Ogbunike",
                "created_at": "2021-11-26T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1701,
                "country_id": 160,
                "name": "De Nativity Networks Academy, Mushin",
                "created_at": "2023-07-26T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1515,
                "country_id": 160,
                "name": "De-larfel secondary school",
                "created_at": "2023-01-21T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1700,
                "country_id": 160,
                "name": "Debiruss Schools, Ajah ,Lekki, Lagos.",
                "created_at": "2023-07-25T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2040,
                "country_id": 160,
                "name": "Dee Ultimate Nursery & Primary School",
                "created_at": "2022-01-26T04:02:10.000000Z",
                "updated_at": "2022-01-26T04:02:10.000000Z"
            },
            {
                "id": 1231,
                "country_id": 160,
                "name": "Delta Secondary School, Warri",
                "created_at": "2022-04-12T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 105,
                "country_id": 160,
                "name": "DELTA STATE POLYTECHNIC",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 106,
                "country_id": 160,
                "name": "Delta State Polytechnic, Otete, Oghara",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 107,
                "country_id": 160,
                "name": "Delta State University",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1222,
                "country_id": 160,
                "name": "Delta State University Secondary School, Abraka",
                "created_at": "2022-04-03T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1438,
                "country_id": 160,
                "name": "Demonstration Secondary School, A. B. U., Zaria",
                "created_at": "2022-11-05T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1070,
                "country_id": 160,
                "name": "Dennis Memorial Grammar school, Onitsha, est. 1925",
                "created_at": "2021-11-02T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1969,
                "country_id": 160,
                "name": "Dhaewood Aviation Business School",
                "created_at": "2022-01-20T02:49:44.000000Z",
                "updated_at": "2022-01-20T02:49:44.000000Z"
            },
            {
                "id": 1783,
                "country_id": 160,
                "name": "Dodan Barracks Nur & Pry School",
                "created_at": "2021-12-03T21:40:52.000000Z",
                "updated_at": "2021-12-03T21:40:52.000000Z"
            },
            {
                "id": 1300,
                "country_id": 160,
                "name": "Doherty Memorial Grammar School, Ijero-Ekiti",
                "created_at": "2022-06-20T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1860,
                "country_id": 160,
                "name": "Dolafield College",
                "created_at": "2021-12-22T20:42:59.000000Z",
                "updated_at": "2021-12-22T20:42:59.000000Z"
            },
            {
                "id": 1121,
                "country_id": 160,
                "name": "Dolphin Institute, Bauchi",
                "created_at": "2021-12-23T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1191,
                "country_id": 160,
                "name": "Dom Domingo College, Warri",
                "created_at": "2022-03-03T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 108,
                "country_id": 160,
                "name": "Dorben polytechnic (formerly Abuja School of Accountancy and Computer Studies)",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1698,
                "country_id": 160,
                "name": "Dorcas Memorial College Ajegunle Lagos",
                "created_at": "2023-07-23T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1216,
                "country_id": 160,
                "name": "Dore Numa College, Warri",
                "created_at": "2022-03-28T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1699,
                "country_id": 160,
                "name": "Doregos Private Academy, Ipaja, Lagos (est. Jan 1990)",
                "created_at": "2023-07-24T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1001,
                "country_id": 160,
                "name": "Dority International Secondary School, Aba",
                "created_at": "2021-08-25T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1168,
                "country_id": 160,
                "name": "DSC Technical High School OVWIAN ALADJA, Orhuwhorun",
                "created_at": "2022-02-08T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1950,
                "country_id": 160,
                "name": "Duke Town Secondary School",
                "created_at": "2022-01-20T02:49:44.000000Z",
                "updated_at": "2022-01-20T02:49:44.000000Z"
            },
            {
                "id": 1564,
                "country_id": 160,
                "name": "Dutsinma Science and Technical College",
                "created_at": "2023-03-11T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1705,
                "country_id": 160,
                "name": "Early-Life Secondary School, Festac Town, Lagos",
                "created_at": "2023-07-30T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1158,
                "country_id": 160,
                "name": "Eastern Secondary Commercial School, Calabar",
                "created_at": "2022-01-29T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1235,
                "country_id": 160,
                "name": "Ebedei Secondary School, Adonaishaka Ebedei Marble hill school, asaba delta state",
                "created_at": "2022-04-16T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1420,
                "country_id": 160,
                "name": "Ebenator Ekwe Secondary",
                "created_at": "2022-10-18T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1709,
                "country_id": 160,
                "name": "Ebenezer Comprehensive High School, Ijaiye Ojokoro, Lagos",
                "created_at": "2023-08-03T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1253,
                "country_id": 160,
                "name": "Ebenezer Nursery and Preparatory School, Benin City",
                "created_at": "2022-05-04T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1916,
                "country_id": 160,
                "name": "Ebhoiyi Secondary School",
                "created_at": "2021-12-27T10:10:15.000000Z",
                "updated_at": "2021-12-27T10:10:15.000000Z"
            },
            {
                "id": 1584,
                "country_id": 160,
                "name": "Ebira Community Secondary School, Ogaminana",
                "created_at": "2023-03-31T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 109,
                "country_id": 160,
                "name": "Ebonyi state university",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1372,
                "country_id": 160,
                "name": "ECWA Goodnews Secondary School, Gombe",
                "created_at": "2022-08-31T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1988,
                "country_id": 160,
                "name": "ECWA Nursery & Primary School",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1931,
                "country_id": 160,
                "name": "Ecwa School of Nursing",
                "created_at": "2021-12-30T13:59:22.000000Z",
                "updated_at": "2021-12-30T13:59:22.000000Z"
            },
            {
                "id": 1962,
                "country_id": 160,
                "name": "Edaiken Primary School",
                "created_at": "2022-01-20T02:49:44.000000Z",
                "updated_at": "2022-01-20T02:49:44.000000Z"
            },
            {
                "id": 1156,
                "country_id": 160,
                "name": "Edgerly Memorial Girls Secondary, Calabar",
                "created_at": "2022-01-27T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1145,
                "country_id": 160,
                "name": "Ediwe Memorial College, Adum - East.",
                "created_at": "2022-01-16T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1249,
                "country_id": 160,
                "name": "Edo College, Murtala Mohammed Way, Benin City. Established February",
                "created_at": "2022-04-30T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1908,
                "country_id": 160,
                "name": "Edo State Polytechnic",
                "created_at": "2021-12-23T18:15:34.000000Z",
                "updated_at": "2021-12-23T18:15:34.000000Z"
            },
            {
                "id": 1934,
                "country_id": 160,
                "name": "Edo State School of Nursing and Midwifery",
                "created_at": "2021-12-30T13:59:22.000000Z",
                "updated_at": "2021-12-30T13:59:22.000000Z"
            },
            {
                "id": 110,
                "country_id": 160,
                "name": "Edo University, Iyamho",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1250,
                "country_id": 160,
                "name": "Edokpolo Grammar School, 1st Federal road, Benin city, Nigeria",
                "created_at": "2022-05-01T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1615,
                "country_id": 160,
                "name": "Effective international college Tanke, Ilorin",
                "created_at": "2023-05-01T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1396,
                "country_id": 160,
                "name": "Egbuoma Secondary School, Egbuoma",
                "created_at": "2022-09-24T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1357,
                "country_id": 160,
                "name": "Egede Girls college",
                "created_at": "2022-08-16T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1251,
                "country_id": 160,
                "name": "Eghosa Grammar School",
                "created_at": "2022-05-02T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1197,
                "country_id": 160,
                "name": "Egini Grammar School, Egini",
                "created_at": "2022-03-09T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1209,
                "country_id": 160,
                "name": "Ekakpamre Grammar School, Ekakpamre",
                "created_at": "2022-03-21T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1293,
                "country_id": 160,
                "name": "Ekamefa Commercial Grammar School, Ilasa-Ekiti",
                "created_at": "2022-06-13T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1310,
                "country_id": 160,
                "name": "Ekameta Community High School, Epe/Ara/Araromi Ekiti",
                "created_at": "2022-06-30T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1347,
                "country_id": 160,
                "name": "Eke High School",
                "created_at": "2022-08-06T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1313,
                "country_id": 160,
                "name": "Ekiti state Government college Ado Ekiti",
                "created_at": "2022-07-03T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1314,
                "country_id": 160,
                "name": "Ekiti state Government college Ikere Ekiti",
                "created_at": "2022-07-04T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1312,
                "country_id": 160,
                "name": "Ekiti state Government college Oye Ekiti",
                "created_at": "2022-07-02T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1311,
                "country_id": 160,
                "name": "Ekiti state Government college Usi Ekiti",
                "created_at": "2022-07-01T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 111,
                "country_id": 160,
                "name": "Ekiti State University, Ado Ekiti",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1703,
                "country_id": 160,
                "name": "Eko Boys High School, Mushin, est. 1913",
                "created_at": "2023-07-28T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1704,
                "country_id": 160,
                "name": "Eko Girls' Grammar School Okota, Lagos Est. 1981",
                "created_at": "2023-07-29T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1283,
                "country_id": 160,
                "name": "Ekpedo Community High School, Ekpedo, Akoko-Edo LGA",
                "created_at": "2022-06-03T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1219,
                "country_id": 160,
                "name": "Eku Grammar School, Eku",
                "created_at": "2022-03-31T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1419,
                "country_id": 160,
                "name": "Ekwe Secondary School Ekwe",
                "created_at": "2022-10-17T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1318,
                "country_id": 160,
                "name": "Eleyo High School Ikere",
                "created_at": "2022-07-08T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 112,
                "country_id": 160,
                "name": "Elizade University Ilara - Mokin",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1989,
                "country_id": 160,
                "name": "Emdee International School",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1387,
                "country_id": 160,
                "name": "Emmanuel College, Owerri",
                "created_at": "2022-09-15T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1809,
                "country_id": 160,
                "name": "Emmanuel International School",
                "created_at": "2021-12-06T13:22:08.000000Z",
                "updated_at": "2021-12-06T13:22:08.000000Z"
            },
            {
                "id": 1252,
                "country_id": 160,
                "name": "Emotan College",
                "created_at": "2022-05-03T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1486,
                "country_id": 160,
                "name": "Emphatic Neo-classic College, Kano Nigeria",
                "created_at": "2022-12-23T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1553,
                "country_id": 160,
                "name": "Emphatic Neoclassic",
                "created_at": "2023-02-28T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1182,
                "country_id": 160,
                "name": "Emu-Uno Secondary School, Emu-Uno",
                "created_at": "2022-02-22T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1708,
                "country_id": 160,
                "name": "ENGREG Schools, 24 Bankole Street, Pedro, Somolu.",
                "created_at": "2023-08-02T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1605,
                "country_id": 160,
                "name": "Enjema Comm. Sec. School, Ofugo",
                "created_at": "2023-04-21T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 113,
                "country_id": 160,
                "name": "Enugu State University of Science and Technology",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1706,
                "country_id": 160,
                "name": "Eric Moore Senior High School, Surulere Lagos. 1980",
                "created_at": "2023-07-31T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1881,
                "country_id": 160,
                "name": "Eric Security Limited",
                "created_at": "2021-12-23T12:05:03.000000Z",
                "updated_at": "2021-12-23T12:05:03.000000Z"
            },
            {
                "id": 1322,
                "country_id": 160,
                "name": "Erin Ayonigba High School, Erinjiyan Ekiti",
                "created_at": "2022-07-12T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1205,
                "country_id": 160,
                "name": "Esi College, Warri",
                "created_at": "2022-03-17T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1540,
                "country_id": 160,
                "name": "Essence College",
                "created_at": "2023-02-15T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1057,
                "country_id": 160,
                "name": "Essien itiaba Community Secondary School, Ikot Asua, Nsit Atai",
                "created_at": "2021-10-20T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1778,
                "country_id": 160,
                "name": "Estate Baptist Primary School",
                "created_at": "2021-12-03T21:35:16.000000Z",
                "updated_at": "2021-12-03T21:35:16.000000Z"
            },
            {
                "id": 1046,
                "country_id": 160,
                "name": "Etebi National High School, Etebi-Eket",
                "created_at": "2021-10-09T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1047,
                "country_id": 160,
                "name": "Etinan Institute, Etinan",
                "created_at": "2021-10-10T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1707,
                "country_id": 160,
                "name": "Eva Adelaja Girls Secondary Grammar School, Gbagada 1964 (formerly GSGS Gbagada)",
                "created_at": "2023-08-01T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1844,
                "country_id": 160,
                "name": "Evbiobe Central School",
                "created_at": "2021-12-22T01:04:12.000000Z",
                "updated_at": "2021-12-22T01:04:12.000000Z"
            },
            {
                "id": 1917,
                "country_id": 160,
                "name": "Ewoyi Primary School",
                "created_at": "2021-12-27T10:10:15.000000Z",
                "updated_at": "2021-12-27T10:10:15.000000Z"
            },
            {
                "id": 1532,
                "country_id": 160,
                "name": "Excel College",
                "created_at": "2023-02-07T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1176,
                "country_id": 160,
                "name": "Excellence School of science and creativity",
                "created_at": "2022-02-16T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2074,
                "country_id": 160,
                "name": "Excellent Group of School",
                "created_at": "2022-01-28T02:22:31.000000Z",
                "updated_at": "2022-01-28T02:22:31.000000Z"
            },
            {
                "id": 1413,
                "country_id": 160,
                "name": "Eziachi Secondary School Orlu (ESSO) Imo State",
                "created_at": "2022-10-11T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1514,
                "country_id": 160,
                "name": "FAAN Secondary School Kano",
                "created_at": "2023-01-20T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2039,
                "country_id": 160,
                "name": "Fadekemi Schools",
                "created_at": "2022-01-26T04:02:10.000000Z",
                "updated_at": "2022-01-26T04:02:10.000000Z"
            },
            {
                "id": 1517,
                "country_id": 160,
                "name": "Fahad Memorial College",
                "created_at": "2023-01-23T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1874,
                "country_id": 160,
                "name": "Famous Technical Work",
                "created_at": "2021-12-22T20:42:59.000000Z",
                "updated_at": "2021-12-22T20:42:59.000000Z"
            },
            {
                "id": 1108,
                "country_id": 160,
                "name": "Fariah Foundation School, Fadan Mada, Bauchi",
                "created_at": "2021-12-10T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1866,
                "country_id": 160,
                "name": "Fazil Omar Primary School",
                "created_at": "2021-12-22T21:20:01.000000Z",
                "updated_at": "2021-12-22T21:20:01.000000Z"
            },
            {
                "id": 1595,
                "country_id": 160,
                "name": "Fed. Gov. College, Ugwolawo",
                "created_at": "2023-04-11T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 114,
                "country_id": 160,
                "name": "Federal College of Agriculture, Akure",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 115,
                "country_id": 160,
                "name": "Federal College of Agriculture, Ibadan",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 116,
                "country_id": 160,
                "name": "Federal College of Education",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 117,
                "country_id": 160,
                "name": "Federal College of Education (Technical) Gombe",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 118,
                "country_id": 160,
                "name": "Federal College of Education (Technical) Gusau",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 119,
                "country_id": 160,
                "name": "Federal College of Education, Abeokuta",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 120,
                "country_id": 160,
                "name": "Federal College of Education, Kano",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 121,
                "country_id": 160,
                "name": "Federal College of Forestry",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 122,
                "country_id": 160,
                "name": "Federal college of forestry, Ibadan",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 123,
                "country_id": 160,
                "name": "Federal College Of Orthopaedic Technology,National Orthopaedic Hospital, Igbobi ",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 124,
                "country_id": 160,
                "name": "Federal College of Wildlife Management, New-Bussa",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 125,
                "country_id": 160,
                "name": "Federal Cooperative College Ibadan",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1184,
                "country_id": 160,
                "name": "Federal girl' college, Ibusa.",
                "created_at": "2022-02-24T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1389,
                "country_id": 160,
                "name": "Federal Government Boys College, Uturu, Okigwe",
                "created_at": "2022-09-17T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1845,
                "country_id": 160,
                "name": "Federal Government College",
                "created_at": "2021-12-22T01:12:13.000000Z",
                "updated_at": "2021-12-22T01:12:13.000000Z"
            },
            {
                "id": 1319,
                "country_id": 160,
                "name": "Federal Government College Ikole-Ekiti",
                "created_at": "2022-07-09T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1048,
                "country_id": 160,
                "name": "Federal Government College Ikot Ekpene, Ikot Ekpene",
                "created_at": "2021-10-11T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1485,
                "country_id": 160,
                "name": "Federal Government College Kano, Nigeria",
                "created_at": "2022-12-22T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1710,
                "country_id": 160,
                "name": "Federal Government College Lagos [Ijanikin]",
                "created_at": "2023-08-04T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1439,
                "country_id": 160,
                "name": "Federal Government College MALALI, Kaduna",
                "created_at": "2022-11-06T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1390,
                "country_id": 160,
                "name": "Federal Government College Okigwe",
                "created_at": "2022-09-18T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1571,
                "country_id": 160,
                "name": "Federal Government College Yauri",
                "created_at": "2023-03-18T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1830,
                "country_id": 160,
                "name": "Federal Government College, Abuja",
                "created_at": "2021-12-21T22:46:53.000000Z",
                "updated_at": "2021-12-21T22:46:53.000000Z"
            },
            {
                "id": 1115,
                "country_id": 160,
                "name": "Federal Government College, Azare, Bauchi",
                "created_at": "2021-12-17T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1568,
                "country_id": 160,
                "name": "Federal Government College, Daura",
                "created_at": "2023-03-15T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1840,
                "country_id": 160,
                "name": "Federal Government College, Enugu",
                "created_at": "2021-12-21T22:46:53.000000Z",
                "updated_at": "2021-12-21T22:46:53.000000Z"
            },
            {
                "id": 1035,
                "country_id": 160,
                "name": "Federal Government College, Ganye",
                "created_at": "2021-09-28T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1254,
                "country_id": 160,
                "name": "Federal Government College, Ibillo",
                "created_at": "2022-05-05T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1160,
                "country_id": 160,
                "name": "Federal Government College, Ikom",
                "created_at": "2022-01-31T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1623,
                "country_id": 160,
                "name": "Federal Government College, Ilorin",
                "created_at": "2023-05-09T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1166,
                "country_id": 160,
                "name": "Federal Government College, Warrio",
                "created_at": "2022-02-06T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1925,
                "country_id": 160,
                "name": "Federal Government Girls College",
                "created_at": "2021-12-30T13:39:57.000000Z",
                "updated_at": "2021-12-30T13:39:57.000000Z"
            },
            {
                "id": 1127,
                "country_id": 160,
                "name": "Federal Government Girls College Aubuloma",
                "created_at": "2021-12-29T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1378,
                "country_id": 160,
                "name": "Federal Government Girls College Bajoga",
                "created_at": "2022-09-06T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 972,
                "country_id": 160,
                "name": "Federal Government Girls College Bwari",
                "created_at": "2021-07-27T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1570,
                "country_id": 160,
                "name": "Federal Government Girls College Gwandu",
                "created_at": "2023-03-17T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1626,
                "country_id": 160,
                "name": "Federal Government Girls College Omu-Aran",
                "created_at": "2023-05-12T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1071,
                "country_id": 160,
                "name": "Federal Government Girls College Onitsha",
                "created_at": "2021-11-03T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1560,
                "country_id": 160,
                "name": "Federal Government Girls College, Bakori",
                "created_at": "2023-03-07T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1113,
                "country_id": 160,
                "name": "Federal Government Girls College, Bauchi",
                "created_at": "2021-12-15T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1258,
                "country_id": 160,
                "name": "Federal Government Girls College, Benin City",
                "created_at": "2022-05-09T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1150,
                "country_id": 160,
                "name": "Federal Government Girls College, Calabar",
                "created_at": "2022-01-21T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1391,
                "country_id": 160,
                "name": "Federal Government Girls College, Owerri",
                "created_at": "2022-09-19T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1005,
                "country_id": 160,
                "name": "Federal Government Girls College, Umuahia",
                "created_at": "2021-08-29T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1036,
                "country_id": 160,
                "name": "Federal Government Girls' College, Yola",
                "created_at": "2021-09-29T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1133,
                "country_id": 160,
                "name": "Federal Government Primary School, Shell Road, Bonny",
                "created_at": "2022-01-04T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 126,
                "country_id": 160,
                "name": "Federal polytechnic Bida",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 2086,
                "country_id": 160,
                "name": "Federal Polytechnic Idah",
                "created_at": "2022-04-19T12:44:31.000000Z",
                "updated_at": "2022-04-19T12:44:31.000000Z"
            },
            {
                "id": 127,
                "country_id": 160,
                "name": "Federal Polytechnic Kaura Namoda",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 128,
                "country_id": 160,
                "name": "Federal polytechnic Offa",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1116,
                "country_id": 160,
                "name": "Federal Polytechnic Staff School, Bauchi",
                "created_at": "2021-12-18T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1769,
                "country_id": 160,
                "name": "Federal School of Arts and Science, Lagos",
                "created_at": "2021-12-02T19:25:12.000000Z",
                "updated_at": "2021-12-02T19:25:12.000000Z"
            },
            {
                "id": 129,
                "country_id": 160,
                "name": "Federal School Of Dental Technology &Therapy, Enugu",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 130,
                "country_id": 160,
                "name": "Federal school of surveying oyo",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1835,
                "country_id": 160,
                "name": "Federal Science and Technical College",
                "created_at": "2021-12-21T22:46:53.000000Z",
                "updated_at": "2021-12-21T22:46:53.000000Z"
            },
            {
                "id": 1157,
                "country_id": 160,
                "name": "Federal Science College, Ogoja",
                "created_at": "2022-01-28T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1998,
                "country_id": 160,
                "name": "Federal Staff Business College",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1262,
                "country_id": 160,
                "name": "Federal Staff Secondary School, Benin City",
                "created_at": "2022-05-13T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 131,
                "country_id": 160,
                "name": "Federal University Dutse",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 132,
                "country_id": 160,
                "name": "Federal University Dutsin-Ma, Katsina State.",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 133,
                "country_id": 160,
                "name": "Federal University Gashua",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 134,
                "country_id": 160,
                "name": "Federal University Gusau",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 135,
                "country_id": 160,
                "name": "Federal University Kashere",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 136,
                "country_id": 160,
                "name": "Federal University Lafia,Nazarawa State",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 137,
                "country_id": 160,
                "name": "Federal University Ndufu-Alike Ikwo",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 138,
                "country_id": 160,
                "name": "Federal University of Agriculture, Abeokuta",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 139,
                "country_id": 160,
                "name": "Federal University of Oye- Ekiti",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 140,
                "country_id": 160,
                "name": "Federal University of Petroleum Resources, Effurun",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 141,
                "country_id": 160,
                "name": "Federal University of Technology Akure",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 142,
                "country_id": 160,
                "name": "Federal University of Technology Minna",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 143,
                "country_id": 160,
                "name": "Federal University of Technology Owerri",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 144,
                "country_id": 160,
                "name": "Federal University of Technology Yola",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 146,
                "country_id": 160,
                "name": "Federal University Otuoke",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 147,
                "country_id": 160,
                "name": "Federal University Wukari",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 148,
                "country_id": 160,
                "name": "Federal University Wukari, Taraba State",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 149,
                "country_id": 160,
                "name": "Federal University, Lokoja",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1334,
                "country_id": 160,
                "name": "Ferdrel Secondary School Enugu",
                "created_at": "2022-07-24T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1711,
                "country_id": 160,
                "name": "Festac College, (formerly Festac Community High School)Festac Town, Lagos",
                "created_at": "2023-08-05T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1846,
                "country_id": 160,
                "name": "Festac Grammar School",
                "created_at": "2021-12-22T01:45:13.000000Z",
                "updated_at": "2021-12-22T01:45:13.000000Z"
            },
            {
                "id": 1196,
                "country_id": 160,
                "name": "Fieldcrest International School,Ekete, Warri",
                "created_at": "2022-03-08T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1851,
                "country_id": 160,
                "name": "First Baptist Church Nursery and Primary School",
                "created_at": "2021-12-22T20:42:59.000000Z",
                "updated_at": "2021-12-22T20:42:59.000000Z"
            },
            {
                "id": 1889,
                "country_id": 160,
                "name": "First Class International College",
                "created_at": "2021-12-23T17:38:43.000000Z",
                "updated_at": "2021-12-23T17:38:43.000000Z"
            },
            {
                "id": 1971,
                "country_id": 160,
                "name": "First Foundation College",
                "created_at": "2022-01-20T02:49:44.000000Z",
                "updated_at": "2022-01-20T02:49:44.000000Z"
            },
            {
                "id": 1551,
                "country_id": 160,
                "name": "First Grade Comprhensive School",
                "created_at": "2023-02-26T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1987,
                "country_id": 160,
                "name": "First Zion High School",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 2030,
                "country_id": 160,
                "name": "Fiwasaye Girls Grammar School",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1712,
                "country_id": 160,
                "name": "Folbim High School, Agege Lagos",
                "created_at": "2023-08-06T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1120,
                "country_id": 160,
                "name": "FOMWAN Nursery and Primary School, Bauchi",
                "created_at": "2021-12-22T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 150,
                "country_id": 160,
                "name": "Fountain University, Osogbo",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 973,
                "country_id": 160,
                "name": "Funtaj High School, Kuje",
                "created_at": "2021-07-28T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1915,
                "country_id": 160,
                "name": "G & G OF LONDON",
                "created_at": "2021-12-27T10:10:15.000000Z",
                "updated_at": "2021-12-27T10:10:15.000000Z"
            },
            {
                "id": 1972,
                "country_id": 160,
                "name": "G4S First Aid",
                "created_at": "2022-01-20T02:49:44.000000Z",
                "updated_at": "2022-01-20T02:49:44.000000Z"
            },
            {
                "id": 1381,
                "country_id": 160,
                "name": "Gandu Junior Secondary School Gombe",
                "created_at": "2022-09-09T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1999,
                "country_id": 160,
                "name": "Garden of Success Schools",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1713,
                "country_id": 160,
                "name": "Gaskiya College, Cardoso Lagos",
                "created_at": "2023-08-07T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1440,
                "country_id": 160,
                "name": "Gaskiya Skills International Schools Barnawa Kaduna",
                "created_at": "2022-11-07T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1528,
                "country_id": 160,
                "name": "Gateway College",
                "created_at": "2023-02-03T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 151,
                "country_id": 160,
                "name": "Gateway polytechnic Saapade",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1714,
                "country_id": 160,
                "name": "Gbagada Grammar School, Gbagada Bariga",
                "created_at": "2023-08-08T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1715,
                "country_id": 160,
                "name": "Gbaja Boys secondary school Surulere",
                "created_at": "2023-08-09T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1034,
                "country_id": 160,
                "name": "General Murtala Muhammed College, Yola",
                "created_at": "2021-09-27T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1728,
                "country_id": 160,
                "name": "Gideon Comprehensive High School Isolo Okota",
                "created_at": "2023-08-22T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1138,
                "country_id": 160,
                "name": "Girl Secondary school, Awkuzu",
                "created_at": "2022-01-09T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1095,
                "country_id": 160,
                "name": "Girls High School, Umuleri",
                "created_at": "2021-11-27T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2026,
                "country_id": 160,
                "name": "Girls Secondary School",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1354,
                "country_id": 160,
                "name": "Girls Secondary School Achi",
                "created_at": "2022-08-13T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1355,
                "country_id": 160,
                "name": "Girls Secondary School Emene",
                "created_at": "2022-08-14T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1416,
                "country_id": 160,
                "name": "Girls Secondary School Iheoma Orlu Imo State",
                "created_at": "2022-10-14T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1418,
                "country_id": 160,
                "name": "Girls Secondary School Orlu Imo State",
                "created_at": "2022-10-16T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1328,
                "country_id": 160,
                "name": "Girls Secondary School, Awkunanaw",
                "created_at": "2022-07-18T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1100,
                "country_id": 160,
                "name": "Girls secondary School, Obosi",
                "created_at": "2021-12-02T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2001,
                "country_id": 160,
                "name": "Girls Senior High School",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1422,
                "country_id": 160,
                "name": "Girls Technical School Umuaka",
                "created_at": "2022-10-20T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1716,
                "country_id": 160,
                "name": "Girls' Academy, Lagos",
                "created_at": "2023-08-10T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1393,
                "country_id": 160,
                "name": "Girls' High School, Oguta",
                "created_at": "2022-09-21T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1104,
                "country_id": 160,
                "name": "Girls' High School, Onitsha",
                "created_at": "2021-12-06T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1169,
                "country_id": 160,
                "name": "Girls' Model Secondary School, Evwreni",
                "created_at": "2022-02-09T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1072,
                "country_id": 160,
                "name": "Girls' Secondary School, Igbo-Ukwu",
                "created_at": "2021-11-04T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1398,
                "country_id": 160,
                "name": "Girls' Secondary School, Umucheke, Okwe",
                "created_at": "2022-09-26T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1383,
                "country_id": 160,
                "name": "Girs secondary school owerri, port harcourt road owerri",
                "created_at": "2022-09-11T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2004,
                "country_id": 160,
                "name": "God's Grace Nursery And Primary School",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 152,
                "country_id": 160,
                "name": "Godfrey Okoye University,Enugu",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1869,
                "country_id": 160,
                "name": "Gold Ladder Nursery, Primary and Secondary School",
                "created_at": "2021-12-22T21:20:01.000000Z",
                "updated_at": "2021-12-22T21:20:01.000000Z"
            },
            {
                "id": 2024,
                "country_id": 160,
                "name": "Golden Touch College",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 153,
                "country_id": 160,
                "name": "Gombe State University ",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1090,
                "country_id": 160,
                "name": "Good News College, Nkpor",
                "created_at": "2021-11-22T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1717,
                "country_id": 160,
                "name": "Goshen international College, Ikorodu, Lagos",
                "created_at": "2023-08-11T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1601,
                "country_id": 160,
                "name": "Gov. Girls Sec School, Okaba",
                "created_at": "2023-04-17T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1596,
                "country_id": 160,
                "name": "Gov. Sec School, Dekina",
                "created_at": "2023-04-12T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1606,
                "country_id": 160,
                "name": "Gov. Sec. School, Ogodu",
                "created_at": "2023-04-22T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1597,
                "country_id": 160,
                "name": "Gov. Sec. School, Ologba",
                "created_at": "2023-04-13T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1555,
                "country_id": 160,
                "name": "Government Arabic Secondary School (KWACHIRI)",
                "created_at": "2023-03-02T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1718,
                "country_id": 160,
                "name": "Government College Agege 1974",
                "created_at": "2023-08-12T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1802,
                "country_id": 160,
                "name": "Government College Keffi",
                "created_at": "2021-12-06T13:00:02.000000Z",
                "updated_at": "2021-12-06T13:00:02.000000Z"
            },
            {
                "id": 1719,
                "country_id": 160,
                "name": "Government College Lagos, Eric Moore 1974",
                "created_at": "2023-08-13T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1367,
                "country_id": 160,
                "name": "Government College Nafada",
                "created_at": "2022-08-26T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1721,
                "country_id": 160,
                "name": "Government College Secondary School, Ojo",
                "created_at": "2023-08-15T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1442,
                "country_id": 160,
                "name": "Government College, [Kagoro]",
                "created_at": "2022-11-09T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1441,
                "country_id": 160,
                "name": "Government College, Kaduna",
                "created_at": "2022-11-08T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1559,
                "country_id": 160,
                "name": "Government College, katsina",
                "created_at": "2023-03-06T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1720,
                "country_id": 160,
                "name": "Government College, Ketu-Epe",
                "created_at": "2023-08-14T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1789,
                "country_id": 160,
                "name": "Government College, Lagos",
                "created_at": "2021-12-03T22:02:03.000000Z",
                "updated_at": "2021-12-03T22:02:03.000000Z"
            },
            {
                "id": 1167,
                "country_id": 160,
                "name": "Government College, Ughelli",
                "created_at": "2022-02-07T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1008,
                "country_id": 160,
                "name": "Government College, Umuahia est. 1929",
                "created_at": "2021-09-01T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1370,
                "country_id": 160,
                "name": "Government Day Secondary School Filiya, Shongom",
                "created_at": "2022-08-29T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1640,
                "country_id": 160,
                "name": "Government day secondary school gaa kanbi",
                "created_at": "2023-05-26T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1443,
                "country_id": 160,
                "name": "Government Day Secondary School, Basawa- Zaria.",
                "created_at": "2022-11-10T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1444,
                "country_id": 160,
                "name": "Government Day Secondary School, Bomo- Zaria",
                "created_at": "2022-11-11T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1646,
                "country_id": 160,
                "name": "Government Day Secondary School, Kaiama",
                "created_at": "2023-06-01T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1112,
                "country_id": 160,
                "name": "Government Day Secondary School, Kofar Wambai, Bauchi",
                "created_at": "2021-12-14T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1636,
                "country_id": 160,
                "name": "Government day secondary school, Kulende, Ilorin",
                "created_at": "2023-05-22T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1563,
                "country_id": 160,
                "name": "Government day Secondary School, Mashi",
                "created_at": "2023-03-10T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1038,
                "country_id": 160,
                "name": "Government Day Secondary school, Parka",
                "created_at": "2021-10-01T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1117,
                "country_id": 160,
                "name": "Government Day Secondary School, Shadawanka Cantonment, Bauchi",
                "created_at": "2021-12-19T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1576,
                "country_id": 160,
                "name": "Government Day secondary schoolBirnin kebbi",
                "created_at": "2023-03-23T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1579,
                "country_id": 160,
                "name": "Government Girl College unity Secondary school Birnin kebbi",
                "created_at": "2023-03-26T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1446,
                "country_id": 160,
                "name": "Government Girl's Secondary School, Kawo",
                "created_at": "2022-11-13T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1447,
                "country_id": 160,
                "name": "Government Girl's Secondary School, Zaria",
                "created_at": "2022-11-14T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1520,
                "country_id": 160,
                "name": "Government Girls Arabic Secondary School Dambatta",
                "created_at": "2023-01-26T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1536,
                "country_id": 160,
                "name": "Government Girls College Dala (Bulukiya)",
                "created_at": "2023-02-11T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1114,
                "country_id": 160,
                "name": "Government Girls College, Bauchi",
                "created_at": "2021-12-16T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1557,
                "country_id": 160,
                "name": "Government Girls College, Katsina",
                "created_at": "2023-03-04T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1146,
                "country_id": 160,
                "name": "Government Girls College, Makurdi",
                "created_at": "2022-01-17T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1647,
                "country_id": 160,
                "name": "Government Girls Day Secondary school Oko-Erin, Ilorin",
                "created_at": "2023-06-02T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 975,
                "country_id": 160,
                "name": "Government Girls Science Secondary School, Kuje",
                "created_at": "2021-07-30T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1565,
                "country_id": 160,
                "name": "Government Girls Secondary School, Sandamu",
                "created_at": "2023-03-12T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1566,
                "country_id": 160,
                "name": "Government Girls Secondary School, Shargelle",
                "created_at": "2023-03-13T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1376,
                "country_id": 160,
                "name": "Government Girls' College Doma",
                "created_at": "2022-09-04T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1453,
                "country_id": 160,
                "name": "Government Girls' College, Zonkwa",
                "created_at": "2022-11-20T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1445,
                "country_id": 160,
                "name": "Government Girls' Day Secondary School, Samaru - Zaria",
                "created_at": "2022-11-12T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1448,
                "country_id": 160,
                "name": "Government Girls' Secondary School, Zonkwa",
                "created_at": "2022-11-15T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1148,
                "country_id": 160,
                "name": "Government Model School, Makurdi",
                "created_at": "2022-01-19T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1581,
                "country_id": 160,
                "name": "Government science College Yauri",
                "created_at": "2023-03-28T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1951,
                "country_id": 160,
                "name": "Government Science Secondary School",
                "created_at": "2022-01-20T02:49:44.000000Z",
                "updated_at": "2022-01-20T02:49:44.000000Z"
            },
            {
                "id": 1375,
                "country_id": 160,
                "name": "Government Science Secondary School Gombe",
                "created_at": "2022-09-03T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1588,
                "country_id": 160,
                "name": "Government Science Secondary School, Lokoja",
                "created_at": "2023-04-04T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 976,
                "country_id": 160,
                "name": "Government Science Secondary School, Pyakasa",
                "created_at": "2021-07-31T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1543,
                "country_id": 160,
                "name": "Government secondary commercial school airport road kano",
                "created_at": "2023-02-18T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1481,
                "country_id": 160,
                "name": "Government Secondary Schook Ungwa Rimi Bajju",
                "created_at": "2022-12-18T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1552,
                "country_id": 160,
                "name": "Government Secondary School (KARAYE)",
                "created_at": "2023-02-27T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1241,
                "country_id": 160,
                "name": "Government Secondary School Afikpo",
                "created_at": "2022-04-22T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1813,
                "country_id": 160,
                "name": "Government Secondary School Bacita",
                "created_at": "2021-12-10T18:46:02.000000Z",
                "updated_at": "2021-12-10T18:46:02.000000Z"
            },
            {
                "id": 1519,
                "country_id": 160,
                "name": "Government Secondary School Dambatta",
                "created_at": "2023-01-25T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1451,
                "country_id": 160,
                "name": "Government Secondary School Fadiya TuduWada",
                "created_at": "2022-11-18T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1548,
                "country_id": 160,
                "name": "Government Secondary School Gwammaja (Gwammaja II)",
                "created_at": "2023-02-23T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1511,
                "country_id": 160,
                "name": "Government Secondary school Gwauron Dutse",
                "created_at": "2023-01-17T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1482,
                "country_id": 160,
                "name": "Government Secondary School Kafanchan",
                "created_at": "2022-12-19T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1450,
                "country_id": 160,
                "name": "Government Secondary School Kurmi-Bi, Zonkwa",
                "created_at": "2022-11-17T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1549,
                "country_id": 160,
                "name": "Government Secondary School Kurna Asabe",
                "created_at": "2023-02-24T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1452,
                "country_id": 160,
                "name": "Government Secondary School Madakiya",
                "created_at": "2022-11-19T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1480,
                "country_id": 160,
                "name": "Government Secondary School Marsa",
                "created_at": "2022-12-17T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1382,
                "country_id": 160,
                "name": "Government secondary school owerri, okigwe road owerri",
                "created_at": "2022-09-10T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1056,
                "country_id": 160,
                "name": "Government Secondary School, Afaha Eket, Eket",
                "created_at": "2021-10-19T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 987,
                "country_id": 160,
                "name": "Government Secondary School, Bwari, Abuja.",
                "created_at": "2021-08-11T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1583,
                "country_id": 160,
                "name": "Government Secondary School, Dirin-Daji",
                "created_at": "2023-03-30T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1558,
                "country_id": 160,
                "name": "Government Secondary School, Funtua",
                "created_at": "2023-03-05T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1454,
                "country_id": 160,
                "name": "Government Secondary School, Giwa- Zaria",
                "created_at": "2022-11-21T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 977,
                "country_id": 160,
                "name": "Government Secondary School, Gwagwalada",
                "created_at": "2021-08-01T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1161,
                "country_id": 160,
                "name": "Government Secondary School, Ikom",
                "created_at": "2022-02-01T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1624,
                "country_id": 160,
                "name": "Government Secondary School, Ilorin",
                "created_at": "2023-05-10T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1616,
                "country_id": 160,
                "name": "Government Secondary School, Ipee",
                "created_at": "2023-05-02T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1043,
                "country_id": 160,
                "name": "Government Secondary School, Jada Local Government.",
                "created_at": "2021-10-06T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1455,
                "country_id": 160,
                "name": "Government Secondary School, Kachia",
                "created_at": "2022-11-22T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1456,
                "country_id": 160,
                "name": "Government Secondary School, Kagoro",
                "created_at": "2022-11-23T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 989,
                "country_id": 160,
                "name": "Government Secondary School, karshi Abuja",
                "created_at": "2021-08-13T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 974,
                "country_id": 160,
                "name": "Government Secondary School, Kuje",
                "created_at": "2021-07-29T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1037,
                "country_id": 160,
                "name": "Government Secondary School, Maiha",
                "created_at": "2021-09-30T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1049,
                "country_id": 160,
                "name": "Government Secondary School, Nto Nsek, Essien Udim",
                "created_at": "2021-10-12T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1130,
                "country_id": 160,
                "name": "Government Secondary School, Odi",
                "created_at": "2022-01-01T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1594,
                "country_id": 160,
                "name": "Government Secondary School, Ogaminana",
                "created_at": "2023-04-10T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1134,
                "country_id": 160,
                "name": "Government Secondary School, Ogoloma,",
                "created_at": "2022-01-05T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1625,
                "country_id": 160,
                "name": "Government secondary school, Omu-Aran",
                "created_at": "2023-05-11T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 988,
                "country_id": 160,
                "name": "Government Secondary School, Wuse Zone3 Abuja.",
                "created_at": "2021-08-12T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1449,
                "country_id": 160,
                "name": "Government Secondary School, Zonkwa",
                "created_at": "2022-11-16T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1039,
                "country_id": 160,
                "name": "Government secondary, Michika",
                "created_at": "2021-10-02T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1040,
                "country_id": 160,
                "name": "Government secondary, Mubi",
                "created_at": "2021-10-03T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1506,
                "country_id": 160,
                "name": "Government Secondry School Dala",
                "created_at": "2023-01-12T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2072,
                "country_id": 160,
                "name": "Government Technical College",
                "created_at": "2022-01-28T02:22:31.000000Z",
                "updated_at": "2022-01-28T02:22:31.000000Z"
            },
            {
                "id": 1554,
                "country_id": 160,
                "name": "Government Technical College (GTC) Kano",
                "created_at": "2023-03-01T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1827,
                "country_id": 160,
                "name": "Government Technical College, Ania",
                "created_at": "2021-12-10T19:23:30.000000Z",
                "updated_at": "2021-12-10T19:23:30.000000Z"
            },
            {
                "id": 1082,
                "country_id": 160,
                "name": "Government Technical College, Onitsha",
                "created_at": "2021-11-14T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1096,
                "country_id": 160,
                "name": "Government Technical College,Umuleri",
                "created_at": "2021-11-28T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1645,
                "country_id": 160,
                "name": "Government Unity Secondary School, Kaiama",
                "created_at": "2023-05-31T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1598,
                "country_id": 160,
                "name": "Governmental Secondary School Iyamoye (GSS-Iyamoye), Iyamoye",
                "created_at": "2023-04-14T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1499,
                "country_id": 160,
                "name": "Govt. Girls Secondary School, DanDinshe.",
                "created_at": "2023-01-05T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1723,
                "country_id": 160,
                "name": "Grace High School, Gbagada",
                "created_at": "2023-08-17T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1724,
                "country_id": 160,
                "name": "Grace International School, Surulere",
                "created_at": "2023-08-18T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1337,
                "country_id": 160,
                "name": "Grammar School Nike, Enugu",
                "created_at": "2022-07-27T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1725,
                "country_id": 160,
                "name": "Grange International School, Lagos",
                "created_at": "2023-08-19T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1457,
                "country_id": 160,
                "name": "Grays International College, Kaduna",
                "created_at": "2022-11-24T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1871,
                "country_id": 160,
                "name": "Great Oxford International Group Of School",
                "created_at": "2021-12-22T21:20:01.000000Z",
                "updated_at": "2021-12-22T21:20:01.000000Z"
            },
            {
                "id": 1991,
                "country_id": 160,
                "name": "Great Trinity College",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1263,
                "country_id": 160,
                "name": "Greater Tomorrow Secondary School, Benin City",
                "created_at": "2022-05-14T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1727,
                "country_id": 160,
                "name": "Green Pastures School, Ejigbo, Lagos",
                "created_at": "2023-08-21T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1729,
                "country_id": 160,
                "name": "Greensprings School , Anthony Village",
                "created_at": "2023-08-23T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1726,
                "country_id": 160,
                "name": "Greenwood House School, Ikoyi",
                "created_at": "2023-08-20T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 154,
                "country_id": 160,
                "name": "Gregory University, Uturu",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1073,
                "country_id": 160,
                "name": "Grundtvig International Secondary school, Oba",
                "created_at": "2021-11-05T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 155,
                "country_id": 160,
                "name": "Hallmark University",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1573,
                "country_id": 160,
                "name": "Harmony International College Kebbi",
                "created_at": "2023-03-20T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1611,
                "country_id": 160,
                "name": "Harmony Secondary School, Lokoja",
                "created_at": "2023-04-27T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1542,
                "country_id": 160,
                "name": "Hassan Ibrahim Gwarzo Secondary School",
                "created_at": "2023-02-17T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1938,
                "country_id": 160,
                "name": "Health Business Support Systems Ltd.",
                "created_at": "2021-12-30T13:59:22.000000Z",
                "updated_at": "2021-12-30T13:59:22.000000Z"
            },
            {
                "id": 1904,
                "country_id": 160,
                "name": "Heritage Polytechnic",
                "created_at": "2021-12-23T18:15:34.000000Z",
                "updated_at": "2021-12-23T18:15:34.000000Z"
            },
            {
                "id": 1151,
                "country_id": 160,
                "name": "Hillcrest High School, Calabar Road, Calabar",
                "created_at": "2022-01-22T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1162,
                "country_id": 160,
                "name": "Hillcrest High School, Ekorinim, Calabar",
                "created_at": "2022-02-02T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1965,
                "country_id": 160,
                "name": "His Majesty International School",
                "created_at": "2022-01-20T02:49:44.000000Z",
                "updated_at": "2022-01-20T02:49:44.000000Z"
            },
            {
                "id": 1302,
                "country_id": 160,
                "name": "Holy Child Catholic School.",
                "created_at": "2022-06-22T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1735,
                "country_id": 160,
                "name": "Holy Child College, Ikoyi",
                "created_at": "2023-08-29T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1155,
                "country_id": 160,
                "name": "Holy Child Girls Secondary School, Calabar",
                "created_at": "2022-01-26T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1088,
                "country_id": 160,
                "name": "Holy Child secondary School,Isuofia",
                "created_at": "2021-11-20T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1111,
                "country_id": 160,
                "name": "Holy Ghost Juniorate, Ihiala",
                "created_at": "2021-12-13T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1240,
                "country_id": 160,
                "name": "Holy Ghost Secondary school, Abakaliki",
                "created_at": "2022-04-21T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1009,
                "country_id": 160,
                "name": "Holy Ghost Secondary Technical School, Umuahia (Fomerly Holy Ghost College)",
                "created_at": "2021-09-02T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1087,
                "country_id": 160,
                "name": "Holy Innocents Juniorate Convent, Nkpor",
                "created_at": "2021-11-19T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1843,
                "country_id": 160,
                "name": "Holy Trinity Grammar School",
                "created_at": "2021-12-22T00:38:39.000000Z",
                "updated_at": "2021-12-22T00:38:39.000000Z"
            },
            {
                "id": 1736,
                "country_id": 160,
                "name": "Homat Comprehensive College, Igbogbo",
                "created_at": "2023-08-30T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1149,
                "country_id": 160,
                "name": "Hope Waddell Training Institution, Calabar, est. 1895",
                "created_at": "2022-01-20T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1730,
                "country_id": 160,
                "name": "Hopebay College, Alaba Oro, Amukoko, Lagos",
                "created_at": "2023-08-24T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1734,
                "country_id": 160,
                "name": "Hopebay College, Ifako-Agege, Lagos",
                "created_at": "2023-08-28T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1732,
                "country_id": 160,
                "name": "Hopebay College, Mazamaza, Lagos",
                "created_at": "2023-08-26T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1731,
                "country_id": 160,
                "name": "Hopebay College, Okokomaiko, Lagos",
                "created_at": "2023-08-25T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1733,
                "country_id": 160,
                "name": "Hopebay College, Okota, Lagos",
                "created_at": "2023-08-27T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1190,
                "country_id": 160,
                "name": "Hussey College Warri",
                "created_at": "2022-03-02T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 979,
                "country_id": 160,
                "name": "I Q Academy",
                "created_at": "2021-08-03T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1861,
                "country_id": 160,
                "name": "Ibadan Grammar School",
                "created_at": "2021-12-22T20:42:59.000000Z",
                "updated_at": "2021-12-22T20:42:59.000000Z"
            },
            {
                "id": 1010,
                "country_id": 160,
                "name": "Ibeku High School, Umuahia, est. 1960",
                "created_at": "2021-09-03T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1281,
                "country_id": 160,
                "name": "Ibillo Boys Grammar School, Ibillo, Akoko-Edo LGA",
                "created_at": "2022-06-01T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 156,
                "country_id": 160,
                "name": "Ibrahim Badamasi Babangida University Lapai, Niger",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1224,
                "country_id": 160,
                "name": "Ibru College, Agbarha-Otor",
                "created_at": "2022-04-05T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1361,
                "country_id": 160,
                "name": "Idaw River Girls College",
                "created_at": "2022-08-20T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1091,
                "country_id": 160,
                "name": "Ideal Minds Comprehensive Secondary School,Nkpor",
                "created_at": "2021-11-23T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1865,
                "country_id": 160,
                "name": "Ideal Nursery and Primary School",
                "created_at": "2021-12-22T21:20:01.000000Z",
                "updated_at": "2021-12-22T21:20:01.000000Z"
            },
            {
                "id": 1221,
                "country_id": 160,
                "name": "Idjerhe Grammar School, Jesse-Town",
                "created_at": "2022-04-02T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1955,
                "country_id": 160,
                "name": "Idodo Secondary School",
                "created_at": "2022-01-20T02:49:44.000000Z",
                "updated_at": "2022-01-20T02:49:44.000000Z"
            },
            {
                "id": 2025,
                "country_id": 160,
                "name": "Idowu Primary School",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1409,
                "country_id": 160,
                "name": "Ifakala Community Secondary School",
                "created_at": "2022-10-07T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1410,
                "country_id": 160,
                "name": "Ifakala Girls Secondary School",
                "created_at": "2022-10-08T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1859,
                "country_id": 160,
                "name": "Ifaki Grammar School",
                "created_at": "2021-12-22T20:42:59.000000Z",
                "updated_at": "2021-12-22T20:42:59.000000Z"
            },
            {
                "id": 1926,
                "country_id": 160,
                "name": "Igando Community High School",
                "created_at": "2021-12-30T13:48:34.000000Z",
                "updated_at": "2021-12-30T13:48:34.000000Z"
            },
            {
                "id": 1259,
                "country_id": 160,
                "name": "Igbinedion Education Centre School, Benin City",
                "created_at": "2022-05-10T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 157,
                "country_id": 160,
                "name": "Igbinedion University, Okada",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1737,
                "country_id": 160,
                "name": "Igbobi College, Yaba, est. 1932",
                "created_at": "2023-08-31T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 158,
                "country_id": 160,
                "name": "Ignatius Ajuru University Of Education",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1307,
                "country_id": 160,
                "name": "Igogo High School",
                "created_at": "2022-06-27T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1290,
                "country_id": 160,
                "name": "Iguobazuwa Grammar School, Iguobazuwa,Ovia LGA",
                "created_at": "2022-06-10T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1085,
                "country_id": 160,
                "name": "Igwebuike Grammar School, Awka",
                "created_at": "2021-11-17T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1385,
                "country_id": 160,
                "name": "Iheme Memoral Grammer School Arondizuogu",
                "created_at": "2022-09-13T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1411,
                "country_id": 160,
                "name": "Ihiagwa Secondary School, Ihiagwa, Owerri, Imo State",
                "created_at": "2022-10-09T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1739,
                "country_id": 160,
                "name": "Ijaiye Ojokoro Comprehensive High School,Ijaiye Ojokoro, Lagos.",
                "created_at": "2023-09-02T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1918,
                "country_id": 160,
                "name": "Ijemo Titun High School",
                "created_at": "2021-12-27T10:10:15.000000Z",
                "updated_at": "2021-12-27T10:10:15.000000Z"
            },
            {
                "id": 1742,
                "country_id": 160,
                "name": "Ijeshatedo Boys Secondary School, Surulere",
                "created_at": "2023-09-05T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1170,
                "country_id": 160,
                "name": "Ika Grammar School, Agbor",
                "created_at": "2022-02-10T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1743,
                "country_id": 160,
                "name": "Ikeja Grammar School, Oshodi",
                "created_at": "2023-09-06T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1834,
                "country_id": 160,
                "name": "Ikeja High school",
                "created_at": "2021-12-21T22:46:53.000000Z",
                "updated_at": "2021-12-21T22:46:53.000000Z"
            },
            {
                "id": 1556,
                "country_id": 160,
                "name": "Ikima Nursery & Primary School Shagari Qtrs. Kano",
                "created_at": "2023-03-03T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2071,
                "country_id": 160,
                "name": "Ikobi Community Secondary School",
                "created_at": "2022-01-28T02:22:31.000000Z",
                "updated_at": "2022-01-28T02:22:31.000000Z"
            },
            {
                "id": 1744,
                "country_id": 160,
                "name": "Ikorodu High School, Ikorodu",
                "created_at": "2023-09-07T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2023,
                "country_id": 160,
                "name": "Ikotun Senior High School",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1274,
                "country_id": 160,
                "name": "Ikpeshi Grammar School, Ikpeshi, Akoko-Edo LGA",
                "created_at": "2022-05-25T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1023,
                "country_id": 160,
                "name": "Ikwuano Secondary School, Ariam.",
                "created_at": "2021-09-16T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1854,
                "country_id": 160,
                "name": "Ila Grammar School, Ila Orangun",
                "created_at": "2021-12-22T20:42:59.000000Z",
                "updated_at": "2021-12-22T20:42:59.000000Z"
            },
            {
                "id": 1326,
                "country_id": 160,
                "name": "Ilawe Grammar School, Ilawe Ekiti",
                "created_at": "2022-07-16T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1639,
                "country_id": 160,
                "name": "Ilorin College Ilorin",
                "created_at": "2023-05-25T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2014,
                "country_id": 160,
                "name": "Ilupeju College",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1738,
                "country_id": 160,
                "name": "Ilupeju Grammer School, Ilupeju",
                "created_at": "2023-09-01T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1831,
                "country_id": 160,
                "name": "Ilushin Grammar School, Ijebu Water Side",
                "created_at": "2021-12-21T22:46:53.000000Z",
                "updated_at": "2021-12-21T22:46:53.000000Z"
            },
            {
                "id": 1509,
                "country_id": 160,
                "name": "Imam Zubair Islamiyya School",
                "created_at": "2023-01-15T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1260,
                "country_id": 160,
                "name": "Immaculate Conception College, Benin City",
                "created_at": "2022-05-11T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1119,
                "country_id": 160,
                "name": "Immaculate Conception Secondary School, Bauchi",
                "created_at": "2021-12-21T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1770,
                "country_id": 160,
                "name": "Immaculate Heart Comprehensive Senior High School",
                "created_at": "2021-12-02T19:25:12.000000Z",
                "updated_at": "2021-12-02T19:25:12.000000Z"
            },
            {
                "id": 1000,
                "country_id": 160,
                "name": "Immaculate Heart, Aba",
                "created_at": "2021-08-24T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2054,
                "country_id": 160,
                "name": "Imo State Polytechnic",
                "created_at": "2022-01-26T04:29:25.000000Z",
                "updated_at": "2022-01-26T04:29:25.000000Z"
            },
            {
                "id": 159,
                "country_id": 160,
                "name": "Imo State University",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1740,
                "country_id": 160,
                "name": "Imohimi comprehensive secretarial collegge, amukoko lagos.",
                "created_at": "2023-09-03T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2048,
                "country_id": 160,
                "name": "Independence Group Of School",
                "created_at": "2022-01-26T04:29:25.000000Z",
                "updated_at": "2022-01-26T04:29:25.000000Z"
            },
            {
                "id": 1084,
                "country_id": 160,
                "name": "Inland Girls Secondary School,Onitsha",
                "created_at": "2021-11-16T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1907,
                "country_id": 160,
                "name": "Innocent Children International Nursery and Primary School",
                "created_at": "2021-12-23T18:15:34.000000Z",
                "updated_at": "2021-12-23T18:15:34.000000Z"
            },
            {
                "id": 1760,
                "country_id": 160,
                "name": "Innocent Comprehensive High School, Ijesha, Lagos",
                "created_at": "2023-09-23T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1937,
                "country_id": 160,
                "name": "Institute for Healthcare Finance & Management",
                "created_at": "2021-12-30T13:59:22.000000Z",
                "updated_at": "2021-12-30T13:59:22.000000Z"
            },
            {
                "id": 160,
                "country_id": 160,
                "name": "institute for peace and strategic studies (IPSS)",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1374,
                "country_id": 160,
                "name": "Institute of Qur'anic Memorization and Islamic Civilisation Gombe",
                "created_at": "2022-09-02T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1512,
                "country_id": 160,
                "name": "Intercontinental college",
                "created_at": "2023-01-18T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 978,
                "country_id": 160,
                "name": "International Community School, Abuja",
                "created_at": "2021-08-02T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 161,
                "country_id": 160,
                "name": "International Institute of Tropical Agriculture",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1759,
                "country_id": 160,
                "name": "International School University of Lagos, UNILAG, Akoka, Yaba",
                "created_at": "2023-09-22T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1011,
                "country_id": 160,
                "name": "International Secondary School, Abia State University, Uturu",
                "created_at": "2021-09-04T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1110,
                "country_id": 160,
                "name": "International Secondary School, ATBU",
                "created_at": "2021-12-12T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1722,
                "country_id": 160,
                "name": "Iponri Estate High School, Surulere",
                "created_at": "2023-08-16T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1308,
                "country_id": 160,
                "name": "Ipoti High School",
                "created_at": "2022-06-28T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1876,
                "country_id": 160,
                "name": "Ire Akari Primary School",
                "created_at": "2021-12-22T22:03:57.000000Z",
                "updated_at": "2021-12-22T22:03:57.000000Z"
            },
            {
                "id": 1875,
                "country_id": 160,
                "name": "Ire Akari Secondary School",
                "created_at": "2021-12-22T22:03:57.000000Z",
                "updated_at": "2021-12-22T22:03:57.000000Z"
            },
            {
                "id": 1317,
                "country_id": 160,
                "name": "Irepodun High School Ikere",
                "created_at": "2022-07-07T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1776,
                "country_id": 160,
                "name": "Iresaadu Primary School, Ile-Odu",
                "created_at": "2021-12-03T21:35:16.000000Z",
                "updated_at": "2021-12-03T21:35:16.000000Z"
            },
            {
                "id": 2003,
                "country_id": 160,
                "name": "Isashi Grammar School",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 2045,
                "country_id": 160,
                "name": "Isawo Comprehensive Senior High School",
                "created_at": "2022-01-26T04:29:25.000000Z",
                "updated_at": "2022-01-26T04:29:25.000000Z"
            },
            {
                "id": 1911,
                "country_id": 160,
                "name": "ISCOM University",
                "created_at": "2021-12-27T10:10:15.000000Z",
                "updated_at": "2021-12-27T10:10:15.000000Z"
            },
            {
                "id": 1423,
                "country_id": 160,
                "name": "Isunjaba High School",
                "created_at": "2022-10-21T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1745,
                "country_id": 160,
                "name": "Itire Community Secondary School,Itire,Lagos",
                "created_at": "2023-09-08T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1906,
                "country_id": 160,
                "name": "Itolo Girls Secondary School",
                "created_at": "2021-12-23T18:15:34.000000Z",
                "updated_at": "2021-12-23T18:15:34.000000Z"
            },
            {
                "id": 1360,
                "country_id": 160,
                "name": "Iva Valley Secondary School",
                "created_at": "2022-08-19T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1782,
                "country_id": 160,
                "name": "Ivbiyeneva Primary School",
                "created_at": "2021-12-03T21:40:52.000000Z",
                "updated_at": "2021-12-03T21:40:52.000000Z"
            },
            {
                "id": 2021,
                "country_id": 160,
                "name": "Iverok College",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1641,
                "country_id": 160,
                "name": "Iwo Comprehensive High School, Iwo-Isin",
                "created_at": "2023-05-27T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1350,
                "country_id": 160,
                "name": "Iwollo High School",
                "created_at": "2022-08-09T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1276,
                "country_id": 160,
                "name": "Iyekhei Girls' Secondary School, Auchi",
                "created_at": "2022-05-27T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1815,
                "country_id": 160,
                "name": "Iyiashili Primary School Utagba Ogbe",
                "created_at": "2021-12-10T18:46:02.000000Z",
                "updated_at": "2021-12-10T18:46:02.000000Z"
            },
            {
                "id": 1458,
                "country_id": 160,
                "name": "J. M. A Premier Secondary School, Kaduna",
                "created_at": "2022-11-25T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1608,
                "country_id": 160,
                "name": "Jama Atu Nasril Islam Comprehensive High School, Ife-Olukotun",
                "created_at": "2023-04-24T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1214,
                "country_id": 160,
                "name": "James Welch Grammar School, Emevor",
                "created_at": "2022-03-26T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1771,
                "country_id": 160,
                "name": "Janet Memorial School",
                "created_at": "2021-12-03T21:30:31.000000Z",
                "updated_at": "2021-12-03T21:30:31.000000Z"
            },
            {
                "id": 1210,
                "country_id": 160,
                "name": "Jeremi College, Otu-Jeremi",
                "created_at": "2022-03-22T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2002,
                "country_id": 160,
                "name": "Jesus Own Foundation",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1366,
                "country_id": 160,
                "name": "JIBWIS Gombe",
                "created_at": "2022-08-25T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1572,
                "country_id": 160,
                "name": "Joda college birnin kebbi",
                "created_at": "2023-03-19T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 162,
                "country_id": 160,
                "name": "Joseph Ayo Babalola University Ikeji Arakeji",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 2079,
                "country_id": 160,
                "name": "Jossy Nursery And Primary School",
                "created_at": "2022-01-28T02:22:31.000000Z",
                "updated_at": "2022-01-28T02:22:31.000000Z"
            },
            {
                "id": 1872,
                "country_id": 160,
                "name": "Joy International School",
                "created_at": "2021-12-22T21:20:01.000000Z",
                "updated_at": "2021-12-22T21:20:01.000000Z"
            },
            {
                "id": 1798,
                "country_id": 160,
                "name": "Junifpride International Private School",
                "created_at": "2021-12-06T13:00:02.000000Z",
                "updated_at": "2021-12-06T13:00:02.000000Z"
            },
            {
                "id": 1459,
                "country_id": 160,
                "name": "Kaduna Capital School, Kaduna",
                "created_at": "2022-11-26T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 163,
                "country_id": 160,
                "name": "Kaduna polytechnic",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1460,
                "country_id": 160,
                "name": "Kaduna Polytechnic Staff School",
                "created_at": "2022-11-27T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 164,
                "country_id": 160,
                "name": "kaduna State University",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1465,
                "country_id": 160,
                "name": "Kaduna Teachers' College, Kaduna",
                "created_at": "2022-12-02T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1132,
                "country_id": 160,
                "name": "Kaiama Grammer School, Kaiama",
                "created_at": "2022-01-03T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1539,
                "country_id": 160,
                "name": "Kano Capital",
                "created_at": "2023-02-14T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1523,
                "country_id": 160,
                "name": "Kano Model Secondary",
                "created_at": "2023-01-29T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 165,
                "country_id": 160,
                "name": "Kano University of Technology, Wudil",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1580,
                "country_id": 160,
                "name": "Kanta College Argungu",
                "created_at": "2023-03-27T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 166,
                "country_id": 160,
                "name": "Kebbi State University of Science and Technology, Aliero",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1912,
                "country_id": 160,
                "name": "Kefe Academy",
                "created_at": "2021-12-27T10:10:15.000000Z",
                "updated_at": "2021-12-27T10:10:15.000000Z"
            },
            {
                "id": 1746,
                "country_id": 160,
                "name": "Keke Senior High School",
                "created_at": "2023-09-09T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2013,
                "country_id": 160,
                "name": "Kemeesther International College",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 2020,
                "country_id": 160,
                "name": "Ken Saro-Wiwa Polytechnic",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1747,
                "country_id": 160,
                "name": "King Ado High School, Lagos Island",
                "created_at": "2023-09-10T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1761,
                "country_id": 160,
                "name": "King's College, Lagos, est. 1909",
                "created_at": "2023-09-24T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1762,
                "country_id": 160,
                "name": "King's High School, Satellite Town, Lagos",
                "created_at": "2023-09-25T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1516,
                "country_id": 160,
                "name": "Kings College Kano",
                "created_at": "2023-01-22T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1763,
                "country_id": 160,
                "name": "Kings' Foundation Nursery and Primary School, Old Ojo Road, Kuje, Lagos",
                "created_at": "2023-09-26T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1630,
                "country_id": 160,
                "name": "Kinsey Academy College, Fate road Ilorin",
                "created_at": "2023-05-16T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 167,
                "country_id": 160,
                "name": "Kogi State Polytechnic",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 168,
                "country_id": 160,
                "name": "Kogi State University, Anyigba",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 981,
                "country_id": 160,
                "name": "Kuje Science Primary School",
                "created_at": "2021-08-05T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 169,
                "country_id": 160,
                "name": "Kwara State College of Education",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 170,
                "country_id": 160,
                "name": "Kwara State Polytechnic",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 171,
                "country_id": 160,
                "name": "Kwara State University, Malete Ilorin",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 172,
                "country_id": 160,
                "name": "kwararafa University Wukari",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1959,
                "country_id": 160,
                "name": "LA Primary School",
                "created_at": "2022-01-20T02:49:44.000000Z",
                "updated_at": "2022-01-20T02:49:44.000000Z"
            },
            {
                "id": 173,
                "country_id": 160,
                "name": "Ladoke Akintola University of Technology",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1764,
                "country_id": 160,
                "name": "Lagoon Secondary School, Lekki",
                "created_at": "2023-09-27T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1862,
                "country_id": 160,
                "name": "Lagooz School",
                "created_at": "2021-12-22T20:42:59.000000Z",
                "updated_at": "2021-12-22T20:42:59.000000Z"
            },
            {
                "id": 174,
                "country_id": 160,
                "name": "Lagos City Computer College",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 175,
                "country_id": 160,
                "name": "Lagos City Polytechnic",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1748,
                "country_id": 160,
                "name": "Lagos progressive secondary school Surulere",
                "created_at": "2023-09-11T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1749,
                "country_id": 160,
                "name": "Lagos State Civil Service Model College, Igbogbo, Ikorodu",
                "created_at": "2023-09-12T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2041,
                "country_id": 160,
                "name": "Lagos State College of Health Technology",
                "created_at": "2022-01-26T04:02:10.000000Z",
                "updated_at": "2022-01-26T04:02:10.000000Z"
            },
            {
                "id": 1754,
                "country_id": 160,
                "name": "Lagos State Model College, Badore",
                "created_at": "2023-09-17T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1750,
                "country_id": 160,
                "name": "Lagos State Model College, Igbo Nla",
                "created_at": "2023-09-13T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1751,
                "country_id": 160,
                "name": "Lagos State Model College, Igbokuta",
                "created_at": "2023-09-14T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1752,
                "country_id": 160,
                "name": "Lagos State Model College, Kankon",
                "created_at": "2023-09-15T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1753,
                "country_id": 160,
                "name": "Lagos State Model College, Meiran",
                "created_at": "2023-09-16T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 176,
                "country_id": 160,
                "name": "Lagos State polytechnic",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1755,
                "country_id": 160,
                "name": "Lagos State Secondary Commercial Academy LASCA, Ilupeju",
                "created_at": "2023-09-18T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 177,
                "country_id": 160,
                "name": "Lagos State University",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 178,
                "country_id": 160,
                "name": "lagos University Teaching Hospital",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1165,
                "country_id": 160,
                "name": "Lakeland School, Ogunu, Warri",
                "created_at": "2022-02-05T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2027,
                "country_id": 160,
                "name": "Lamb Academy",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 179,
                "country_id": 160,
                "name": "Landmark University",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 2012,
                "country_id": 160,
                "name": "Lato High School",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1758,
                "country_id": 160,
                "name": "laurel Secondary school, Jakande estate,Isolo",
                "created_at": "2023-09-21T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 180,
                "country_id": 160,
                "name": "LAWNA THEOLOGICAL SEMINARY",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1864,
                "country_id": 160,
                "name": "Layi Oyekanmi Primary School",
                "created_at": "2021-12-22T20:42:59.000000Z",
                "updated_at": "2021-12-22T20:42:59.000000Z"
            },
            {
                "id": 1884,
                "country_id": 160,
                "name": "Lead City Polytechnic",
                "created_at": "2021-12-23T12:05:03.000000Z",
                "updated_at": "2021-12-23T12:05:03.000000Z"
            },
            {
                "id": 181,
                "country_id": 160,
                "name": "Lead City University, Ibadan",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1807,
                "country_id": 160,
                "name": "Leads college",
                "created_at": "2021-12-06T13:22:08.000000Z",
                "updated_at": "2021-12-06T13:22:08.000000Z"
            },
            {
                "id": 1494,
                "country_id": 160,
                "name": "Lebanon College",
                "created_at": "2022-12-31T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1756,
                "country_id": 160,
                "name": "Lekki British International High School, Lekki Phase One",
                "created_at": "2023-09-19T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1981,
                "country_id": 160,
                "name": "Liberty College",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 2073,
                "country_id": 160,
                "name": "Lighthouse Polytechnic",
                "created_at": "2022-01-28T02:22:31.000000Z",
                "updated_at": "2022-01-28T02:22:31.000000Z"
            },
            {
                "id": 1928,
                "country_id": 160,
                "name": "Lilian Baylis Technology School",
                "created_at": "2021-12-30T13:48:34.000000Z",
                "updated_at": "2021-12-30T13:48:34.000000Z"
            },
            {
                "id": 1980,
                "country_id": 160,
                "name": "LilyFields Comprehensive College",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1808,
                "country_id": 160,
                "name": "Living Spring Comprehensive College",
                "created_at": "2021-12-06T13:22:08.000000Z",
                "updated_at": "2021-12-06T13:22:08.000000Z"
            },
            {
                "id": 1003,
                "country_id": 160,
                "name": "LIVING WORD ACADEMY SECONDARY SCHOOL ABA.",
                "created_at": "2021-08-27T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1002,
                "country_id": 160,
                "name": "LIVING WORD MAGNET SCHOOL ABA",
                "created_at": "2021-08-26T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2008,
                "country_id": 160,
                "name": "LivingStone Model College",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1773,
                "country_id": 160,
                "name": "Local Government Primary School, Ijagba",
                "created_at": "2021-12-03T21:30:31.000000Z",
                "updated_at": "2021-12-03T21:30:31.000000Z"
            },
            {
                "id": 1757,
                "country_id": 160,
                "name": "Loral International Secondary Schools, Festac Town, Lagos",
                "created_at": "2023-09-20T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 983,
                "country_id": 160,
                "name": "Louisville Girls High School, Abuja",
                "created_at": "2021-08-07T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 982,
                "country_id": 160,
                "name": "Loyola Jesuit College",
                "created_at": "2021-08-06T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1546,
                "country_id": 160,
                "name": "Lufaloy, Kano",
                "created_at": "2023-02-21T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1272,
                "country_id": 160,
                "name": "Lumen Christi Secondary School, Uromi",
                "created_at": "2022-05-23T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1050,
                "country_id": 160,
                "name": "Lutheran High School, Obot Idim, Ibesikpo-Asutan",
                "created_at": "2021-10-13T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1097,
                "country_id": 160,
                "name": "Madonna Secondary School,Umuleri",
                "created_at": "2021-11-29T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 182,
                "country_id": 160,
                "name": "Madonna University, Nigeria",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1513,
                "country_id": 160,
                "name": "Maitama Sule Girls Secondary School",
                "created_at": "2023-01-19T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2010,
                "country_id": 160,
                "name": "Makarios Nursery & Primary School",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1016,
                "country_id": 160,
                "name": "Marist Brothers Juniorate, Uturu",
                "created_at": "2021-09-09T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1017,
                "country_id": 160,
                "name": "Marist Comprehensive Academy, Uturu",
                "created_at": "2021-09-10T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1171,
                "country_id": 160,
                "name": "Mary Mount College, Agbor",
                "created_at": "2022-02-11T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1330,
                "country_id": 160,
                "name": "Maryand Secondary School, Enugu",
                "created_at": "2022-07-20T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1766,
                "country_id": 160,
                "name": "Maryland Comprehensive Secondary School, Ikeja",
                "created_at": "2023-09-29T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1765,
                "country_id": 160,
                "name": "Marywood grammar school Ebute Metta West Lagos",
                "created_at": "2023-09-28T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1847,
                "country_id": 160,
                "name": "Mate Nursery / Primary School",
                "created_at": "2021-12-22T01:45:13.000000Z",
                "updated_at": "2021-12-22T01:45:13.000000Z"
            },
            {
                "id": 1521,
                "country_id": 160,
                "name": "Mautsidau Secondary School, Makoda",
                "created_at": "2023-01-27T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1767,
                "country_id": 160,
                "name": "May Day School Primary school",
                "created_at": "2023-09-30T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1940,
                "country_id": 160,
                "name": "Mayflower School",
                "created_at": "2021-12-30T13:59:22.000000Z",
                "updated_at": "2021-12-30T13:59:22.000000Z"
            },
            {
                "id": 183,
                "country_id": 160,
                "name": "McPherson University, Seriki Sotayo",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 184,
                "country_id": 160,
                "name": "Medical Laboratory Science Council of Nigeria",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1932,
                "country_id": 160,
                "name": "Medilag Consult",
                "created_at": "2021-12-30T13:59:22.000000Z",
                "updated_at": "2021-12-30T13:59:22.000000Z"
            },
            {
                "id": 1910,
                "country_id": 160,
                "name": "Mercy Model College",
                "created_at": "2021-12-27T10:10:15.000000Z",
                "updated_at": "2021-12-27T10:10:15.000000Z"
            },
            {
                "id": 1177,
                "country_id": 160,
                "name": "Merit Mixed Secondary School, Sapele",
                "created_at": "2022-02-17T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1007,
                "country_id": 160,
                "name": "Methodist College Uzuakoli 1923",
                "created_at": "2021-08-31T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2070,
                "country_id": 160,
                "name": "Methodist Primary School",
                "created_at": "2022-01-28T02:22:31.000000Z",
                "updated_at": "2022-01-28T02:22:31.000000Z"
            },
            {
                "id": 1401,
                "country_id": 160,
                "name": "Mgbidi Boys Secondary School,Mgbidi",
                "created_at": "2022-09-29T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 185,
                "country_id": 160,
                "name": "Michael and Cecilia Ibru University",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 186,
                "country_id": 160,
                "name": "Michael Okpara University of Agriculture Umudike",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1803,
                "country_id": 160,
                "name": "Midtown College Diobu, Port-Harcourt",
                "created_at": "2021-12-06T13:00:02.000000Z",
                "updated_at": "2021-12-06T13:00:02.000000Z"
            },
            {
                "id": 1924,
                "country_id": 160,
                "name": "Mighty Oaks",
                "created_at": "2021-12-30T13:39:57.000000Z",
                "updated_at": "2021-12-30T13:39:57.000000Z"
            },
            {
                "id": 1467,
                "country_id": 160,
                "name": "Military School, Zaria",
                "created_at": "2022-12-04T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2029,
                "country_id": 160,
                "name": "Missionary Academy",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1995,
                "country_id": 160,
                "name": "MIT Nursery & Primary School",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1304,
                "country_id": 160,
                "name": "Moba Grammar School, Otun Ekiti",
                "created_at": "2022-06-24T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1524,
                "country_id": 160,
                "name": "Model Comprehensive School",
                "created_at": "2023-01-30T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1468,
                "country_id": 160,
                "name": "Model International School, Kaduna",
                "created_at": "2022-12-05T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1927,
                "country_id": 160,
                "name": "Model Primary School",
                "created_at": "2021-12-30T13:48:34.000000Z",
                "updated_at": "2021-12-30T13:48:34.000000Z"
            },
            {
                "id": 984,
                "country_id": 160,
                "name": "Model Secondary School, Maitama",
                "created_at": "2021-08-08T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2000,
                "country_id": 160,
                "name": "Most High Schools",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1638,
                "country_id": 160,
                "name": "Mount Carmel college, Ilorin",
                "created_at": "2023-05-24T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1140,
                "country_id": 160,
                "name": "Mount St. Gabriel Secondary school, Makurdi",
                "created_at": "2022-01-11T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 187,
                "country_id": 160,
                "name": "Mountain Top University",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 188,
                "country_id": 160,
                "name": "Muritada College of Education, Ibadan",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1026,
                "country_id": 160,
                "name": "Musa Bello Shelleng memorial college.",
                "created_at": "2021-09-19T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1495,
                "country_id": 160,
                "name": "Musa Iliasu college",
                "created_at": "2023-01-01T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1960,
                "country_id": 160,
                "name": "Mushin Boys High School",
                "created_at": "2022-01-20T02:49:44.000000Z",
                "updated_at": "2022-01-20T02:49:44.000000Z"
            },
            {
                "id": 1637,
                "country_id": 160,
                "name": "Muyideen Arabic Secondary School, Kulende, Ilorin",
                "created_at": "2023-05-23T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1141,
                "country_id": 160,
                "name": "N.K.S.T (W.M. Bristow Secondary School), Gboko",
                "created_at": "2022-01-12T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1341,
                "country_id": 160,
                "name": "Nachi Girls Secondary School.",
                "created_at": "2022-07-31T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1574,
                "country_id": 160,
                "name": "Nagari college Birnin Kebbi",
                "created_at": "2023-03-21T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1201,
                "country_id": 160,
                "name": "Nana College, Warri",
                "created_at": "2022-03-13T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1163,
                "country_id": 160,
                "name": "Nana Primary School, Warri Delta State Nigeria",
                "created_at": "2022-02-03T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1362,
                "country_id": 160,
                "name": "Nara Girls College",
                "created_at": "2022-08-21T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 189,
                "country_id": 160,
                "name": "Nasarawa State University, Keffi",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 190,
                "country_id": 160,
                "name": "National College, Abuja",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 1233,
                "country_id": 160,
                "name": "National Comprehensive College Warri",
                "created_at": "2022-04-14T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1384,
                "country_id": 160,
                "name": "National High School Arondizuogu",
                "created_at": "2022-09-12T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 191,
                "country_id": 160,
                "name": "National Horticultural Research Institute Ibadan",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 192,
                "country_id": 160,
                "name": "National Open University",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 193,
                "country_id": 160,
                "name": "National Postgraduate Medical",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 194,
                "country_id": 160,
                "name": "National Postgraduate Medical College",
                "created_at": "2021-07-19T09:51:02.000000Z",
                "updated_at": "2021-07-19T09:51:02.000000Z"
            },
            {
                "id": 2097,
                "country_id": 160,
                "name": "National Water Resources Institute",
                "created_at": "2022-06-24T02:26:39.000000Z",
                "updated_at": "2022-06-24T02:26:40.000000Z"
            },
            {
                "id": 1791,
                "country_id": 160,
                "name": "Nawair-Ud-Deen Model College",
                "created_at": "2021-12-05T14:24:50.000000Z",
                "updated_at": "2021-12-05T14:24:50.000000Z"
            },
            {
                "id": 1825,
                "country_id": 160,
                "name": "Nawair-ud-deen Nur/Pry School",
                "created_at": "2021-12-10T19:23:30.000000Z",
                "updated_at": "2021-12-10T19:23:30.000000Z"
            },
            {
                "id": 1978,
                "country_id": 160,
                "name": "Nawar-ud-Deen Senior Secondary School",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1412,
                "country_id": 160,
                "name": "Nekede Secondary School, Nekede Owerri",
                "created_at": "2022-10-10T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1128,
                "country_id": 160,
                "name": "Nembe National Grammar School, Nembe",
                "created_at": "2021-12-30T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1077,
                "country_id": 160,
                "name": "New Era Girls' Secondary School, Onitsha",
                "created_at": "2021-11-09T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1968,
                "country_id": 160,
                "name": "Newbreed Secondary School",
                "created_at": "2022-01-20T02:49:44.000000Z",
                "updated_at": "2022-01-20T02:49:44.000000Z"
            },
            {
                "id": 996,
                "country_id": 160,
                "name": "Ngwa High School(Nahisco), Aba.",
                "created_at": "2021-08-20T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1345,
                "country_id": 160,
                "name": "Ngwo Girls College Ngwo",
                "created_at": "2022-08-04T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1781,
                "country_id": 160,
                "name": "Niger College",
                "created_at": "2021-12-03T21:40:52.000000Z",
                "updated_at": "2021-12-03T21:40:52.000000Z"
            },
            {
                "id": 195,
                "country_id": 160,
                "name": "Niger Delta University, Wilberforce Island, Bayelsa State",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 196,
                "country_id": 160,
                "name": "Nigeria Institute of Medical Research, yaba lagos",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1819,
                "country_id": 160,
                "name": "Nigeria Opportunities Industrialization Centre",
                "created_at": "2021-12-10T18:46:02.000000Z",
                "updated_at": "2021-12-10T18:46:02.000000Z"
            },
            {
                "id": 197,
                "country_id": 160,
                "name": "Nigeria Police Academy",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 198,
                "country_id": 160,
                "name": "Nigerian Airforce",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 199,
                "country_id": 160,
                "name": "Nigerian Army",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 2060,
                "country_id": 160,
                "name": "Nigerian Army College of Environmental Science and Technology",
                "created_at": "2022-01-28T02:22:31.000000Z",
                "updated_at": "2022-01-28T02:22:31.000000Z"
            },
            {
                "id": 200,
                "country_id": 160,
                "name": "Nigerian Army School of Medical Sciences",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 201,
                "country_id": 160,
                "name": "Nigerian Army Signals",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 202,
                "country_id": 160,
                "name": "Nigerian Defence Academy",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 203,
                "country_id": 160,
                "name": "Nigerian Defence Academy, Kaduna",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 204,
                "country_id": 160,
                "name": "NIGERIAN INSTITUTE OF ADVANCED LEGAL STUDIES",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 205,
                "country_id": 160,
                "name": "Nigerian Institute of Journalism",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1939,
                "country_id": 160,
                "name": "Nigerian Institute of Management",
                "created_at": "2021-12-30T13:59:22.000000Z",
                "updated_at": "2021-12-30T13:59:22.000000Z"
            },
            {
                "id": 206,
                "country_id": 160,
                "name": "NIGERIAN INSTITUTE OF SCIENCE LABORATORY TECHNOLOGY",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 207,
                "country_id": 160,
                "name": "Nigerian Navy",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1531,
                "country_id": 160,
                "name": "Nigerian Turkish College Kano",
                "created_at": "2023-02-06T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 990,
                "country_id": 160,
                "name": "Nigerian Turkish International college, Abuja",
                "created_at": "2021-08-14T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1469,
                "country_id": 160,
                "name": "Nigerian Turkish International College, Kaduna",
                "created_at": "2022-12-06T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 208,
                "country_id": 160,
                "name": "Nile University of Nigeria",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1012,
                "country_id": 160,
                "name": "Ninlan Demonstration Secondary School, Aba",
                "created_at": "2021-09-05T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1426,
                "country_id": 160,
                "name": "Nkwere Boys College (Boysco)",
                "created_at": "2022-10-24T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 209,
                "country_id": 160,
                "name": "Nnamdi Azikiwe University",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1075,
                "country_id": 160,
                "name": "Nneamaka Girls Secondary School, Ifitedun",
                "created_at": "2021-11-07T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1102,
                "country_id": 160,
                "name": "Nnobi Girls Secorndry School Nnobi",
                "created_at": "2021-12-04T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1265,
                "country_id": 160,
                "name": "Nosakhare Model Educational Centre, Benin City",
                "created_at": "2022-05-16T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1309,
                "country_id": 160,
                "name": "Notre Dame Grammer School Usi Ekiti",
                "created_at": "2022-06-29T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2047,
                "country_id": 160,
                "name": "Notre Dame Group Of Schools",
                "created_at": "2022-01-26T04:29:25.000000Z",
                "updated_at": "2022-01-26T04:29:25.000000Z"
            },
            {
                "id": 1225,
                "country_id": 160,
                "name": "Novena College, kwale",
                "created_at": "2022-04-06T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1352,
                "country_id": 160,
                "name": "Nsude High School Nsude",
                "created_at": "2022-08-11T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1964,
                "country_id": 160,
                "name": "Nursery and Primary School",
                "created_at": "2022-01-20T02:49:44.000000Z",
                "updated_at": "2022-01-20T02:49:44.000000Z"
            },
            {
                "id": 210,
                "country_id": 160,
                "name": "Nursing and Midwifery Council of Nigeria",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 211,
                "country_id": 160,
                "name": "Nutritional Science",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 212,
                "country_id": 160,
                "name": "Nwafor Orizu College of Education",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1958,
                "country_id": 160,
                "name": "Oba Laoye Grammar School",
                "created_at": "2022-01-20T02:49:44.000000Z",
                "updated_at": "2022-01-20T02:49:44.000000Z"
            },
            {
                "id": 1785,
                "country_id": 160,
                "name": "Obaahoho Comprehensive High School, Epinmi Akoko",
                "created_at": "2021-12-03T21:45:47.000000Z",
                "updated_at": "2021-12-03T21:45:47.000000Z"
            },
            {
                "id": 213,
                "country_id": 160,
                "name": "Obafemi Awolowo University",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 214,
                "country_id": 160,
                "name": "Obafemi Awolowo University Teaching Hospital Complex",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1363,
                "country_id": 160,
                "name": "Obe Girls Secondary School",
                "created_at": "2022-08-22T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 215,
                "country_id": 160,
                "name": "Obong University",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 2038,
                "country_id": 160,
                "name": "Odi-Olowo Nursery & Primary School",
                "created_at": "2022-01-26T04:02:10.000000Z",
                "updated_at": "2022-01-26T04:02:10.000000Z"
            },
            {
                "id": 1818,
                "country_id": 160,
                "name": "Odion Primary School Emevor",
                "created_at": "2021-12-10T18:46:02.000000Z",
                "updated_at": "2021-12-10T18:46:02.000000Z"
            },
            {
                "id": 2064,
                "country_id": 160,
                "name": "Oduduwa Primary School",
                "created_at": "2022-01-28T02:22:31.000000Z",
                "updated_at": "2022-01-28T02:22:31.000000Z"
            },
            {
                "id": 216,
                "country_id": 160,
                "name": "Oduduwa University, Ipetumodu, ile-ife.",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1199,
                "country_id": 160,
                "name": "Ofagbe Technical College, Ofagbe",
                "created_at": "2022-03-11T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1619,
                "country_id": 160,
                "name": "Offa Grammar School Offa, est. 1943",
                "created_at": "2023-05-05T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1899,
                "country_id": 160,
                "name": "Offham Primary School",
                "created_at": "2021-12-23T17:51:53.000000Z",
                "updated_at": "2021-12-23T17:51:53.000000Z"
            },
            {
                "id": 1226,
                "country_id": 160,
                "name": "Ogbavrni Grammar School, Usieffurun",
                "created_at": "2022-04-07T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1217,
                "country_id": 160,
                "name": "Ogbe Secondary School, Effurun",
                "created_at": "2022-03-29T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1059,
                "country_id": 160,
                "name": "Ogidi Boys Secondary School, Ogidi.",
                "created_at": "2021-10-22T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1060,
                "country_id": 160,
                "name": "Ogidi Girls Secondary School, Ogidi.",
                "created_at": "2021-10-23T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1194,
                "country_id": 160,
                "name": "Oginibo Secondary School, Oginibo",
                "created_at": "2022-03-06T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 217,
                "country_id": 160,
                "name": "Ogun State College of Education, Ijebu Ode",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 218,
                "country_id": 160,
                "name": "Ogun State College of Health Technology, Ilese-Ijebu",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 219,
                "country_id": 160,
                "name": "Ogun State Institute of Technology, Igbesa",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1936,
                "country_id": 160,
                "name": "Ogun State School Of Nursing And Midwifery",
                "created_at": "2021-12-30T13:59:22.000000Z",
                "updated_at": "2021-12-30T13:59:22.000000Z"
            },
            {
                "id": 2011,
                "country_id": 160,
                "name": "Ogunlade College",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1025,
                "country_id": 160,
                "name": "Ohafia Girls Secondary School, Ohafia",
                "created_at": "2021-09-18T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1015,
                "country_id": 160,
                "name": "Ohafia High School, Ohafia",
                "created_at": "2021-09-08T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1280,
                "country_id": 160,
                "name": "Ojah Primary School, Ibillo, Akoko-Edo LGA",
                "created_at": "2022-05-31T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1609,
                "country_id": 160,
                "name": "Oke-Oyi Secondary School, Ponyan",
                "created_at": "2023-04-25T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1301,
                "country_id": 160,
                "name": "Okemesi Grammar School, Okemesi-Ekiti",
                "created_at": "2022-06-21T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1984,
                "country_id": 160,
                "name": "Okesuna Senior High School",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1198,
                "country_id": 160,
                "name": "Oketa Mixed Grammar School, Ofagbe",
                "created_at": "2022-03-10T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1966,
                "country_id": 160,
                "name": "Okhuesan Secondary School",
                "created_at": "2022-01-20T02:49:44.000000Z",
                "updated_at": "2022-01-20T02:49:44.000000Z"
            },
            {
                "id": 1164,
                "country_id": 160,
                "name": "Okiti mixed Secondary School, Ubulu Okiti",
                "created_at": "2022-02-04T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1415,
                "country_id": 160,
                "name": "Okporo Technical College (OTC) Okporo Orlu Imo State",
                "created_at": "2022-10-13T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2042,
                "country_id": 160,
                "name": "Ola Oluwa Group of Schools",
                "created_at": "2022-01-26T04:02:10.000000Z",
                "updated_at": "2022-01-26T04:02:10.000000Z"
            },
            {
                "id": 220,
                "country_id": 160,
                "name": "Olabisi Onabanjo University",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1795,
                "country_id": 160,
                "name": "Olamaboro Community Grammar School, Okpo",
                "created_at": "2021-12-06T13:00:02.000000Z",
                "updated_at": "2021-12-06T13:00:02.000000Z"
            },
            {
                "id": 2035,
                "country_id": 160,
                "name": "Oloye Private School",
                "created_at": "2022-01-26T04:02:10.000000Z",
                "updated_at": "2022-01-26T04:02:10.000000Z"
            },
            {
                "id": 985,
                "country_id": 160,
                "name": "Olumawu Basic Education School",
                "created_at": "2021-08-09T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1622,
                "country_id": 160,
                "name": "Omupo Grammar School, Omupo",
                "created_at": "2023-05-08T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1946,
                "country_id": 160,
                "name": "Ondo Anglican Grammar School, Ondo",
                "created_at": "2021-12-30T13:59:22.000000Z",
                "updated_at": "2021-12-30T13:59:22.000000Z"
            },
            {
                "id": 221,
                "country_id": 160,
                "name": "Ondo State University of Science and Technology, Okitipupa",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1242,
                "country_id": 160,
                "name": "Onicha secondary Techanical College Reach Continental Secondary School, Afikpo",
                "created_at": "2022-04-23T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1325,
                "country_id": 160,
                "name": "Oniwe Comprehensive High School, Ilawe Ekiti",
                "created_at": "2022-07-15T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2087,
                "country_id": 160,
                "name": "Onshore And Offshore Safety Institute",
                "created_at": "2022-04-20T17:38:22.000000Z",
                "updated_at": "2022-04-20T17:38:22.000000Z"
            },
            {
                "id": 2051,
                "country_id": 160,
                "name": "Onvia Primary School",
                "created_at": "2022-01-26T04:29:25.000000Z",
                "updated_at": "2022-01-26T04:29:25.000000Z"
            },
            {
                "id": 1914,
                "country_id": 160,
                "name": "Opencast Polytechnic Benin City",
                "created_at": "2021-12-27T10:10:15.000000Z",
                "updated_at": "2021-12-27T10:10:15.000000Z"
            },
            {
                "id": 1228,
                "country_id": 160,
                "name": "Ophregbala Secondary School,Ophregbala",
                "created_at": "2022-04-09T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1273,
                "country_id": 160,
                "name": "Oredo secondary school, Benin city.",
                "created_at": "2022-05-24T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1208,
                "country_id": 160,
                "name": "Orhunwhorun High School, Orhunwhorun",
                "created_at": "2022-03-20T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1620,
                "country_id": 160,
                "name": "Oro Grammar School, Oro, Kwara State",
                "created_at": "2023-05-06T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1179,
                "country_id": 160,
                "name": "Orogun Grammar School, Orogun",
                "created_at": "2022-02-19T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1849,
                "country_id": 160,
                "name": "Oroki Nur/Pri School",
                "created_at": "2021-12-22T01:45:13.000000Z",
                "updated_at": "2021-12-22T01:45:13.000000Z"
            },
            {
                "id": 1644,
                "country_id": 160,
                "name": "Orota Community High School (OCHS) Odo-Owa",
                "created_at": "2023-05-30T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1836,
                "country_id": 160,
                "name": "Osasinmwin Oba Secondary School",
                "created_at": "2021-12-21T22:46:53.000000Z",
                "updated_at": "2021-12-21T22:46:53.000000Z"
            },
            {
                "id": 1921,
                "country_id": 160,
                "name": "OSISATECH Polytechnic and College of Education",
                "created_at": "2021-12-27T10:10:15.000000Z",
                "updated_at": "2021-12-27T10:10:15.000000Z"
            },
            {
                "id": 222,
                "country_id": 160,
                "name": "Osun State University",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1230,
                "country_id": 160,
                "name": "Otowodo Grammar School, Ughelli",
                "created_at": "2022-04-11T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1404,
                "country_id": 160,
                "name": "Otulu commercial secondary school",
                "created_at": "2022-10-02T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1777,
                "country_id": 160,
                "name": "Our Lady Of Apostle Secondary school",
                "created_at": "2021-12-03T21:35:16.000000Z",
                "updated_at": "2021-12-03T21:35:16.000000Z"
            },
            {
                "id": 1277,
                "country_id": 160,
                "name": "Our Lady of fatima, Jattu",
                "created_at": "2022-05-28T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1092,
                "country_id": 160,
                "name": "Our Lady's Comprehensive Secondary, Nkpor",
                "created_at": "2021-11-24T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1193,
                "country_id": 160,
                "name": "Our Lady's High School, Effurun",
                "created_at": "2022-03-05T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1083,
                "country_id": 160,
                "name": "Our Lady's High School, Onitsha",
                "created_at": "2021-11-15T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 224,
                "country_id": 160,
                "name": "Our saviour Institute of science, Agriculture & Technology, Enugu",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 223,
                "country_id": 160,
                "name": "Our saviour Institute of science, Agriculture & Technology(Our Saviour Polytechnic) Enugu",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 999,
                "country_id": 160,
                "name": "Ovom Girls Secondary School, Aba",
                "created_at": "2021-08-23T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1195,
                "country_id": 160,
                "name": "Ovwian Secondary School, Ovwian",
                "created_at": "2022-03-07T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1200,
                "country_id": 160,
                "name": "Owhrode Secondary School, Owhrode",
                "created_at": "2022-03-12T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2075,
                "country_id": 160,
                "name": "Owina Primary School",
                "created_at": "2022-01-28T02:22:31.000000Z",
                "updated_at": "2022-01-28T02:22:31.000000Z"
            },
            {
                "id": 2080,
                "country_id": 160,
                "name": "Owode High School, Owode Egba",
                "created_at": "2022-01-28T02:22:31.000000Z",
                "updated_at": "2022-01-28T02:22:31.000000Z"
            },
            {
                "id": 1994,
                "country_id": 160,
                "name": "Oxford Nursery & Primary School",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1895,
                "country_id": 160,
                "name": "Oyo State School of Science",
                "created_at": "2021-12-23T17:51:53.000000Z",
                "updated_at": "2021-12-23T17:51:53.000000Z"
            },
            {
                "id": 1643,
                "country_id": 160,
                "name": "Oyun Baptist High School, Ijagbo (OBHS)",
                "created_at": "2023-05-29T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1364,
                "country_id": 160,
                "name": "Ozalla Boys School",
                "created_at": "2022-08-23T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1405,
                "country_id": 160,
                "name": "Ozara comprehensive secondary school",
                "created_at": "2022-10-03T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 980,
                "country_id": 160,
                "name": "Pace setter's College, Wuye, Abuja",
                "created_at": "2021-08-04T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 225,
                "country_id": 160,
                "name": "Pan-Atlantic University",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1901,
                "country_id": 160,
                "name": "Paramount Christian College",
                "created_at": "2021-12-23T18:15:34.000000Z",
                "updated_at": "2021-12-23T18:15:34.000000Z"
            },
            {
                "id": 226,
                "country_id": 160,
                "name": "Paul University, Awka",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 2015,
                "country_id": 160,
                "name": "Payne Primary School",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1983,
                "country_id": 160,
                "name": "Pedro Primary School",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1470,
                "country_id": 160,
                "name": "Perkins International Academy of Excellence, Zaria",
                "created_at": "2022-12-07T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 227,
                "country_id": 160,
                "name": "Petroluem Training Institute",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1178,
                "country_id": 160,
                "name": "PHCN Staff School, Ogorode, Sapele",
                "created_at": "2022-02-18T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1379,
                "country_id": 160,
                "name": "Pilot Secondary School Gombe",
                "created_at": "2022-09-07T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 228,
                "country_id": 160,
                "name": "Plateau State Polytechnic, Barkin Ladi",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 229,
                "country_id": 160,
                "name": "Plateau State University, Bokkos",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1832,
                "country_id": 160,
                "name": "Police Children School, Ikeja",
                "created_at": "2021-12-21T22:46:53.000000Z",
                "updated_at": "2021-12-21T22:46:53.000000Z"
            },
            {
                "id": 1402,
                "country_id": 160,
                "name": "Premier Secondary School, Orji, Owerri",
                "created_at": "2022-09-30T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2067,
                "country_id": 160,
                "name": "Presbyterian Primary Schools",
                "created_at": "2022-01-28T02:22:31.000000Z",
                "updated_at": "2022-01-28T02:22:31.000000Z"
            },
            {
                "id": 1271,
                "country_id": 160,
                "name": "Presentation National High School, Benin city.",
                "created_at": "2022-05-22T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1496,
                "country_id": 160,
                "name": "Prime College",
                "created_at": "2023-01-02T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1505,
                "country_id": 160,
                "name": "Prime College",
                "created_at": "2023-01-11T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1848,
                "country_id": 160,
                "name": "Prime Focus Comprehensive Academy",
                "created_at": "2021-12-22T01:45:13.000000Z",
                "updated_at": "2021-12-22T01:45:13.000000Z"
            },
            {
                "id": 1533,
                "country_id": 160,
                "name": "Prince Secondary School",
                "created_at": "2023-02-08T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1394,
                "country_id": 160,
                "name": "Priscilla Memorial Secondary School, Oguta",
                "created_at": "2022-09-22T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1811,
                "country_id": 160,
                "name": "Providence Academy",
                "created_at": "2021-12-06T13:31:16.000000Z",
                "updated_at": "2021-12-06T13:31:16.000000Z"
            },
            {
                "id": 2032,
                "country_id": 160,
                "name": "Providence Heights Secondary School",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1471,
                "country_id": 160,
                "name": "Queen Amina's College, Kaduna",
                "created_at": "2022-12-08T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1634,
                "country_id": 160,
                "name": "Queen Elizabeth Secondary School, Ilorin",
                "created_at": "2023-05-20T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1079,
                "country_id": 160,
                "name": "Queen of the Rosary College, Onitsha",
                "created_at": "2021-11-11T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1358,
                "country_id": 160,
                "name": "Queen of the Rosary Nsukka",
                "created_at": "2022-08-17T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1627,
                "country_id": 160,
                "name": "Queen secondary school Omu-Aran",
                "created_at": "2023-05-13T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2055,
                "country_id": 160,
                "name": "Queenmaris College",
                "created_at": "2022-01-26T04:29:25.000000Z",
                "updated_at": "2022-01-26T04:29:25.000000Z"
            },
            {
                "id": 1886,
                "country_id": 160,
                "name": "Queens College Lagos",
                "created_at": "2021-12-23T12:05:03.000000Z",
                "updated_at": "2021-12-23T12:05:03.000000Z"
            },
            {
                "id": 1064,
                "country_id": 160,
                "name": "Queens Comprehensive College, Ogidi",
                "created_at": "2021-10-27T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1335,
                "country_id": 160,
                "name": "Queens School, Enugu",
                "created_at": "2022-07-25T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1503,
                "country_id": 160,
                "name": "Queens Science Academy, Kano",
                "created_at": "2023-01-09T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1545,
                "country_id": 160,
                "name": "Rainbow Schools, Hotoro, Kano",
                "created_at": "2023-02-20T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1949,
                "country_id": 160,
                "name": "Rao International School",
                "created_at": "2022-01-20T02:49:44.000000Z",
                "updated_at": "2022-01-20T02:49:44.000000Z"
            },
            {
                "id": 230,
                "country_id": 160,
                "name": "Redeemer's University, Ede",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 231,
                "country_id": 160,
                "name": "Redeemers University",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 992,
                "country_id": 160,
                "name": "Regina Pacis College, Garki",
                "created_at": "2021-08-16T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1893,
                "country_id": 160,
                "name": "Reima Royal College",
                "created_at": "2021-12-23T17:38:43.000000Z",
                "updated_at": "2021-12-23T17:38:43.000000Z"
            },
            {
                "id": 232,
                "country_id": 160,
                "name": "Renaissance University Ugbawka,Enugu",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 233,
                "country_id": 160,
                "name": "Rhema University",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1472,
                "country_id": 160,
                "name": "Rimi College, Kaduna",
                "created_at": "2022-12-09T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 234,
                "country_id": 160,
                "name": "Rivers State College of Education",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 235,
                "country_id": 160,
                "name": "Rivers State University of Science and Technology",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1614,
                "country_id": 160,
                "name": "Romichs International Schools, Ilorin",
                "created_at": "2023-04-30T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1386,
                "country_id": 160,
                "name": "Roselec International Nursery Primary Secondary School Skokwa.",
                "created_at": "2022-09-14T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1510,
                "country_id": 160,
                "name": "Royal College",
                "created_at": "2023-01-16T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2017,
                "country_id": 160,
                "name": "Royal Comprehensive College",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 993,
                "country_id": 160,
                "name": "Royal Family Academy, Wuye, Abuja bright future foundation",
                "created_at": "2021-08-17T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2028,
                "country_id": 160,
                "name": "Royal Favour Intl School",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1473,
                "country_id": 160,
                "name": "Royal Palm International School, Kaduna",
                "created_at": "2022-12-10T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1526,
                "country_id": 160,
                "name": "Rumfa College Kano (RCK)",
                "created_at": "2023-02-01T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1125,
                "country_id": 160,
                "name": "Sa'adu Zungur Primary School, Bauchi",
                "created_at": "2021-12-27T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 998,
                "country_id": 160,
                "name": "Sacred Heart Seconday School (Sahaco), Aba",
                "created_at": "2021-08-22T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1491,
                "country_id": 160,
                "name": "Sahatussibyan Littahfeezul Qur'an ayagi.",
                "created_at": "2022-12-28T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1589,
                "country_id": 160,
                "name": "Saint Charles' College, Ankpa",
                "created_at": "2023-04-05T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1498,
                "country_id": 160,
                "name": "Saint Loius Girls Secondary School",
                "created_at": "2023-01-04T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1501,
                "country_id": 160,
                "name": "Saint Thomas Secondary School",
                "created_at": "2023-01-07T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2068,
                "country_id": 160,
                "name": "Saka Tinubu Memorial High School",
                "created_at": "2022-01-28T02:22:31.000000Z",
                "updated_at": "2022-01-28T02:22:31.000000Z"
            },
            {
                "id": 2069,
                "country_id": 160,
                "name": "Saka Tinubu Primary School",
                "created_at": "2022-01-28T02:22:31.000000Z",
                "updated_at": "2022-01-28T02:22:31.000000Z"
            },
            {
                "id": 236,
                "country_id": 160,
                "name": "Salem University, Lokoja",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1487,
                "country_id": 160,
                "name": "Samadi International School, Kano, Nigeria",
                "created_at": "2022-12-24T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1369,
                "country_id": 160,
                "name": "Sambo Secondary School Billiri",
                "created_at": "2022-08-28T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 237,
                "country_id": 160,
                "name": "Samuel Adegboyega University,Ogwa",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1741,
                "country_id": 160,
                "name": "Sanya Grammar School, Surulere",
                "created_at": "2023-09-04T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1474,
                "country_id": 160,
                "name": "Sardauna Memorial College, Kaduna",
                "created_at": "2022-12-11T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2034,
                "country_id": 160,
                "name": "Sari-Iganmu Secondary School\r\n",
                "created_at": "2022-01-26T04:02:10.000000Z",
                "updated_at": "2022-01-26T04:02:10.000000Z"
            },
            {
                "id": 1508,
                "country_id": 160,
                "name": "Saudat Memorial School",
                "created_at": "2023-01-14T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1289,
                "country_id": 160,
                "name": "scholars imperial high school ugbowo benin city",
                "created_at": "2022-06-09T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1027,
                "country_id": 160,
                "name": "school for arabic and islamic studies yola",
                "created_at": "2021-09-20T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 995,
                "country_id": 160,
                "name": "School for the Gifted,Gwagwalada",
                "created_at": "2021-08-19T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 238,
                "country_id": 160,
                "name": "School of Health Information Management",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 239,
                "country_id": 160,
                "name": "School of Medical Laboratory Technology",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1933,
                "country_id": 160,
                "name": "School of Midwifery, St. Camillus Hospital",
                "created_at": "2021-12-30T13:59:22.000000Z",
                "updated_at": "2021-12-30T13:59:22.000000Z"
            },
            {
                "id": 1930,
                "country_id": 160,
                "name": "School of Nursing Luth",
                "created_at": "2021-12-30T13:59:22.000000Z",
                "updated_at": "2021-12-30T13:59:22.000000Z"
            },
            {
                "id": 1929,
                "country_id": 160,
                "name": "School Of Nursing, Federal Teaching Hospital, Ido-Ekiti",
                "created_at": "2021-12-30T13:59:22.000000Z",
                "updated_at": "2021-12-30T13:59:22.000000Z"
            },
            {
                "id": 1935,
                "country_id": 160,
                "name": "School of Psychiatric Nursing, Aro Abeokuta",
                "created_at": "2021-12-30T13:59:22.000000Z",
                "updated_at": "2021-12-30T13:59:22.000000Z"
            },
            {
                "id": 1562,
                "country_id": 160,
                "name": "Science and technical college, Mashi",
                "created_at": "2023-03-09T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1502,
                "country_id": 160,
                "name": "Science College, Dawakin Tofa",
                "created_at": "2023-01-08T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1883,
                "country_id": 160,
                "name": "Scotfield Academy, Lagos",
                "created_at": "2021-12-23T12:05:03.000000Z",
                "updated_at": "2021-12-23T12:05:03.000000Z"
            },
            {
                "id": 1547,
                "country_id": 160,
                "name": "Seat of wisdom group of schools,kano",
                "created_at": "2023-02-22T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1399,
                "country_id": 160,
                "name": "Secondary Technical school Dikenafai,Ideato south",
                "created_at": "2022-09-27T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1022,
                "country_id": 160,
                "name": "Secondary Technical School, Aba.",
                "created_at": "2021-09-15T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1349,
                "country_id": 160,
                "name": "Sedes Sapientia Model Girls College Oghe",
                "created_at": "2022-08-08T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1052,
                "country_id": 160,
                "name": "Senior Science School, Ndon Eyo, Onna",
                "created_at": "2021-10-15T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 963,
                "country_id": 160,
                "name": "Senior Secondary School, Nigeria",
                "created_at": "2021-12-02T17:45:49.000000Z",
                "updated_at": "2021-12-02T17:45:49.000000Z"
            },
            {
                "id": 1855,
                "country_id": 160,
                "name": "Seventh Day Adventist Primary School",
                "created_at": "2021-12-22T20:42:59.000000Z",
                "updated_at": "2021-12-22T20:42:59.000000Z"
            },
            {
                "id": 240,
                "country_id": 160,
                "name": "Shaka polytechnic",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1534,
                "country_id": 160,
                "name": "Sheikh Bashir El Rayyan Nur. Primary & Secondary School",
                "created_at": "2023-02-09T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1377,
                "country_id": 160,
                "name": "Sheikh Mahmud Abubakar Secondary School Kumo",
                "created_at": "2022-09-05T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1294,
                "country_id": 160,
                "name": "Shepherd international college, Ado Ekiti",
                "created_at": "2022-06-14T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1913,
                "country_id": 160,
                "name": "Sigma Bond Academy",
                "created_at": "2021-12-27T10:10:15.000000Z",
                "updated_at": "2021-12-27T10:10:15.000000Z"
            },
            {
                "id": 1105,
                "country_id": 160,
                "name": "Sky crest High School",
                "created_at": "2021-12-07T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1106,
                "country_id": 160,
                "name": "Sky crest Nursery and Primary School",
                "created_at": "2021-12-08T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1475,
                "country_id": 160,
                "name": "Soba Technical College, Zaria",
                "created_at": "2022-12-12T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 241,
                "country_id": 160,
                "name": "Sokoto State University",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1990,
                "country_id": 160,
                "name": "Solid Foundation Children School",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1327,
                "country_id": 160,
                "name": "Solid Foundation High School, Ilawe Ekiti",
                "created_at": "2022-07-17T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 242,
                "country_id": 160,
                "name": "Southwestern University, Nigeria",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1816,
                "country_id": 160,
                "name": "Special Science School",
                "created_at": "2021-12-10T18:46:02.000000Z",
                "updated_at": "2021-12-10T18:46:02.000000Z"
            },
            {
                "id": 1139,
                "country_id": 160,
                "name": "Special Science Senior Secondary School, Makurdi",
                "created_at": "2022-01-10T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1500,
                "country_id": 160,
                "name": "Spring International Secondary school",
                "created_at": "2023-01-06T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1279,
                "country_id": 160,
                "name": "St Angela Girls, Uzarue",
                "created_at": "2022-05-30T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1143,
                "country_id": 160,
                "name": "St Anne's Sec School, Oturkpo",
                "created_at": "2022-01-14T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1129,
                "country_id": 160,
                "name": "St Barnabas Primary School, Twon-Brass, Bayelsa",
                "created_at": "2021-12-31T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1136,
                "country_id": 160,
                "name": "St Barnabas Primary School, Twon-Brass, Bayelsa",
                "created_at": "2022-01-07T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1892,
                "country_id": 160,
                "name": "St Brian's Model College",
                "created_at": "2021-12-23T17:38:43.000000Z",
                "updated_at": "2021-12-23T17:38:43.000000Z"
            },
            {
                "id": 1891,
                "country_id": 160,
                "name": "St Brians Model College",
                "created_at": "2021-12-23T17:38:43.000000Z",
                "updated_at": "2021-12-23T17:38:43.000000Z"
            },
            {
                "id": 1427,
                "country_id": 160,
                "name": "St Catherine Girls Secondary School Nkwere",
                "created_at": "2022-10-25T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1142,
                "country_id": 160,
                "name": "St Francis College, Oturkpo",
                "created_at": "2022-01-13T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1261,
                "country_id": 160,
                "name": "St John Bosco's College, Ubiaja",
                "created_at": "2022-05-12T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1591,
                "country_id": 160,
                "name": "st kizito seminary, Idah",
                "created_at": "2023-04-07T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1186,
                "country_id": 160,
                "name": "St Malachy's College, Sapele",
                "created_at": "2022-02-26T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1287,
                "country_id": 160,
                "name": "St Maria Goretti Girls Grammar School,Dumez road,Off Sakpoba Road,Benincity.",
                "created_at": "2022-06-07T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1945,
                "country_id": 160,
                "name": "St Mary Anglican Primary School",
                "created_at": "2021-12-30T13:59:22.000000Z",
                "updated_at": "2021-12-30T13:59:22.000000Z"
            },
            {
                "id": 1863,
                "country_id": 160,
                "name": "St Michael Primary School",
                "created_at": "2021-12-22T20:42:59.000000Z",
                "updated_at": "2021-12-22T20:42:59.000000Z"
            },
            {
                "id": 1331,
                "country_id": 160,
                "name": "St Patrick Secondary School,Enugu",
                "created_at": "2022-07-21T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1045,
                "country_id": 160,
                "name": "st peter's minor seminary, Yola",
                "created_at": "2021-10-08T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1593,
                "country_id": 160,
                "name": "st peters college, Idah",
                "created_at": "2023-04-09T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1288,
                "country_id": 160,
                "name": "St Rita's Comprehensive High School,St Saviour Road,Benin-city.",
                "created_at": "2022-06-08T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1970,
                "country_id": 160,
                "name": "St Saviour High School",
                "created_at": "2022-01-20T02:49:44.000000Z",
                "updated_at": "2022-01-20T02:49:44.000000Z"
            },
            {
                "id": 1356,
                "country_id": 160,
                "name": "St Theresa Abor",
                "created_at": "2022-08-15T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1628,
                "country_id": 160,
                "name": "St. Anthony's Secondary School, Ilorin",
                "created_at": "2023-05-14T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1024,
                "country_id": 160,
                "name": "St. Anthony's Secondary School, Ohafia.",
                "created_at": "2021-09-17T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1425,
                "country_id": 160,
                "name": "St. Augustine Secondary School (SASS) Nkwere",
                "created_at": "2022-10-23T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1183,
                "country_id": 160,
                "name": "St. Augustine's college (Oboshi college)",
                "created_at": "2022-02-23T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1919,
                "country_id": 160,
                "name": "St. Bernadette Nursery & Primary School",
                "created_at": "2021-12-27T10:10:15.000000Z",
                "updated_at": "2021-12-27T10:10:15.000000Z"
            },
            {
                "id": 1080,
                "country_id": 160,
                "name": "St. Charles Secondary School, Onitsha",
                "created_at": "2021-11-12T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1081,
                "country_id": 160,
                "name": "St. Charles Special Science School, Onitsha",
                "created_at": "2021-11-13T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1882,
                "country_id": 160,
                "name": "St. Francis Grammar School",
                "created_at": "2021-12-23T12:05:03.000000Z",
                "updated_at": "2021-12-23T12:05:03.000000Z"
            },
            {
                "id": 1180,
                "country_id": 160,
                "name": "St. George's Grammar School, Obinomba",
                "created_at": "2022-02-20T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1942,
                "country_id": 160,
                "name": "St. Georges Boys & Girls School",
                "created_at": "2021-12-30T13:59:22.000000Z",
                "updated_at": "2021-12-30T13:59:22.000000Z"
            },
            {
                "id": 2059,
                "country_id": 160,
                "name": "St. John Primary School",
                "created_at": "2022-01-28T02:22:31.000000Z",
                "updated_at": "2022-01-28T02:22:31.000000Z"
            },
            {
                "id": 1172,
                "country_id": 160,
                "name": "St. John's College, Alihagwu, Agbor",
                "created_at": "2022-02-12T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1062,
                "country_id": 160,
                "name": "St. Joseph Comprehensive Secondary School, Ogidi",
                "created_at": "2021-10-25T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1144,
                "country_id": 160,
                "name": "St. joseph's secondary school,Ito",
                "created_at": "2022-01-15T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1431,
                "country_id": 160,
                "name": "St. Kizito Secondary Uruala",
                "created_at": "2022-10-29T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1400,
                "country_id": 160,
                "name": "St. Kizito's Secondary School Umuchima,Ideator South LGA.",
                "created_at": "2022-09-28T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1295,
                "country_id": 160,
                "name": "St. Louis Grammar School Ikere-Ekiti",
                "created_at": "2022-06-15T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1269,
                "country_id": 160,
                "name": "St. Mary British International School, Benin City",
                "created_at": "2022-05-20T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1320,
                "country_id": 160,
                "name": "St. Mary's Girls Grammar School Ikole-Ekiti.",
                "created_at": "2022-07-10T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1074,
                "country_id": 160,
                "name": "St. Mary's High School, Ifitedunu",
                "created_at": "2021-11-06T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1051,
                "country_id": 160,
                "name": "St. Mary's Science College, Abak",
                "created_at": "2021-10-14T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1430,
                "country_id": 160,
                "name": "St. Marys Seminary School Umuowa",
                "created_at": "2022-10-28T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1395,
                "country_id": 160,
                "name": "St. Michael's Secondary School, Orsu-Obodo",
                "created_at": "2022-09-23T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1218,
                "country_id": 160,
                "name": "St. Patrick College, Asaba",
                "created_at": "2022-03-30T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1152,
                "country_id": 160,
                "name": "St. Patrick's college, Ikot-Ansa, Calabar",
                "created_at": "2022-01-23T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1181,
                "country_id": 160,
                "name": "St. Peter Claver's College, Aghalokpe, Sapele",
                "created_at": "2022-02-21T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1296,
                "country_id": 160,
                "name": "St. Peter's Primary School Otun Ekiti",
                "created_at": "2022-06-16T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1292,
                "country_id": 160,
                "name": "St. Stephen's Anglican Primary School, Ayebode-Ekiti",
                "created_at": "2022-06-12T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1787,
                "country_id": 160,
                "name": "St. Sylvanians Primary School",
                "created_at": "2021-12-03T21:48:40.000000Z",
                "updated_at": "2021-12-03T21:48:40.000000Z"
            },
            {
                "id": 1211,
                "country_id": 160,
                "name": "St. Vincent College, Okwagbe",
                "created_at": "2022-03-23T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1621,
                "country_id": 160,
                "name": "St.Claire's girls' School , Offa",
                "created_at": "2023-05-07T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1236,
                "country_id": 160,
                "name": "St.Ita's Girls Grammar School,Sapele",
                "created_at": "2022-04-17T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1952,
                "country_id": 160,
                "name": "St.Martin Primary School",
                "created_at": "2022-01-20T02:49:44.000000Z",
                "updated_at": "2022-01-20T02:49:44.000000Z"
            },
            {
                "id": 1004,
                "country_id": 160,
                "name": "St.Patrick's Model Secondary School ABA",
                "created_at": "2021-08-28T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1020,
                "country_id": 160,
                "name": "St.Patrick's Model Secondary School ABA",
                "created_at": "2021-09-13T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1266,
                "country_id": 160,
                "name": "Standard High School, Benin City",
                "created_at": "2022-05-17T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1063,
                "country_id": 160,
                "name": "Star Light Comprehensive Secondary School, Ogidi",
                "created_at": "2021-10-26T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1680,
                "country_id": 160,
                "name": "State Grammar School, Surulere,Lagos",
                "created_at": "2023-07-05T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2006,
                "country_id": 160,
                "name": "State High School 1",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 2005,
                "country_id": 160,
                "name": "State High School 2",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 2019,
                "country_id": 160,
                "name": "Success Circle Secondary School",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1894,
                "country_id": 160,
                "name": "Sundest Nursery Primary and College Schools",
                "created_at": "2021-12-23T17:38:43.000000Z",
                "updated_at": "2021-12-23T17:38:43.000000Z"
            },
            {
                "id": 1122,
                "country_id": 160,
                "name": "Sunshine Learning Centre, Bauchi",
                "created_at": "2021-12-24T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1530,
                "country_id": 160,
                "name": "Supreme College",
                "created_at": "2023-02-05T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2066,
                "country_id": 160,
                "name": "Sure Foundation Schools",
                "created_at": "2022-01-28T02:22:31.000000Z",
                "updated_at": "2022-01-28T02:22:31.000000Z"
            },
            {
                "id": 2082,
                "country_id": 160,
                "name": "Surulere Girls Secondary School",
                "created_at": "2022-01-30T04:58:46.000000Z",
                "updated_at": "2022-01-30T04:58:46.000000Z"
            },
            {
                "id": 1550,
                "country_id": 160,
                "name": "Ta'adibul Aulad Girls Senior Islamic Sec. School Adakawa (TAGSISSA)",
                "created_at": "2023-02-25T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1476,
                "country_id": 160,
                "name": "Tafawa Balewa Memorial College,Zangon-Kataf",
                "created_at": "2022-12-13T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 243,
                "country_id": 160,
                "name": "Tai Solarin University of Education, Ogun State",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 2044,
                "country_id": 160,
                "name": "Talim Ul Islam Nursery & Primary School",
                "created_at": "2022-01-26T04:02:10.000000Z",
                "updated_at": "2022-01-26T04:02:10.000000Z"
            },
            {
                "id": 244,
                "country_id": 160,
                "name": "Tansian University, Umunya",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 2061,
                "country_id": 160,
                "name": "Taraba State Primary School",
                "created_at": "2022-01-28T02:22:31.000000Z",
                "updated_at": "2022-01-28T02:22:31.000000Z"
            },
            {
                "id": 245,
                "country_id": 160,
                "name": "Taraba State University, Jalingo",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1582,
                "country_id": 160,
                "name": "Technical Science College Bunza",
                "created_at": "2023-03-29T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1974,
                "country_id": 160,
                "name": "Temple Gate Polytechnic, Aba",
                "created_at": "2022-01-23T13:33:24.000000Z",
                "updated_at": "2022-01-23T13:33:24.000000Z"
            },
            {
                "id": 1463,
                "country_id": 160,
                "name": "TENDERCARE NURSERY AND PRIMARY SCHOOL, KADUNA",
                "created_at": "2022-11-30T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1799,
                "country_id": 160,
                "name": "Tendertouch Nursery And Primary School",
                "created_at": "2021-12-06T13:00:02.000000Z",
                "updated_at": "2021-12-06T13:00:02.000000Z"
            },
            {
                "id": 246,
                "country_id": 160,
                "name": "The American University of Nigeria, yola",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1943,
                "country_id": 160,
                "name": "The Apostolic Church Grammar School, Ketu",
                "created_at": "2021-12-30T13:59:22.000000Z",
                "updated_at": "2021-12-30T13:59:22.000000Z"
            },
            {
                "id": 970,
                "country_id": 160,
                "name": "The Capital Science Academy, Kuje",
                "created_at": "2021-07-25T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 247,
                "country_id": 160,
                "name": "The Federal Polytechnic Ado - Ekiti",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 248,
                "country_id": 160,
                "name": "THE FEDERAL POLYTECHNIC KAURA - NAMODA",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 249,
                "country_id": 160,
                "name": "The Federal Polytechnic, Bauchi",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 250,
                "country_id": 160,
                "name": "The Federal Polytechnic, Offa",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 251,
                "country_id": 160,
                "name": "The Federal Polytechnic, Zamfara",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 252,
                "country_id": 160,
                "name": "The Federal University Of Technology, Akure",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1504,
                "country_id": 160,
                "name": "The Light International School",
                "created_at": "2023-01-10T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 253,
                "country_id": 160,
                "name": "THE NIGERIAN BAPTIST THEOLGICAL SEMINARY OGBOMOSO",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 254,
                "country_id": 160,
                "name": "The Nigerian Baptist Theological Seminary",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1887,
                "country_id": 160,
                "name": "The Nigerian Law School",
                "created_at": "2021-12-23T12:05:03.000000Z",
                "updated_at": "2021-12-23T12:05:03.000000Z"
            },
            {
                "id": 255,
                "country_id": 160,
                "name": "THE POLYTECHIC ILE - IFE",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 256,
                "country_id": 160,
                "name": "The Polytechnic Ibadan",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 257,
                "country_id": 160,
                "name": "The Redeemed Christian Bible College",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1014,
                "country_id": 160,
                "name": "the school of Alison Omon, princess of Benin",
                "created_at": "2021-09-07T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 258,
                "country_id": 160,
                "name": "The West African College of Nursing",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1642,
                "country_id": 160,
                "name": "Thomas Adewumi International School Oko",
                "created_at": "2023-05-28T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1610,
                "country_id": 160,
                "name": "Titcombe College, Egbe",
                "created_at": "2023-04-26T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1986,
                "country_id": 160,
                "name": "Tom-Dave Nur/Pry School",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1817,
                "country_id": 160,
                "name": "Township Primary School Nsukka",
                "created_at": "2021-12-10T18:46:02.000000Z",
                "updated_at": "2021-12-10T18:46:02.000000Z"
            },
            {
                "id": 2036,
                "country_id": 160,
                "name": "Toyibat Comprehensive High School",
                "created_at": "2022-01-26T04:02:10.000000Z",
                "updated_at": "2022-01-26T04:02:10.000000Z"
            },
            {
                "id": 1359,
                "country_id": 160,
                "name": "Trans Ekulu Girls College Enugu",
                "created_at": "2022-08-18T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1284,
                "country_id": 160,
                "name": "Travis Christian College, Benin city",
                "created_at": "2022-06-04T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1392,
                "country_id": 160,
                "name": "Trinity High School, Oguta",
                "created_at": "2022-09-20T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1804,
                "country_id": 160,
                "name": "TTC Mobile Ikeja",
                "created_at": "2021-12-06T13:00:02.000000Z",
                "updated_at": "2021-12-06T13:00:02.000000Z"
            },
            {
                "id": 2043,
                "country_id": 160,
                "name": "Tunyo Comprehensive College",
                "created_at": "2022-01-26T04:02:10.000000Z",
                "updated_at": "2022-01-26T04:02:10.000000Z"
            },
            {
                "id": 1544,
                "country_id": 160,
                "name": "Turkish International School, Kano",
                "created_at": "2023-02-19T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2037,
                "country_id": 160,
                "name": "Twinkle Star Nursery and Primary School",
                "created_at": "2022-01-26T04:02:10.000000Z",
                "updated_at": "2022-01-26T04:02:10.000000Z"
            },
            {
                "id": 1223,
                "country_id": 160,
                "name": "Udu Secondary School, Otor-Udu",
                "created_at": "2022-04-04T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1089,
                "country_id": 160,
                "name": "Uga Boys Secondary School, Uga",
                "created_at": "2021-11-21T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1993,
                "country_id": 160,
                "name": "Ugbokodo Secondary School",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1897,
                "country_id": 160,
                "name": "Ukilekpe Primary School",
                "created_at": "2021-12-23T17:51:53.000000Z",
                "updated_at": "2021-12-23T17:51:53.000000Z"
            },
            {
                "id": 1826,
                "country_id": 160,
                "name": "Ukpacha Primary School, Ohafia",
                "created_at": "2021-12-10T19:23:30.000000Z",
                "updated_at": "2021-12-10T19:23:30.000000Z"
            },
            {
                "id": 1567,
                "country_id": 160,
                "name": "Ulul-Albab Science Secondary School, Katsina",
                "created_at": "2023-03-14T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 259,
                "country_id": 160,
                "name": "Umaru Musa Yar Adua University",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 260,
                "country_id": 160,
                "name": "UMCA Theological College",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 261,
                "country_id": 160,
                "name": "UMCATheologiccal College",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 2050,
                "country_id": 160,
                "name": "Umejei Primary School",
                "created_at": "2022-01-26T04:29:25.000000Z",
                "updated_at": "2022-01-26T04:29:25.000000Z"
            },
            {
                "id": 1497,
                "country_id": 160,
                "name": "Ummahatul'mu'uminat Ayagi",
                "created_at": "2023-01-03T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1421,
                "country_id": 160,
                "name": "Umuaka High School",
                "created_at": "2022-10-19T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2076,
                "country_id": 160,
                "name": "Umueleke Secondary School",
                "created_at": "2022-01-28T02:22:31.000000Z",
                "updated_at": "2022-01-28T02:22:31.000000Z"
            },
            {
                "id": 2052,
                "country_id": 160,
                "name": "Umutu Mixed Secondary School",
                "created_at": "2022-01-26T04:29:25.000000Z",
                "updated_at": "2022-01-26T04:29:25.000000Z"
            },
            {
                "id": 1477,
                "country_id": 160,
                "name": "UNCLE BADO MEMORIAL COLLEGE (KADUNA)",
                "created_at": "2022-12-14T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2056,
                "country_id": 160,
                "name": "Uncle T Nursery & Primary School",
                "created_at": "2022-01-26T04:29:25.000000Z",
                "updated_at": "2022-01-26T04:29:25.000000Z"
            },
            {
                "id": 1633,
                "country_id": 160,
                "name": "Unilorin Seconday School, University of Ilorin, Ilorin",
                "created_at": "2023-05-19T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1336,
                "country_id": 160,
                "name": "Union Secondary School, Awkunanaw",
                "created_at": "2022-07-26T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1324,
                "country_id": 160,
                "name": "United High School, Ilawe Ekiti",
                "created_at": "2022-07-14T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1837,
                "country_id": 160,
                "name": "United Methodist High School",
                "created_at": "2021-12-21T22:46:53.000000Z",
                "updated_at": "2021-12-21T22:46:53.000000Z"
            },
            {
                "id": 1793,
                "country_id": 160,
                "name": "United Missionary Comprehensive College",
                "created_at": "2021-12-06T12:05:52.000000Z",
                "updated_at": "2021-12-06T12:05:52.000000Z"
            },
            {
                "id": 1941,
                "country_id": 160,
                "name": "United Primary School",
                "created_at": "2021-12-30T13:59:22.000000Z",
                "updated_at": "2021-12-30T13:59:22.000000Z"
            },
            {
                "id": 1902,
                "country_id": 160,
                "name": "United Universal Comprehensive College",
                "created_at": "2021-12-23T18:15:34.000000Z",
                "updated_at": "2021-12-23T18:15:34.000000Z"
            },
            {
                "id": 1569,
                "country_id": 160,
                "name": "Unity college Malumfashi",
                "created_at": "2023-03-16T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1212,
                "country_id": 160,
                "name": "Unity School, Agbarho",
                "created_at": "2022-03-24T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2031,
                "country_id": 160,
                "name": "Universal School Of Aviation",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 262,
                "country_id": 160,
                "name": "University of Abuja",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 263,
                "country_id": 160,
                "name": "University of Agriculture, Abeokuta",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 264,
                "country_id": 160,
                "name": "University of Agriculture, Makurdi",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 265,
                "country_id": 160,
                "name": "University of Benin",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1285,
                "country_id": 160,
                "name": "University of Benin Demonstration Secondary School (UDSS)",
                "created_at": "2022-06-05T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 266,
                "country_id": 160,
                "name": "University of Calabar",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 267,
                "country_id": 160,
                "name": "University of Enterpreneurship & Technology",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 268,
                "country_id": 160,
                "name": "University of Garden City",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 269,
                "country_id": 160,
                "name": "University of Ibadan",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 270,
                "country_id": 160,
                "name": "University of Ilorin",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 271,
                "country_id": 160,
                "name": "University of Jos",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 272,
                "country_id": 160,
                "name": "University of Lagos",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 273,
                "country_id": 160,
                "name": "University of Maiduguri",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 274,
                "country_id": 160,
                "name": "University of Mkar, Mkar",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 275,
                "country_id": 160,
                "name": "University of Nigeria",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 276,
                "country_id": 160,
                "name": "University of Port Harcourt",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 277,
                "country_id": 160,
                "name": "University of Uyo",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1267,
                "country_id": 160,
                "name": "University Preparatory Secondary School, Benin City",
                "created_at": "2022-05-18T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1333,
                "country_id": 160,
                "name": "University Secondary School Enugu,",
                "created_at": "2022-07-23T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1044,
                "country_id": 160,
                "name": "University Secondary School, FUTY Yola",
                "created_at": "2021-10-07T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1041,
                "country_id": 160,
                "name": "upper benue nry and primary school yola",
                "created_at": "2021-10-04T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1192,
                "country_id": 160,
                "name": "Urhobo College, Effurun",
                "created_at": "2022-03-04T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 278,
                "country_id": 160,
                "name": "Usmanu Danfodiyo University, Sokoto",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1814,
                "country_id": 160,
                "name": "Utagba Ogbe Technical College",
                "created_at": "2021-12-10T18:46:02.000000Z",
                "updated_at": "2021-12-10T18:46:02.000000Z"
            },
            {
                "id": 1338,
                "country_id": 160,
                "name": "Uwani Secondary School,",
                "created_at": "2022-07-28T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1956,
                "country_id": 160,
                "name": "Uwendova Primary School",
                "created_at": "2022-01-20T02:49:44.000000Z",
                "updated_at": "2022-01-20T02:49:44.000000Z"
            },
            {
                "id": 1227,
                "country_id": 160,
                "name": "Uwhurun Grammar School, Uwhurun",
                "created_at": "2022-04-08T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 279,
                "country_id": 160,
                "name": "Veritas University, Abuja",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1780,
                "country_id": 160,
                "name": "Vetland Grammar School",
                "created_at": "2021-12-03T21:35:16.000000Z",
                "updated_at": "2021-12-03T21:35:16.000000Z"
            },
            {
                "id": 1779,
                "country_id": 160,
                "name": "Vetland Primary School",
                "created_at": "2021-12-03T21:35:16.000000Z",
                "updated_at": "2021-12-03T21:35:16.000000Z"
            },
            {
                "id": 1870,
                "country_id": 160,
                "name": "Victory College, Ikare",
                "created_at": "2021-12-22T21:20:01.000000Z",
                "updated_at": "2021-12-22T21:20:01.000000Z"
            },
            {
                "id": 1368,
                "country_id": 160,
                "name": "Walter Gowans Memoria College Kaltungo",
                "created_at": "2022-08-27T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1957,
                "country_id": 160,
                "name": "Waziri Umaru Federal Polytechnic",
                "created_at": "2022-01-20T02:49:44.000000Z",
                "updated_at": "2022-01-20T02:49:44.000000Z"
            },
            {
                "id": 280,
                "country_id": 160,
                "name": "wellspring University",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1975,
                "country_id": 160,
                "name": "Wesley Girls Senior Secondary School",
                "created_at": "2022-01-26T03:04:38.000000Z",
                "updated_at": "2022-01-26T03:04:38.000000Z"
            },
            {
                "id": 1607,
                "country_id": 160,
                "name": "Wesley High School, Ife-Olukotun",
                "created_at": "2023-04-23T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 281,
                "country_id": 160,
                "name": "Wesley University Ondo",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 282,
                "country_id": 160,
                "name": "West Africa Health Examinations Board",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 283,
                "country_id": 160,
                "name": "West Africa Theology Seminary",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 284,
                "country_id": 160,
                "name": "West African College of Nursing",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 285,
                "country_id": 160,
                "name": "West African Postgraduate Medical College",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 2049,
                "country_id": 160,
                "name": "Western Boys High School",
                "created_at": "2022-01-26T04:29:25.000000Z",
                "updated_at": "2022-01-26T04:29:25.000000Z"
            },
            {
                "id": 286,
                "country_id": 160,
                "name": "Western Deita University, Oghara",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 287,
                "country_id": 160,
                "name": "Western Delta University, Oghara",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1013,
                "country_id": 160,
                "name": "Wilcox Memorial Comprehensive Secondary School, Aba",
                "created_at": "2021-09-06T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1561,
                "country_id": 160,
                "name": "Women Teaching College, Katsina",
                "created_at": "2023-03-08T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1264,
                "country_id": 160,
                "name": "Word of Faith College, Benin City",
                "created_at": "2022-05-15T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 991,
                "country_id": 160,
                "name": "Word of Faith Group of Schools, Area 1, Abuja.",
                "created_at": "2021-08-15T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1234,
                "country_id": 160,
                "name": "Word of Faith Group of Schools, Effurun.",
                "created_at": "2022-04-15T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1775,
                "country_id": 160,
                "name": "Wuraville College",
                "created_at": "2021-12-03T21:35:16.000000Z",
                "updated_at": "2021-12-03T21:35:16.000000Z"
            },
            {
                "id": 288,
                "country_id": 160,
                "name": "Yaba College of Technology",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1373,
                "country_id": 160,
                "name": "YAHAYA AHMED MODEL AND SCHOOL GOMBE",
                "created_at": "2022-09-01T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1529,
                "country_id": 160,
                "name": "Yandutse College",
                "created_at": "2023-02-04T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 2081,
                "country_id": 160,
                "name": "Yegunda/Idomu Community Senior High School",
                "created_at": "2022-01-28T02:22:31.000000Z",
                "updated_at": "2022-01-28T02:22:31.000000Z"
            },
            {
                "id": 289,
                "country_id": 160,
                "name": "Yobe State University, Damaturu",
                "created_at": "2021-07-19T09:51:03.000000Z",
                "updated_at": "2021-07-19T09:51:03.000000Z"
            },
            {
                "id": 1602,
                "country_id": 160,
                "name": "young Muslim College, Ankpa",
                "created_at": "2023-04-18T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1207,
                "country_id": 160,
                "name": "Yoworen College, Warri",
                "created_at": "2022-03-19T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1479,
                "country_id": 160,
                "name": "Zamani College, Kaduna",
                "created_at": "2022-12-16T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            },
            {
                "id": 1478,
                "country_id": 160,
                "name": "Zaria academy, Shika",
                "created_at": "2022-12-15T09:51:00.000000Z",
                "updated_at": "2021-07-19T09:51:00.000000Z"
            }
        ];
        return theInstitution.sort(compareByName);
    }

    static getCountries() {
        return [{
            "id": 160,
            "sortname": "NG",
            "name": "Nigeria",
            "phonecode": 234,
            "created_at": null,
            "updated_at": null
        }, {
            "id": 83,
            "sortname": "GH",
            "name": "Ghana",
            "phonecode": 233,
            "created_at": null,
            "updated_at": null
        }];
    }

    static getDegrees() {
        let theDegrees = [
            {"id": "1", "name": "MEM", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {"id": "2", "name": "Others", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {
                "id": "3",
                "name": "( O.D )Doctor of Optometry Degree",
                "created_at": "2021-08-31 09:25:52",
                "updated_at": "2021-08-31 09:25:52"
            },
            {
                "id": "4",
                "name": "A.C.I.B(Dip.)",
                "created_at": "2021-08-31 09:25:52",
                "updated_at": "2021-08-31 09:25:52"
            },
            {"id": "5", "name": "A.C.I.B.", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {"id": "6", "name": "A.I.B.", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {"id": "7", "name": "ACA", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {"id": "8", "name": "ACIA", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {
                "id": "9",
                "name": "Advance Diploma",
                "created_at": "2021-08-31 09:25:52",
                "updated_at": "2021-08-31 09:25:52"
            },
            {"id": "10", "name": "AIMLT", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {"id": "11", "name": "AMIKAD", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {
                "id": "12",
                "name": "B Sc. Information Technology",
                "created_at": "2021-08-31 09:25:52",
                "updated_at": "2021-08-31 09:25:52"
            },
            {"id": "13", "name": "B. Agric", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {"id": "14", "name": "B. Physio", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {
                "id": "15",
                "name": "B.(Forestry)",
                "created_at": "2021-08-31 09:25:52",
                "updated_at": "2021-08-31 09:25:52"
            },
            {"id": "16", "name": "B.A", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {"id": "17", "name": "B.A(ED)", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {
                "id": "18",
                "name": "B.A.(Hons)",
                "created_at": "2021-08-31 09:25:52",
                "updated_at": "2021-08-31 09:25:52"
            },
            {"id": "19", "name": "B.Agric", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {"id": "20", "name": "B.Com", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {"id": "21", "name": "B.E.S.", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {"id": "22", "name": "B.Ed", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {
                "id": "23",
                "name": "B.Ed (Hons)",
                "created_at": "2021-08-31 09:25:52",
                "updated_at": "2021-08-31 09:25:52"
            },
            {"id": "24", "name": "B.Eng.", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {"id": "25", "name": "B.I.R.M", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {"id": "26", "name": "B.L", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {"id": "27", "name": "B.L.S.", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {"id": "28", "name": "B.M.L.S", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {"id": "29", "name": "B.M.R", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {"id": "30", "name": "B.N.Sc", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {"id": "31", "name": "B.Pharm", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {"id": "32", "name": "B.Phil", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {"id": "33", "name": "B.Sc", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {
                "id": "34",
                "name": "B.Sc Agric",
                "created_at": "2021-08-31 09:25:52",
                "updated_at": "2021-08-31 09:25:52"
            },
            {
                "id": "35",
                "name": "B.Sc Agric Tech",
                "created_at": "2021-08-31 09:25:52",
                "updated_at": "2021-08-31 09:25:52"
            },
            {"id": "36", "name": "B.Sc(Edu)", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {"id": "37", "name": "B.Tech", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {"id": "38", "name": "B.Th", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {"id": "39", "name": "BDS", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {"id": "40", "name": "BEng", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {"id": "41", "name": "BLIS", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {"id": "42", "name": "Cert.", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {"id": "43", "name": "D.V.M", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {"id": "44", "name": "D.V.M.", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {"id": "45", "name": "Diploma", "created_at": "2021-08-31 09:25:52", "updated_at": "2021-08-31 09:25:52"},
            {
                "id": "46",
                "name": "Drug Development",
                "created_at": "2021-08-31 09:25:53",
                "updated_at": "2021-08-31 09:25:53"
            },
            {"id": "47", "name": "DSC", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {
                "id": "48",
                "name": "Executive Diploma in Strategic Mgt & Leadership",
                "created_at": "2021-08-31 09:25:53",
                "updated_at": "2021-08-31 09:25:53"
            },
            {"id": "49", "name": "FDC", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "50", "name": "FIMLT", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {
                "id": "51",
                "name": "Final Diploma",
                "created_at": "2021-08-31 09:25:53",
                "updated_at": "2021-08-31 09:25:53"
            },
            {"id": "52", "name": "FWACP", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "53", "name": "FWACS", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "54", "name": "FWC", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "55", "name": "GIS", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "56", "name": "H.N.D", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {
                "id": "57",
                "name": "HND(Community Health)",
                "created_at": "2021-08-31 09:25:53",
                "updated_at": "2021-08-31 09:25:53"
            },
            {
                "id": "58",
                "name": "HND\/Final Dip",
                "created_at": "2021-08-31 09:25:53",
                "updated_at": "2021-08-31 09:25:53"
            },
            {"id": "59", "name": "I.C.A.N", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {
                "id": "60",
                "name": "Intermediate Diploma",
                "created_at": "2021-08-31 09:25:53",
                "updated_at": "2021-08-31 09:25:53"
            },
            {
                "id": "61",
                "name": "International Trade and Development",
                "created_at": "2021-08-31 09:25:53",
                "updated_at": "2021-08-31 09:25:53"
            },
            {"id": "62", "name": "L.T.T.S.", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "63", "name": "LL.B", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "64", "name": "LL.M", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {
                "id": "65",
                "name": "LLM. (Energy Law)",
                "created_at": "2021-08-31 09:25:53",
                "updated_at": "2021-08-31 09:25:53"
            },
            {
                "id": "66",
                "name": "M Sc Veterinary Microbiology",
                "created_at": "2021-08-31 09:25:53",
                "updated_at": "2021-08-31 09:25:53"
            },
            {"id": "67", "name": "M.A", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "68", "name": "M.A.D.E", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "69", "name": "M.A.Ed", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "70", "name": "M.A.R.I.M", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "71", "name": "M.A.S", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "72", "name": "M.B.A", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "73", "name": "M.B.B.S", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "74", "name": "M.D", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "75", "name": "M.DIV.TH", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "76", "name": "M.E.", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "77", "name": "M.E.A", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "78", "name": "M.E.D.", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "79", "name": "M.E.M", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "80", "name": "M.Ed", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "81", "name": "M.Eng", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "82", "name": "M.I.L.R", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "83", "name": "M.I.R", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "84", "name": "M.inf.SC", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "85", "name": "M.L.S", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "86", "name": "M.M.P", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "87", "name": "M.M.R.M", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "88", "name": "M.P.A", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "89", "name": "M.P.H", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "90", "name": "M.Pharm", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "91", "name": "M.Phil", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {
                "id": "92",
                "name": "M.Phil\/Ph.D",
                "created_at": "2021-08-31 09:25:53",
                "updated_at": "2021-08-31 09:25:53"
            },
            {"id": "93", "name": "M.S.W", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "94", "name": "M.Sc", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {
                "id": "95",
                "name": "M.Sc (Hons)",
                "created_at": "2021-08-31 09:25:53",
                "updated_at": "2021-08-31 09:25:53"
            },
            {
                "id": "96",
                "name": "M.Sc(Biomedical Education)",
                "created_at": "2021-08-31 09:25:53",
                "updated_at": "2021-08-31 09:25:53"
            },
            {
                "id": "97",
                "name": "M.SC(Construction Management)",
                "created_at": "2021-08-31 09:25:53",
                "updated_at": "2021-08-31 09:25:53"
            },
            {
                "id": "98",
                "name": "M.Sc. (Energy Studies)",
                "created_at": "2021-08-31 09:25:53",
                "updated_at": "2021-08-31 09:25:53"
            },
            {"id": "99", "name": "M.Tech.", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "100", "name": "M.V.Sc", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {
                "id": "101",
                "name": "Master of Medicine",
                "created_at": "2021-08-31 09:25:53",
                "updated_at": "2021-08-31 09:25:53"
            },
            {
                "id": "102",
                "name": "Master of Science in Int. Trade and Development",
                "created_at": "2021-08-31 09:25:53",
                "updated_at": "2021-08-31 09:25:53"
            },
            {
                "id": "103",
                "name": "Masters in Strategic Studies",
                "created_at": "2021-08-31 09:25:53",
                "updated_at": "2021-08-31 09:25:53"
            },
            {
                "id": "104",
                "name": "Masters in Tourism Development",
                "created_at": "2021-08-31 09:25:53",
                "updated_at": "2021-08-31 09:25:53"
            },
            {"id": "105", "name": "MB Ch.B", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "106", "name": "MCA", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "107", "name": "MD", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "108", "name": "MECPC", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "109", "name": "MHPM", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "110", "name": "MHRS", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "111", "name": "MHS", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "112", "name": "MIPR", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "113", "name": "MIRSS", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "114", "name": "MLIS", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "115", "name": "MPCS", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "116", "name": "MPH", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "117", "name": "MPP", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "118", "name": "MPVM", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "119", "name": "MSM", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "120", "name": "MTM", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "121", "name": "MVPH", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "122", "name": "N.C.E", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {
                "id": "123",
                "name": "National Diploma",
                "created_at": "2021-08-31 09:25:53",
                "updated_at": "2021-08-31 09:25:53"
            },
            {"id": "124", "name": "P.G.D", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {
                "id": "125",
                "name": "P.G.D(BDM)",
                "created_at": "2021-08-31 09:25:53",
                "updated_at": "2021-08-31 09:25:53"
            },
            {
                "id": "126",
                "name": "P.G.D(Business Management)",
                "created_at": "2021-08-31 09:25:53",
                "updated_at": "2021-08-31 09:25:53"
            },
            {
                "id": "127",
                "name": "P.G.D(HSE)",
                "created_at": "2021-08-31 09:25:53",
                "updated_at": "2021-08-31 09:25:53"
            },
            {
                "id": "128",
                "name": "P.G.D(Mass Com)",
                "created_at": "2021-08-31 09:25:53",
                "updated_at": "2021-08-31 09:25:53"
            },
            {
                "id": "129",
                "name": "P.G.D.D.E",
                "created_at": "2021-08-31 09:25:53",
                "updated_at": "2021-08-31 09:25:53"
            },
            {"id": "130", "name": "P.G.D.E", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {
                "id": "131",
                "name": "P.G.D.F.M",
                "created_at": "2021-08-31 09:25:53",
                "updated_at": "2021-08-31 09:25:53"
            },
            {"id": "132", "name": "P.G.D.S", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "133", "name": "PDDD", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "134", "name": "PGDHM", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "135", "name": "PGDSW", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "136", "name": "Ph.D", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "137", "name": "Pharm.D", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "138", "name": "BSW", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "139", "name": "B.EMT", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "140", "name": "AIMLS", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "141", "name": "B.WMA", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "142", "name": "MLC", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "143", "name": "MIAD", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {"id": "144", "name": "MDS", "created_at": "2021-08-31 09:25:53", "updated_at": "2021-08-31 09:25:53"},
            {
                "id": "145",
                "name": "Cambridge O Level",
                "created_at": "2021-12-03 00:14:02",
                "updated_at": "2021-12-03 00:14:02"
            },
            {
                "id": "146",
                "name": "General Certificate of Education (GCE)",
                "created_at": "2021-12-03 00:14:02",
                "updated_at": "2021-12-03 00:14:02"
            },
            {
                "id": "147",
                "name": "High School Certificate(HSC)",
                "created_at": "2021-12-03 00:17:49",
                "updated_at": "2021-12-03 00:17:49"
            },
            {
                "id": "148",
                "name": "National Examination Council",
                "created_at": "2021-12-03 00:17:49",
                "updated_at": "2021-12-03 00:17:49"
            },
            {
                "id": "149",
                "name": "West Africa Examination Council(WAEC)",
                "created_at": "2021-12-03 00:18:23",
                "updated_at": "2021-12-03 00:18:23"
            },
            {
                "id": "150",
                "name": "West African Senior School Certificate Examination(WASSCE)",
                "created_at": "2021-12-03 00:18:23",
                "updated_at": "2021-12-03 00:18:23"
            },
            {
                "id": "151",
                "name": "Higher National Diploma",
                "created_at": "2021-12-02 17:30:27",
                "updated_at": "2021-12-02 17:30:27"
            },
            {
                "id": "152",
                "name": "Junior Secondary School Certificate",
                "created_at": "2021-12-02 19:16:56",
                "updated_at": "2021-12-02 19:16:56"
            },
            {"id": "153", "name": "H.S.E", "created_at": "2022-04-20 17:42:08", "updated_at": "2022-04-20 17:42:08"},
            {
                "id": "154",
                "name": "First School Leaving Certificate",
                "created_at": "2023-06-19 10:11:49",
                "updated_at": "2023-06-19 10:11:49"
            }
        ];

        return theDegrees.sort(compareByName);
    }

    static getCourses() {
        let theCourses = [
            {"id": "1", "name": "Agriculture", "created_at": null, "updated_at": null},
            {"id": "2", "name": "Agricultural Economics", "created_at": null, "updated_at": null},
            {"id": "3", "name": "Agricultural Extension", "created_at": null, "updated_at": null},
            {"id": "4", "name": "Agronomy", "created_at": null, "updated_at": null},
            {"id": "5", "name": "Animal Science", "created_at": null, "updated_at": null},
            {"id": "6", "name": "Crop Science", "created_at": null, "updated_at": null},
            {"id": "7", "name": "Food Science and Technology", "created_at": null, "updated_at": null},
            {"id": "8", "name": "Fisheries", "created_at": null, "updated_at": null},
            {"id": "9", "name": "Forest Resources Management (Forestry)", "created_at": null, "updated_at": null},
            {"id": "10", "name": "Home Science", "created_at": null, "updated_at": null},
            {"id": "11", "name": "Soil Science", "created_at": null, "updated_at": null},
            {"id": "12", "name": "Archeology and Tourism", "created_at": null, "updated_at": null},
            {"id": "13", "name": "Arabic and Islamic Studies", "created_at": null, "updated_at": null},
            {"id": "14", "name": "Christian Religious Studies", "created_at": null, "updated_at": null},
            {"id": "15", "name": "English and Literary Studies", "created_at": null, "updated_at": null},
            {"id": "16", "name": "Fine and Applied Arts (Creatiuve Arts)", "created_at": null, "updated_at": null},
            {"id": "17", "name": "Foreign Languages and Literature", "created_at": null, "updated_at": null},
            {"id": "18", "name": "History and International Studies", "created_at": null, "updated_at": null},
            {"id": "19", "name": "Linguistics and Nigerian Languages", "created_at": null, "updated_at": null},
            {
                "id": "20",
                "name": "Mass Communication (Communication and Language Arts)",
                "created_at": null,
                "updated_at": null
            },
            {"id": "21", "name": "Music", "created_at": null, "updated_at": null},
            {"id": "22", "name": "Theatre and Film Studies", "created_at": null, "updated_at": null},
            {"id": "23", "name": "Biochemistry", "created_at": null, "updated_at": null},
            {"id": "24", "name": "Botany", "created_at": null, "updated_at": null},
            {"id": "25", "name": "Microbiology", "created_at": null, "updated_at": null},
            {"id": "26", "name": "Marine Biology", "created_at": null, "updated_at": null},
            {"id": "27", "name": "Cell Biology & Genetics", "created_at": null, "updated_at": null},
            {"id": "28", "name": "Zoology", "created_at": null, "updated_at": null},
            {"id": "29", "name": "Accountancy", "created_at": null, "updated_at": null},
            {"id": "30", "name": "Acturial Science", "created_at": null, "updated_at": null},
            {"id": "31", "name": "Business Administration", "created_at": null, "updated_at": null},
            {"id": "32", "name": "Business Management", "created_at": null, "updated_at": null},
            {"id": "33", "name": "Banking and Finance", "created_at": null, "updated_at": null},
            {"id": "34", "name": "Hospitality and Tourism", "created_at": null, "updated_at": null},
            {"id": "35", "name": "Marketing", "created_at": null, "updated_at": null},
            {"id": "36", "name": "Insurance", "created_at": null, "updated_at": null},
            {
                "id": "37",
                "name": "Industrial Relations and Personnel Management",
                "created_at": null,
                "updated_at": null
            },
            {"id": "38", "name": "Child Dental Health", "created_at": null, "updated_at": null},
            {"id": "39", "name": "Oral and Maxillofacial Surgery", "created_at": null, "updated_at": null},
            {"id": "40", "name": "Preventive Dentistry", "created_at": null, "updated_at": null},
            {"id": "41", "name": "Restorative Dentistry", "created_at": null, "updated_at": null},
            {"id": "42", "name": "Adult Education and Extra-Mural Studies", "created_at": null, "updated_at": null},
            {"id": "43", "name": "Arts Education", "created_at": null, "updated_at": null},
            {"id": "44", "name": "Education & Accountancy", "created_at": null, "updated_at": null},
            {"id": "45", "name": "Education & Computer Science", "created_at": null, "updated_at": null},
            {"id": "46", "name": "Education & Economics", "created_at": null, "updated_at": null},
            {"id": "47", "name": "Education & Mathematics", "created_at": null, "updated_at": null},
            {"id": "48", "name": "Education & Physics", "created_at": null, "updated_at": null},
            {"id": "49", "name": "Education & Religious Studies", "created_at": null, "updated_at": null},
            {"id": "50", "name": "Education & Social Science", "created_at": null, "updated_at": null},
            {"id": "51", "name": "Education And Biology", "created_at": null, "updated_at": null},
            {"id": "52", "name": "Education And Chemistry", "created_at": null, "updated_at": null},
            {"id": "53", "name": "Education And English Language", "created_at": null, "updated_at": null},
            {"id": "54", "name": "Education And French", "created_at": null, "updated_at": null},
            {"id": "55", "name": "Education And Geography\/Physics", "created_at": null, "updated_at": null},
            {"id": "56", "name": "Education And Political Science", "created_at": null, "updated_at": null},
            {"id": "57", "name": "Educational Foundations", "created_at": null, "updated_at": null},
            {
                "id": "58",
                "name": "Educational \/ Psychology Guidance And Counselling",
                "created_at": null,
                "updated_at": null
            },
            {"id": "59", "name": "Health and Physical Education", "created_at": null, "updated_at": null},
            {"id": "60", "name": "Library and Information Science", "created_at": null, "updated_at": null},
            {"id": "61", "name": "Science Education", "created_at": null, "updated_at": null},
            {"id": "62", "name": "Social Sciences Education", "created_at": null, "updated_at": null},
            {
                "id": "63",
                "name": "Vocational Teacher Education (Technical Education)",
                "created_at": null,
                "updated_at": null
            },
            {"id": "64", "name": "Religion", "created_at": null, "updated_at": null},
            {"id": "65", "name": "Igbo Linguistics", "created_at": null, "updated_at": null},
            {"id": "66", "name": "Agricultural and Bioresources Engineering", "created_at": null, "updated_at": null},
            {"id": "67", "name": "Civil Engineering", "created_at": null, "updated_at": null},
            {"id": "68", "name": "Chemical Engineering", "created_at": null, "updated_at": null},
            {"id": "69", "name": "Computer Engineering", "created_at": null, "updated_at": null},
            {"id": "70", "name": "Electrical Engineering", "created_at": null, "updated_at": null},
            {"id": "71", "name": "Electronic Engineering", "created_at": null, "updated_at": null},
            {"id": "72", "name": "Marine Engineering", "created_at": null, "updated_at": null},
            {"id": "73", "name": "Mechanical Engineering", "created_at": null, "updated_at": null},
            {"id": "74", "name": "Metallurgical and Materials Engineering", "created_at": null, "updated_at": null},
            {"id": "75", "name": "Petroleum and Gas Engineering", "created_at": null, "updated_at": null},
            {"id": "76", "name": "Systems Engineering", "created_at": null, "updated_at": null},
            {"id": "77", "name": "Structural Engineering", "created_at": null, "updated_at": null},
            {"id": "78", "name": "Production and Industrial Engineering", "created_at": null, "updated_at": null},
            {"id": "79", "name": "Architecture", "created_at": null, "updated_at": null},
            {"id": "80", "name": "Estate Management", "created_at": null, "updated_at": null},
            {"id": "81", "name": "Quantity Surveying", "created_at": null, "updated_at": null},
            {"id": "82", "name": "Building", "created_at": null, "updated_at": null},
            {"id": "83", "name": "Geoinformatics and Surveying", "created_at": null, "updated_at": null},
            {"id": "84", "name": "Urban and Regional Planning", "created_at": null, "updated_at": null},
            {"id": "85", "name": "Health Administration and Management", "created_at": null, "updated_at": null},
            {"id": "86", "name": "Medical Laboratory Sciences", "created_at": null, "updated_at": null},
            {
                "id": "87",
                "name": "Medical Radiography and Radiological Sciences",
                "created_at": null,
                "updated_at": null
            },
            {"id": "88", "name": "Medical Rehabilitation", "created_at": null, "updated_at": null},
            {"id": "89", "name": "Nursing Sciences", "created_at": null, "updated_at": null},
            {"id": "90", "name": "Commercial and Property Law", "created_at": null, "updated_at": null},
            {"id": "91", "name": "International and Jurisprudence", "created_at": null, "updated_at": null},
            {"id": "92", "name": "Private and Public Law", "created_at": null, "updated_at": null},
            {"id": "93", "name": "Anatomy", "created_at": null, "updated_at": null},
            {"id": "94", "name": "Anesthesia", "created_at": null, "updated_at": null},
            {"id": "95", "name": "Chemical Pathology", "created_at": null, "updated_at": null},
            {"id": "96", "name": "Community Medicine", "created_at": null, "updated_at": null},
            {"id": "97", "name": "Dermatology", "created_at": null, "updated_at": null},
            {"id": "98", "name": "Hematology and Immunology", "created_at": null, "updated_at": null},
            {"id": "99", "name": "Medical Biochemistry", "created_at": null, "updated_at": null},
            {"id": "100", "name": "Medical Microbiology", "created_at": null, "updated_at": null},
            {"id": "101", "name": "Medicine", "created_at": null, "updated_at": null},
            {"id": "102", "name": "Morbid Anatomy", "created_at": null, "updated_at": null},
            {"id": "103", "name": "Obstetrics and Gynecology", "created_at": null, "updated_at": null},
            {"id": "104", "name": "Ophthalmology", "created_at": null, "updated_at": null},
            {"id": "105", "name": "Otolaryngology", "created_at": null, "updated_at": null},
            {"id": "106", "name": "Pediatrics", "created_at": null, "updated_at": null},
            {"id": "107", "name": "Pharmacology and Therapeutics", "created_at": null, "updated_at": null},
            {"id": "108", "name": "Physiology", "created_at": null, "updated_at": null},
            {"id": "109", "name": "Radiation Medicine", "created_at": null, "updated_at": null},
            {"id": "110", "name": "Surgery", "created_at": null, "updated_at": null},
            {"id": "111", "name": "Psychological Medicine", "created_at": null, "updated_at": null},
            {"id": "112", "name": "Clinical Pharmacy and Pharmacy Management", "created_at": null, "updated_at": null},
            {
                "id": "113",
                "name": "Pharmaceutical Chemistry and Industrial Pharmacy",
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "114",
                "name": "Pharmaceutical Technology and Industrial Pharmacy",
                "created_at": null,
                "updated_at": null
            },
            {"id": "115", "name": "Pharmaceutics", "created_at": null, "updated_at": null},
            {"id": "116", "name": "Pharmacognosy", "created_at": null, "updated_at": null},
            {"id": "117", "name": "Department of Pharmacology and Toxicology", "created_at": null, "updated_at": null},
            {"id": "118", "name": "Computer Science", "created_at": null, "updated_at": null},
            {"id": "119", "name": "Geology", "created_at": null, "updated_at": null},
            {"id": "120", "name": "Mathematics", "created_at": null, "updated_at": null},
            {"id": "121", "name": "Physics and Astronomy", "created_at": null, "updated_at": null},
            {"id": "122", "name": "Geophysics", "created_at": null, "updated_at": null},
            {"id": "123", "name": "Pure and Industrial Chemistry", "created_at": null, "updated_at": null},
            {"id": "124", "name": "Statistics", "created_at": null, "updated_at": null},
            {"id": "125", "name": "Economics", "created_at": null, "updated_at": null},
            {"id": "126", "name": "Geography", "created_at": null, "updated_at": null},
            {"id": "127", "name": "Philosophy", "created_at": null, "updated_at": null},
            {"id": "128", "name": "Political Science", "created_at": null, "updated_at": null},
            {"id": "129", "name": "Psychology", "created_at": null, "updated_at": null},
            {"id": "130", "name": "Public Administration and Local Government", "created_at": null, "updated_at": null},
            {"id": "131", "name": "Social Work", "created_at": null, "updated_at": null},
            {"id": "132", "name": "Sociology\/Anthropology", "created_at": null, "updated_at": null},
            {"id": "133", "name": "Veterinary Physiology\/Pharmacology", "created_at": null, "updated_at": null},
            {"id": "134", "name": "Veterinary Anatomy", "created_at": null, "updated_at": null},
            {"id": "135", "name": "Animal Health and Production", "created_at": null, "updated_at": null},
            {"id": "136", "name": "Veterinary Parasitology and Entomology", "created_at": null, "updated_at": null},
            {"id": "137", "name": "Veterinary Pathology and Microbiology", "created_at": null, "updated_at": null},
            {
                "id": "138",
                "name": "Veterinary Public Health and Preventive Medicine",
                "created_at": null,
                "updated_at": null
            },
            {"id": "139", "name": "Veterinary Surgery", "created_at": null, "updated_at": null},
            {"id": "140", "name": "Veterinary Medicine", "created_at": null, "updated_at": null},
            {
                "id": "141",
                "name": "Veterinary Obstetrics and Reproductive Diseases",
                "created_at": null,
                "updated_at": null
            },
            {"id": "142", "name": "Veterinary Teaching Hospital", "created_at": null, "updated_at": null},
            {"id": "143", "name": "Abadina Media Resource Centre", "created_at": null, "updated_at": null},
            {"id": "144", "name": "Adult Education", "created_at": null, "updated_at": null},
            {
                "id": "145",
                "name": "Africa Regional Centre for Information Science",
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "146",
                "name": "Agricultural  and Environmental Engineering",
                "created_at": null,
                "updated_at": null
            },
            {"id": "147", "name": "Agricultural and Environmental Engineering", "created_at": null, "updated_at": null},
            {
                "id": "148",
                "name": "Agricultural Extension and Rural Development",
                "created_at": null,
                "updated_at": null
            },
            {"id": "149", "name": "Anaesthesia", "created_at": null, "updated_at": null},
            {"id": "150", "name": "Aquaculture and Fisheries Management", "created_at": null, "updated_at": null},
            {"id": "151", "name": "Archaeology and Anthropology", "created_at": null, "updated_at": null},
            {"id": "152", "name": "Botany and Microbiology", "created_at": null, "updated_at": null},
            {
                "id": "153",
                "name": "Centre for Child and Adolescent Mental Health",
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "154",
                "name": "Centre For Drug Discovery, Development and Production",
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "155",
                "name": "Centre for Educational Media Resource Studies",
                "created_at": null,
                "updated_at": null
            },
            {"id": "156", "name": "Centre For Entrepreneurship And Innovation", "created_at": null, "updated_at": null},
            {"id": "157", "name": "Centre for Peace and Conflict Studies", "created_at": null, "updated_at": null},
            {
                "id": "158",
                "name": "Centre for Petroleum, Energy Economics and Law",
                "created_at": null,
                "updated_at": null
            },
            {"id": "159", "name": "Centre For Sustainable Development", "created_at": null, "updated_at": null},
            {"id": "160", "name": "Chemistry", "created_at": null, "updated_at": null},
            {"id": "161", "name": "Classics", "created_at": null, "updated_at": null},
            {
                "id": "162",
                "name": "Clinical Pharmacy and Pharmacy Administration",
                "created_at": null,
                "updated_at": null
            },
            {"id": "163", "name": "Clinical Sciences", "created_at": null, "updated_at": null},
            {"id": "164", "name": "Communication and Language Arts", "created_at": null, "updated_at": null},
            {"id": "165", "name": "Crop Protection and Environmental Biology", "created_at": null, "updated_at": null},
            {"id": "166", "name": "Dental Surgery", "created_at": null, "updated_at": null},
            {"id": "167", "name": "Dentistry", "created_at": null, "updated_at": null},
            {"id": "168", "name": "DLC", "created_at": null, "updated_at": null},
            {"id": "169", "name": "Educational Management", "created_at": null, "updated_at": null},
            {"id": "170", "name": "Electrical and Electronics Engineering", "created_at": null, "updated_at": null},
            {"id": "171", "name": "English", "created_at": null, "updated_at": null},
            {"id": "172", "name": "Environmental Health Sciences", "created_at": null, "updated_at": null},
            {"id": "173", "name": "Epidemiology and Medical Statistics ", "created_at": null, "updated_at": null},
            {
                "id": "174",
                "name": "Epidemiology, Medical Statistics and Environmental Health",
                "created_at": null,
                "updated_at": null
            },
            {"id": "175", "name": "European Studies", "created_at": null, "updated_at": null},
            {"id": "176", "name": "Food Technology", "created_at": null, "updated_at": null},
            {"id": "177", "name": "Forest Resources Management", "created_at": null, "updated_at": null},
            {"id": "178", "name": "Geography (Science)", "created_at": null, "updated_at": null},
            {"id": "179", "name": "Guidance and Counselling", "created_at": null, "updated_at": null},
            {"id": "180", "name": "Haematology", "created_at": null, "updated_at": null},
            {"id": "181", "name": "Health Policy and Management", "created_at": null, "updated_at": null},
            {"id": "182", "name": "Health Promotion and Education", "created_at": null, "updated_at": null},
            {"id": "183", "name": "History", "created_at": null, "updated_at": null},
            {"id": "184", "name": "Human Kinetics and Health Education", "created_at": null, "updated_at": null},
            {"id": "185", "name": "Human Nutrition", "created_at": null, "updated_at": null},
            {"id": "186", "name": "Industrial and Production Engineering", "created_at": null, "updated_at": null},
            {"id": "187", "name": "Institute for Peace and Strategic Studies", "created_at": null, "updated_at": null},
            {"id": "188", "name": "Institute of African Studies", "created_at": null, "updated_at": null},
            {"id": "189", "name": "Institute of Child Health", "created_at": null, "updated_at": null},
            {"id": "190", "name": "Institute of Education", "created_at": null, "updated_at": null},
            {"id": "191", "name": "Law", "created_at": null, "updated_at": null},
            {"id": "192", "name": "Library, Archival and Information Studies", "created_at": null, "updated_at": null},
            {"id": "193", "name": "Linguistics and African Languages", "created_at": null, "updated_at": null},
            {"id": "194", "name": "Medical Microbiology and Parasitology", "created_at": null, "updated_at": null},
            {"id": "195", "name": "Medical Statistics and Epidemiology", "created_at": null, "updated_at": null},
            {"id": "196", "name": "Modern European Languages", "created_at": null, "updated_at": null},
            {"id": "197", "name": "Nursing", "created_at": null, "updated_at": null},
            {"id": "198", "name": "Obstetrics and Gynaecology", "created_at": null, "updated_at": null},
            {"id": "199", "name": "Opthamology", "created_at": null, "updated_at": null},
            {"id": "200", "name": "Otorhinolaryngology", "created_at": null, "updated_at": null},
            {"id": "201", "name": "Paediatrics", "created_at": null, "updated_at": null},
            {"id": "202", "name": "Pathology", "created_at": null, "updated_at": null},
            {"id": "203", "name": "Petroleum Engineering", "created_at": null, "updated_at": null},
            {"id": "204", "name": "Pharmaceutical Chemistry", "created_at": null, "updated_at": null},
            {"id": "205", "name": "Pharmaceutical Microbiology", "created_at": null, "updated_at": null},
            {"id": "206", "name": "Pharmaceutics and Industrial Pharmacy", "created_at": null, "updated_at": null},
            {"id": "207", "name": "Physical and Health Education", "created_at": null, "updated_at": null},
            {"id": "208", "name": "Physics", "created_at": null, "updated_at": null},
            {"id": "209", "name": "Physiotherapy", "created_at": null, "updated_at": null},
            {"id": "210", "name": "Preventive and Social Medicine", "created_at": null, "updated_at": null},
            {"id": "211", "name": "Preventive Medicine and Primary Care", "created_at": null, "updated_at": null},
            {"id": "212", "name": "Psychiatry", "created_at": null, "updated_at": null},
            {"id": "213", "name": "Radiation Oncology", "created_at": null, "updated_at": null},
            {"id": "214", "name": "Radiotherapy", "created_at": null, "updated_at": null},
            {"id": "215", "name": "Religious Studies", "created_at": null, "updated_at": null},
            {"id": "216", "name": "School of Business", "created_at": null, "updated_at": null},
            {"id": "217", "name": "Sociology", "created_at": null, "updated_at": null},
            {"id": "218", "name": "Special Education", "created_at": null, "updated_at": null},
            {"id": "219", "name": "Teacher Education", "created_at": null, "updated_at": null},
            {"id": "220", "name": "Theatre Arts", "created_at": null, "updated_at": null},
            {"id": "221", "name": "Veterinary Microbiology and Parasitology", "created_at": null, "updated_at": null},
            {"id": "222", "name": "Veterinary Pathology", "created_at": null, "updated_at": null},
            {"id": "223", "name": "Veterinary Physiology and Pharmacology", "created_at": null, "updated_at": null},
            {"id": "224", "name": "Veterinary Physiology, Bioch. and Pharm.", "created_at": null, "updated_at": null},
            {"id": "225", "name": "Veterinary Surgery and Reproduction", "created_at": null, "updated_at": null},
            {"id": "226", "name": "Virology", "created_at": null, "updated_at": null},
            {"id": "227", "name": "Wildlife and Ecotourism Management", "created_at": null, "updated_at": null},
            {"id": "228", "name": "Wildlife and Fisheries Management", "created_at": null, "updated_at": null},
            {"id": "229", "name": "Business Education", "created_at": null, "updated_at": null},
            {"id": "230", "name": "Public Health", "created_at": null, "updated_at": null},
            {"id": "231", "name": "Yoruba Language and Literature", "created_at": null, "updated_at": null},
            {"id": "232", "name": "Mining Engineering", "created_at": null, "updated_at": null},
            {"id": "233", "name": "Pharmacy", "created_at": null, "updated_at": null},
            {"id": "234", "name": "B.Wildlife Management", "created_at": null, "updated_at": null},
            {"id": "235", "name": "Operations Research", "created_at": null, "updated_at": null},
            {"id": "236", "name": "B.Sc.(Demography and Social Statistics)", "created_at": null, "updated_at": null},
            {"id": "237", "name": "B.Tech(Pure and Applied Chemistry)", "created_at": null, "updated_at": null},
            {"id": "238", "name": "Biology", "created_at": null, "updated_at": null},
            {"id": "239", "name": "Biological Science", "created_at": null, "updated_at": null},
            {"id": "240", "name": "Accounting", "created_at": null, "updated_at": null},
            {"id": "241", "name": "Accounting and Finance", "created_at": null, "updated_at": null},
            {
                "id": "242",
                "name": "Telecommunications and Information Technology",
                "created_at": null,
                "updated_at": null
            },
            {"id": "243", "name": "Information Technology", "created_at": null, "updated_at": null},
            {"id": "244", "name": "Tourism Studies", "created_at": null, "updated_at": null},
            {"id": "245", "name": "Office Technology and Management", "created_at": null, "updated_at": null},
            {"id": "246", "name": "Secretarial Administration", "created_at": null, "updated_at": null},
            {"id": "247", "name": "Information Technology Management", "created_at": null, "updated_at": null},
            {"id": "248", "name": "International Studies", "created_at": null, "updated_at": null},
            {"id": "249", "name": "telecommunication science", "created_at": null, "updated_at": null},
            {"id": "250", "name": "Industrial Chemistry", "created_at": null, "updated_at": null},
            {"id": "251", "name": "Demography and social statistics", "created_at": null, "updated_at": null},
            {"id": "252", "name": "Information Resources Management", "created_at": null, "updated_at": null},
            {"id": "253", "name": "Science Laboratory Technology", "created_at": null, "updated_at": null},
            {"id": "254", "name": "Health Information Management", "created_at": null, "updated_at": null},
            {"id": "255", "name": "Social Studies\/Primary Education Studies", "created_at": null, "updated_at": null},
            {"id": "256", "name": "Early Childhood Education", "created_at": null, "updated_at": null},
            {"id": "257", "name": "Social Studies Education", "created_at": null, "updated_at": null},
            {"id": "258", "name": "Geography & Regional Planning", "created_at": null, "updated_at": null},
            {"id": "259", "name": "International Relations", "created_at": null, "updated_at": null},
            {"id": "260", "name": "Hutrition and Dietetics", "created_at": null, "updated_at": null},
            {"id": "261", "name": "Petroleum and Petrochemical Science ", "created_at": null, "updated_at": null},
            {"id": "262", "name": "French Language", "created_at": null, "updated_at": null},
            {"id": "263", "name": "Intergrated Science", "created_at": null, "updated_at": null},
            {"id": "264", "name": "Community Health", "created_at": null, "updated_at": null},
            {"id": "265", "name": "Environmental Management and Toxicology", "created_at": null, "updated_at": null},
            {"id": "266", "name": "Home Economics a", "created_at": null, "updated_at": null},
            {"id": "267", "name": "Home and Hotel Management", "created_at": null, "updated_at": null},
            {"id": "268", "name": "Education Evaluation", "created_at": null, "updated_at": null},
            {"id": "269", "name": "Industrial Design", "created_at": null, "updated_at": null},
            {"id": "270", "name": "Physics with Electronics", "created_at": null, "updated_at": null},
            {"id": "271", "name": "Transport Management Technology", "created_at": null, "updated_at": null},
            {"id": "272", "name": "Home Economics ", "created_at": null, "updated_at": null},
            {"id": "273", "name": "Physics \/ Education", "created_at": null, "updated_at": null},
            {"id": "274", "name": "Printing Technology", "created_at": null, "updated_at": null},
            {"id": "275", "name": "Home Science and Management", "created_at": null, "updated_at": null},
            {"id": "276", "name": "Housing Development and Management", "created_at": null, "updated_at": null},
            {"id": "277", "name": "Literature in English", "created_at": null, "updated_at": null},
            {"id": "278", "name": "Biology\/ Microbiology", "created_at": null, "updated_at": null},
            {"id": "279", "name": "Nutrition and Dietetics", "created_at": null, "updated_at": null},
            {"id": "280", "name": "Horticulture and Landscape Technology", "created_at": null, "updated_at": null},
            {"id": "281", "name": "Social Justice", "created_at": null, "updated_at": null},
            {
                "id": "282",
                "name": "Agricultural Meteorology and Water Management",
                "created_at": null,
                "updated_at": null
            },
            {"id": "283", "name": "Primary Education\/Geography", "created_at": null, "updated_at": null},
            {"id": "284", "name": "Agricultural Engineering", "created_at": null, "updated_at": null},
            {"id": "285", "name": "Entrepreneurship", "created_at": null, "updated_at": null},
            {"id": "286", "name": "Cooperative Economics and Management", "created_at": null, "updated_at": null},
            {"id": "287", "name": "Education and Yoruba", "created_at": null, "updated_at": null},
            {"id": "288", "name": "Publishing and Copyright Studies", "created_at": null, "updated_at": null},
            {"id": "289", "name": "Plant Science and Biotechnology", "created_at": null, "updated_at": null},
            {"id": "290", "name": "Geographical Information Systems", "created_at": null, "updated_at": null},
            {"id": "291", "name": "Forestry and Wildlife Management", "created_at": null, "updated_at": null},
            {"id": "292", "name": "Physics and Solar Energy", "created_at": null, "updated_at": null},
            {"id": "293", "name": "Food, Nutrition and Dietetics", "created_at": null, "updated_at": null},
            {"id": "294", "name": "Energy Studies", "created_at": null, "updated_at": null},
            {"id": "295", "name": "Plant Physiology and Crop Production", "created_at": null, "updated_at": null},
            {"id": "296", "name": "Physics and Electronics", "created_at": null, "updated_at": null},
            {"id": "297", "name": "Animal Physiology", "created_at": null, "updated_at": null},
            {"id": "298", "name": "Engineering Physics", "created_at": null, "updated_at": null},
            {"id": "299", "name": "Electronics Engineering", "created_at": null, "updated_at": null},
            {"id": "300", "name": "International Health", "created_at": null, "updated_at": null},
            {"id": "301", "name": "Environmental Biology", "created_at": null, "updated_at": null},
            {"id": "302", "name": "Heamatology and Blood Group Serology", "created_at": null, "updated_at": null},
            {"id": "303", "name": "Public and Community Health", "created_at": null, "updated_at": null},
            {"id": "304", "name": "International Law and Diplomacy", "created_at": null, "updated_at": null},
            {"id": "305", "name": "Marine Science and Technology", "created_at": null, "updated_at": null},
            {"id": "306", "name": "Business Administration and Management ", "created_at": null, "updated_at": null},
            {"id": "307", "name": "Botany and Ecological Studies", "created_at": null, "updated_at": null},
            {"id": "308", "name": "Agricultural Biochemistry and Nutrition", "created_at": null, "updated_at": null},
            {"id": "309", "name": "Animal Nutrition and Biotechnology", "created_at": null, "updated_at": null},
            {"id": "310", "name": "Agricultural Administration", "created_at": null, "updated_at": null},
            {"id": "311", "name": "Entrepreneurial Studies", "created_at": null, "updated_at": null},
            {"id": "312", "name": "Water Resources Management and Hydrology", "created_at": null, "updated_at": null},
            {"id": "313", "name": "Plant Health Management", "created_at": null, "updated_at": null},
            {"id": "314", "name": "Entrepreneural Studies", "created_at": null, "updated_at": null},
            {"id": "315", "name": "Peace and Security Studies", "created_at": null, "updated_at": null},
            {"id": "316", "name": "Higher Defence and Strategic Studies", "created_at": null, "updated_at": null},
            {"id": "317", "name": "Electrical\/Telecom Engineering", "created_at": null, "updated_at": null},
            {"id": "318", "name": "Wood Products Engineering", "created_at": null, "updated_at": null},
            {
                "id": "319",
                "name": "International Relations and Defence Studies",
                "created_at": null,
                "updated_at": null
            },
            {"id": "320", "name": "Others", "created_at": null, "updated_at": null},
            {"id": "321", "name": "Law Enforcement and Criminal Justice", "created_at": null, "updated_at": null},
            {"id": "322", "name": "International Affairs and Diplomacy", "created_at": null, "updated_at": null},
            {"id": "323", "name": "Electronic and Telecom Engineering", "created_at": null, "updated_at": null},
            {"id": "324", "name": "Political Science and Defence Studies", "created_at": null, "updated_at": null},
            {
                "id": "325",
                "name": "Maintenance Engineering and Asset Management",
                "created_at": null,
                "updated_at": null
            },
            {"id": "326", "name": "Security Operations and Management", "created_at": null, "updated_at": null},
            {"id": "327", "name": "Strategic Studies", "created_at": null, "updated_at": null},
            {"id": "328", "name": "Genetics and Biotechnology", "created_at": null, "updated_at": null},
            {"id": "329", "name": "Resource Management & Extension", "created_at": null, "updated_at": null},
            {"id": "330", "name": "Government & Public Administration", "created_at": null, "updated_at": null},
            {"id": "331", "name": "Developmental Studies", "created_at": null, "updated_at": null},
            {"id": "332", "name": "Industrial Mathematics", "created_at": null, "updated_at": null},
            {"id": "333", "name": "Christian Theology", "created_at": null, "updated_at": null},
            {"id": "334", "name": "Intelligence & Security Studies", "created_at": null, "updated_at": null},
            {"id": "335", "name": "Biomedical Engineering", "created_at": null, "updated_at": null},
            {"id": "336", "name": "purchasing and Supply", "created_at": null, "updated_at": null},
            {"id": "337", "name": "Highway and Transportation Engineering", "created_at": null, "updated_at": null},
            {"id": "338", "name": "Epizootiology", "created_at": null, "updated_at": null},
            {"id": "339", "name": "Optometry", "created_at": null, "updated_at": null},
            {"id": "340", "name": "Educational Technology", "created_at": null, "updated_at": null},
            {"id": "341", "name": "Meteorology", "created_at": null, "updated_at": null},
            {"id": "342", "name": "Criminology and Security Studies", "created_at": null, "updated_at": null},
            {"id": "343", "name": "Health Administration", "created_at": null, "updated_at": null},
            {"id": "344", "name": "Forensic Science", "created_at": null, "updated_at": null},
            {"id": "345", "name": "Information Science", "created_at": null, "updated_at": null},
            {"id": "346", "name": "Dental Therapy", "created_at": null, "updated_at": null},
            {"id": "347", "name": "Integrated Science Education", "created_at": null, "updated_at": null},
            {"id": "348", "name": "Management Information System", "created_at": null, "updated_at": null},
            {"id": "349", "name": "Production Engineering", "created_at": null, "updated_at": null},
            {"id": "350", "name": "Land Surveying", "created_at": null, "updated_at": null},
            {"id": "351", "name": "Peace and Conflict Studies", "created_at": null, "updated_at": null},
            {"id": "352", "name": "Negotiation, Mediation and Arbitration", "created_at": null, "updated_at": null},
            {"id": "353", "name": "Governance and Regional Integration", "created_at": null, "updated_at": null},
            {"id": "354", "name": "Parasitology", "created_at": null, "updated_at": null},
            {"id": "355", "name": "Agro-forestry", "created_at": null, "updated_at": null},
            {"id": "356", "name": "Forestry and Wildlife", "created_at": null, "updated_at": null},
            {"id": "357", "name": "Health Education", "created_at": null, "updated_at": null},
            {"id": "358", "name": "Public Policy", "created_at": null, "updated_at": null},
            {"id": "359", "name": "Public Administration", "created_at": null, "updated_at": null},
            {"id": "360", "name": "Research and Public Policy", "created_at": null, "updated_at": null},
            {"id": "361", "name": "English Language\/Islamic Studies", "created_at": null, "updated_at": null},
            {"id": "362", "name": "Local Government and Development Studies", "created_at": null, "updated_at": null},
            {"id": "363", "name": "Glass and Ceramic Technology", "created_at": null, "updated_at": null},
            {"id": "364", "name": "Entrepreneurship Education", "created_at": null, "updated_at": null},
            {"id": "365", "name": "English and Linguistics", "created_at": null, "updated_at": null},
            {"id": "366", "name": "Human Resource Management", "created_at": null, "updated_at": null},
            {"id": "367", "name": "Project Management Technology", "created_at": null, "updated_at": null},
            {"id": "368", "name": "Archaeology\/Botany", "created_at": null, "updated_at": null},
            {"id": "369", "name": "Islamic Studies", "created_at": null, "updated_at": null},
            {"id": "370", "name": "Dramatic Arts", "created_at": null, "updated_at": null},
            {
                "id": "371",
                "name": "Environmental Science and Resource management",
                "created_at": null,
                "updated_at": null
            },
            {
                "id": "372",
                "name": "Information and Communication Science",
                "created_at": "2021-12-02 14:13:51",
                "updated_at": "2021-12-02 14:13:51"
            },
            {
                "id": "373",
                "name": "Applied Accounting",
                "created_at": "2021-12-02 14:13:51",
                "updated_at": "2021-12-02 14:13:51"
            },
            {
                "id": "374",
                "name": "Applied Geo-Physics",
                "created_at": "2021-12-02 14:13:51",
                "updated_at": "2021-12-02 14:13:51"
            },
            {
                "id": "375",
                "name": "Chartered Institute of Personnel Management",
                "created_at": "2021-12-02 14:13:51",
                "updated_at": "2021-12-02 14:13:51"
            },
            {"id": "376", "name": "Science", "created_at": "2021-12-02 17:23:23", "updated_at": "2021-12-02 17:23:23"},
            {
                "id": "377",
                "name": "Commercial",
                "created_at": "2021-12-02 17:23:23",
                "updated_at": "2021-12-02 17:23:23"
            },
            {"id": "378", "name": "Arts", "created_at": "2021-12-02 17:23:23", "updated_at": "2021-12-02 17:23:23"},
            {
                "id": "379",
                "name": "Technology",
                "created_at": "2021-12-02 17:23:23",
                "updated_at": "2021-12-02 17:23:23"
            },
            {"id": "380", "name": "Others", "created_at": "2021-12-02 17:26:40", "updated_at": "2021-12-02 17:26:40"},
            {
                "id": "381",
                "name": "Health and Safety",
                "created_at": "2022-04-20 17:41:47",
                "updated_at": "2022-04-20 17:41:47"
            },
            {
                "id": "382",
                "name": "Water Resources Engineering Technology",
                "created_at": "2022-06-24 02:28:38",
                "updated_at": "2022-06-24 02:28:38"
            }
        ];

        return theCourses.sort(compareByName);
    }


}

function compareByName(a, b) {
    // Convert names to lowercase for case-insensitive sorting
    var nameA = a.name.toLowerCase();
    var nameB = b.name.toLowerCase();

    if (nameA < nameB) {
        return -1; // a should be sorted before b
    } else if (nameA > nameB) {
        return 1; // a should be sorted after b
    } else {
        return 0; // names are equal, maintain original order
    }
}

export default Helpers;