import React, {useEffect, useState} from "react";
import App from "../../routes/index";
import {useHistory, useRouteMatch} from "react-router-dom";
import {useSelector} from "react-redux";
import {Badge, Layout, Menu} from "antd";
import {footerText} from "../../util/config";
import Sidebar from "../Sidebar";
import SidebarContent from "../Sidebar/SidebarContent";
import {BellFilled, CaretDownFilled} from "@ant-design/icons";
import {DOCUMENT_BASE_URL} from "../../constants/ServerUrl";
import {NoPageAccess} from "../../components/NoPageAccess";
import AlertNotification from "../Alert";
const alert = "😊 Hey Champ!, Great work so far. Just a friendly reminder to checkout before leaving. Cheers! 👍"

const {Content, Sider, Header, Footer} = Layout;
const MainApp = () => {
    let [sidebarCollapsed, setSidebarCollapsed] = useState(false);

    const match = useRouteMatch();

    const {authUser} = useSelector(({auth}) => auth);
    return (
        <Layout className="">
            
            <Sidebar sidebarCollapsed={sidebarCollapsed}/>
            <Layout>
                <AlertNotification message={alert}  hour="18" minute="44"> </AlertNotification>
                <Header className="bg-white p-0 h-20">

                    {authUser && (
                        <div className="flex  justify-end space-x-20 mt-2 mr-24">
                            <div>
                                <Badge dot>
                                    <BellFilled/>
                                </Badge>
                            </div>

                            {authUser.profile_photo_path && (
                                <div className="flex cursor-pointer">
                                    <div className=" rounded-full  p-1 ">

                                        <img width="50" className="rounded-full" alt={authUser.first_name}
                                             src={`${DOCUMENT_BASE_URL}/${authUser.profile_photo_path}`}/>
                                    </div>
                                    <div>

                                        <p className="pl-3 font-semibold">{authUser.first_name} <CaretDownFilled
                                            className="pl-4"/></p>
                                    </div>
                                </div>
                            )}
                            {!authUser.profile_photo_path && (
                                <div className="flex cursor-pointer ">
                                    <div className=" rounded-full  p-1 ">

                                        <img width="50" className="rounded-full" alt="POSNL"
                                             src="/images/app-icons/placeholder.jpg"/>
                                    </div>
                                    <div>

                                        <p className=" pl-3 font-semibold">{authUser.first_name}
                                            <CaretDownFilled
                                                className=" pl-4"/></p>
                                    </div>
                                </div>)}
                        </div>)}
                    {!authUser && (
                        <div className="flex  justify-end space-x-20 mt-2 mr-24">
                            <div>

                                <Badge dot>
                                    <BellFilled/>
                                </Badge>
                            </div>
                            <div className="flex cursor-pointer ">
                                <div className=" rounded-full  p-1 ">

                                    <img width="50" className="rounded-full" alt="Phillips Outsourcing"
                                         src="/images/app-icons/placeholder.jpg"/>
                                </div>
                                <div>

                                    <p className=" pl-3 font-semibold">Welcome<CaretDownFilled
                                        className=" pl-4"/></p>
                                </div>
                            </div>


                        </div>

                    )}
                </Header>
                <Content className="overflow-auto h-screen">
                    {authUser && (
                        <>
                            {(authUser.employee_status_id.toString() !== "1") ? (
                                <NoPageAccess/>
                            ) : (<App match={match}/>)}
                        </>
                    )}

                    <Footer>
                        <div className="gx-layout-footer-content">
                            {footerText}
                        </div>
                    </Footer>
                </Content>

            </Layout>
        </Layout>
    )
};
export default MainApp;

